import { useState, useEffect, useCallback, useMemo, memo } from "react"
import axios from "axios"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  Typography,
  Button,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material"
import { fetchToken } from "../../Auth"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

// Remove the MenuProps constant since it's specific to MUI Select
// Remove or comment out these lines:
// const ITEM_HEIGHT = 48
// const ITEM_PADDING_TOP = 8
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// }

// Add a customStyles object before the CreateRoleDialog component definition
const customStyles = {
  menu: (provided) => ({
    ...provided,
    maxHeight: "120px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "120px",
  }),
  control: (provided) => ({
    ...provided,
    "&:hover": {
      border: "1.25px solid #556",
    },
    minHeight: "56px",
  }),
}

// Styled components moved outside component to prevent recreation on each render
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "800px",
    width: "100%",
  },
}))

const DialogHeader = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  padding: "16px 24px",
  borderBottom: "1px solid #e0e0e0",
  "& .MuiTypography-root": {
    color: "#FF8A00",
    fontWeight: 500,
  },
})

const StyledTableCell = styled(TableCell)({
  padding: "12px 16px",
  fontSize: "14px",
  "&.MuiTableCell-head": {
    backgroundColor: "#1a1a1a",
    color: "white",
    fontWeight: 500,
  },
})

const StyledRadio = styled(Radio)({
  color: "#000000",
  "&.Mui-checked": {
    color: "#000000",
  },
})

const AnimatedPaper = styled(Paper)(({ theme }) => ({
  transition: theme.transitions.create(["transform", "opacity"], {
    duration: theme.transitions.duration.standard,
  }),
  "&.entering, &.entered": {
    transform: "translateY(0)",
    opacity: 1,
  },
  "&.exiting, &.exited": {
    transform: "translateY(-20px)",
    opacity: 0,
  },
}))
const CancelButton = styled(Button)({
  color: "#666",
  borderColor: "#ddd",
})

// Permission mapping object
const permissionToId = {
  reader: 1,
  maker: 2,
  checker: 3,
  fullAccess: 4,
  noAccess: 5,
}

const CreateButton = styled(Button)({
  backgroundColor: "#FF8A00",
  color: "white",
  "&:hover": {
    backgroundColor: "#ff9d2f",
  },
})

// Memoized component to prevent unnecessary re-renders
const CreateRoleDialog = memo(function CreateRoleDialog({ open, onClose, dashboards, functionalities, onSuccess }) {
  const [roleName, setRoleName] = useState("")
  const [selectedDashboards, setSelectedDashboards] = useState([])
  const [permissions, setPermissions] = useState({})
  const [isRoleNameExpanded, setIsRoleNameExpanded] = useState(false)
  const [isDashboardAccessExpanded, setIsDashboardAccessExpanded] = useState(false)
  const [isFeatureAccessExpanded, setIsFeatureAccessExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Initialize permissions when functionalities change
  useEffect(() => {
    if (functionalities && functionalities.length > 0) {
      setPermissions(
        functionalities.reduce((acc, feature) => {
          acc[feature.functionality_id] = "noAccess"
          return acc
        }, {}),
      )
    }
  }, [functionalities])

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      setRoleName("")
      setSelectedDashboards([])
      setPermissions(
        functionalities.reduce((acc, feature) => {
          acc[feature.functionality_id] = "noAccess"
          return acc
        }, {}),
      )
    }
  }, [open, functionalities])

  // Modify the availableDashboards to match react-select format
  const availableDashboards = useMemo(() => {
    return dashboards.map((dashboard) => ({
      value: dashboard.dashboard_id,
      label: dashboard.dashboard_name,
    }))
  }, [dashboards])

  // Memoized handlers for UI interactions
  const toggleRoleNameSection = useCallback(() => {
    setIsRoleNameExpanded((prev) => !prev)
  }, [])

  const toggleDashboardAccessSection = useCallback(() => {
    setIsDashboardAccessExpanded((prev) => !prev)
  }, [])

  const toggleFeatureAccessSection = useCallback(() => {
    setIsFeatureAccessExpanded((prev) => !prev)
  }, [])

  const handleRoleNameChange = useCallback((e) => {
    setRoleName(e.target.value)
  }, [])

  // Inside the CreateRoleDialog component, modify the selectedDashboards state and handlers
  // Replace the handleDashboardChange function with this:
  const handleDashboardChange = useCallback((selectedOptions) => {
    const options = selectedOptions || []
    setSelectedDashboards(options)
  }, [])

  const handlePermissionChange = useCallback((featureId, permission) => {
    setPermissions((prev) => ({
      ...prev,
      [featureId]: permission,
    }))
  }, [])
  const validateDesignationName = (name) => {
    // Allow only letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]+$/
    return regex.test(name)
  }

  // Validate form data
  const validateForm = useCallback(() => {
    if (!roleName.trim()) {
      toast.error("Please enter a role name")
      return false
    }
    if (!validateDesignationName(roleName)) {
      toast.warning("Role name should not contain special characters")
      return false
    }

    // In the validateForm function, update the check for selectedDashboards:
    if (selectedDashboards.length === 0) {
      toast.error("Please select at least one dashboard")
      return false
    }

    return true
  }, [roleName, selectedDashboards])

  // Handle form submission
  const handleCreateRole = useCallback(async () => {
    // Validate required fields
    if (!validateForm()) {
      return
    }

    try {
      setIsLoading(true)

      // In the handleCreateRole function, update the structuredData:
      const structuredData = {
        roleName: roleName.trim(),
        dashboards_assigned: selectedDashboards.map((dashboard) => dashboard.value),
        functionality_assigned: functionalities
          .filter((feature) => feature.functionality_id && permissions[feature.functionality_id] !== undefined)
          .map((feature) => ({
            functionality_id: feature.functionality_id,
            privilege: permissionToId[permissions[feature.functionality_id]],
          })),
      }

      const token = fetchToken("zetta_access_token")
      if (!token) {
        toast.error("Authentication token not found")
        throw new Error("Authentication token not found")
      }

      const response = await axios.post("/create_user_role", structuredData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.data.success) {
        toast.success("Role created successfully")
        onSuccess?.()
        onClose(true)
      } else {
        toast.error(response.data.message || "Failed to create role")
      }
    } catch (error) {
      console.error("Error creating role:", error)
      if (error.response?.status === 403) {
        toast.error("Access Denied: Insufficient Permissions")
      } else if (error.response?.status === 400) {
        toast.error(error.response.data.message || "Invalid input")
      } else if (error.response?.status === 401) {
        window.location.href = "/login"
      } else {
        toast.error(error.message || "An error occurred while creating the role")
      }
    } finally {
      setIsLoading(false)
    }
  }, [roleName, selectedDashboards, permissions, functionalities, onClose, validateForm])

  // Replace the memoized selectedDashboardNames with this:
  // Remove this code:
  // const selectedDashboardNames = useMemo(() => {
  //   return selectedDashboards.map((d) => d.name)
  // }, [selectedDashboards])

  // Replace the memoized dashboardChips with this:
  // Remove this code:
  // const dashboardChips = useMemo(() => {
  //   return (
  //     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
  //       {selectedDashboardNames.map((value) => (
  //         <Chip
  //           key={value}
  //           label={value}
  //           sx={{
  //             backgroundColor: "#f5f5f5",
  //           }}
  //         />
  //       ))}
  //     </Box>
  //   )
  // }, [selectedDashboardNames])

  // // Memoized select styles
  // const selectStyles = useMemo(
  //   () => ({
  //     minHeight: "56px",
  //     "& .MuiOutlinedInput-notchedOutline": {
  //       borderColor: "#e0e0e0",
  //     },
  //     "&:hover .MuiOutlinedInput-notchedOutline": {
  //       borderColor: "#000",
  //     },
  //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //       borderColor: "#000",
  //     },
  //   }),
  //   [],
  // )

  return (
    <StyledDialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth disableEscapeKeyDown={isLoading}>
      <DialogHeader>
        <Typography component="div" variant="h6">
          Create New Role
        </Typography>
        <IconButton onClick={() => onClose(false)} sx={{ color: "#666" }} disabled={isLoading}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent sx={{ p: 3, mt: 1 }}>
        <Accordion
          expanded={isRoleNameExpanded}
          onChange={() => setIsRoleNameExpanded(!isRoleNameExpanded)}
          sx={{
            borderRadius: "10px",
            mb: 3,
            p: 0,
            "&:before": {
              display: "none",
            },
            backgroundColor: "#1e1e2d",
            "&.Mui-expanded": {
              margin: 0,
              marginBottom: 3,
            },
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          }}
        >
          <AccordionSummary
            expandIcon={
              isRoleNameExpanded ? (
                <KeyboardArrowUp sx={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              )
            }
            aria-controls="role-name-content"
            id="role-name-header"
            sx={{
              padding: "0 16px",
              minHeight: "58px",
              backgroundColor: "#2a2a3c",
              borderRadius: "10px",
              overflow: "hidden",
              "&.Mui-expanded": {
                minHeight: "58px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>Role Name</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px 0",
              transition: "all 0.3s ease-in-out",
              backgroundColor: "#ededf2",
            }}
          >
            <Box sx={{ px: 3 }}>
              <AnimatedPaper
                sx={{
                  backgroundColor: "#fcfcfc",
                  p: 3,
                  borderRadius: "8px",
                }}
                elevation={2}
              >
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 1, color: "#666" }}>
                    User Role Name
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter User Role Name"
                    value={roleName}
                    onChange={handleRoleNameChange}
                    disabled={isLoading}
                    inputProps={{
                      maxLength: 100,
                    }}
                    sx={{
                      mt: 1,
                      mb: 1,
                      "& .MuiOutlinedInput-root": {
                        color: "#080707",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "8px",
                        "& fieldset": {
                          borderColor: "rgba(3, 0, 0, 0.15)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(14, 13, 13, 0.3)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6366f1",
                        },
                        padding: "4px 8px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "12px 14px",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "rgba(4, 4, 4, 0.5)",
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              </AnimatedPaper>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={isDashboardAccessExpanded}
          onChange={() => setIsDashboardAccessExpanded(!isDashboardAccessExpanded)}
          sx={{
            borderRadius: "10px",
            mb: 3,
            p: 0,
            "&:before": {
              display: "none",
            },
            backgroundColor: "#1e1e2d",
            "&.Mui-expanded": {
              margin: 0,
              marginBottom: 3,
            },
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          }}
        >
          <AccordionSummary
            expandIcon={
              isDashboardAccessExpanded ? (
                <KeyboardArrowUp sx={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              )
            }
            aria-controls="dashboard-access-content"
            id="dashboard-access-header"
            sx={{
              padding: "0 16px",
              minHeight: "58px",
              backgroundColor: "#2a2a3c",
              borderRadius: "10px",
              overflow: "hidden",
              "&.Mui-expanded": {
                minHeight: "58px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>Manage Role and Dashboard Access</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px 0",
              transition: "all 0.3s ease-in-out",
              backgroundColor: "#ededf2",
            }}
          >
            <Box sx={{ px: 3 }}>
              <AnimatedPaper
                sx={{
                  backgroundColor: "#fcfcfc",
                  p: 3,
                  borderRadius: "8px",
                }}
                elevation={2}
              >
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 1, color: "#666" }}>
                    Assign Dashboards*
                  </Typography>
                  <Select
                    placeholder="Select Dashboards"
                    styles={customStyles}
                    isMulti
                    value={selectedDashboards}
                    onChange={handleDashboardChange}
                    options={availableDashboards}
                    className="w-100"
                    isDisabled={isLoading}
                  />
                </Box>
              </AnimatedPaper>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={isFeatureAccessExpanded}
          onChange={() => setIsFeatureAccessExpanded(!isFeatureAccessExpanded)}
          sx={{
            borderRadius: "10px",
            mb: 3,
            p: 0,
            "&:before": {
              display: "none",
            },
            backgroundColor: "#1e1e2d",
            "&.Mui-expanded": {
              margin: 0,
              marginBottom: 3,
            },
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          }}
        >
          <AccordionSummary
            expandIcon={
              isFeatureAccessExpanded ? (
                <KeyboardArrowUp sx={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              )
            }
            aria-controls="feature-access-content"
            id="feature-access-header"
            sx={{
              padding: "0 16px",
              minHeight: "58px",
              backgroundColor: "#2a2a3c",
              borderRadius: "10px",
              overflow: "hidden",
              "&.Mui-expanded": {
                minHeight: "58px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>Manage Role and Feature Access</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px 0",
              transition: "all 0.3s ease-in-out",
              backgroundColor: "#ededf2",
            }}
          >
            <Box sx={{ px: 3 }}>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  border: "1px solid #e0e0e0",
                  maxHeight: "400px",
                  overflow: "auto",
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Functionality Name</StyledTableCell>
                      <StyledTableCell align="center">Reader</StyledTableCell>
                      <StyledTableCell align="center">Maker</StyledTableCell>
                      <StyledTableCell align="center">Checker</StyledTableCell>
                      <StyledTableCell align="center">Full Access</StyledTableCell>
                      <StyledTableCell align="center">No Access</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {functionalities.map((feature) => (
                      <TableRow key={feature.functionality_id}>
                        <StyledTableCell>{feature.functionality_name}</StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[feature.functionality_id] === "reader"}
                            onChange={() => handlePermissionChange(feature.functionality_id, "reader")}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[feature.functionality_id] === "maker"}
                            onChange={() => handlePermissionChange(feature.functionality_id, "maker")}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[feature.functionality_id] === "checker"}
                            onChange={() => handlePermissionChange(feature.functionality_id, "checker")}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[feature.functionality_id] === "fullAccess"}
                            onChange={() => handlePermissionChange(feature.functionality_id, "fullAccess")}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[feature.functionality_id] === "noAccess"}
                            onChange={() => handlePermissionChange(feature.functionality_id, "noAccess")}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2, justifyContent: "center" }}>
        <CreateButton variant="contained" onClick={handleCreateRole} disableElevation disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Create Role"}
        </CreateButton>
        <CancelButton variant="outlined" onClick={() => onClose(false)} disabled={isLoading}>
          Cancel
        </CancelButton>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </StyledDialog>
  )
})

export default CreateRoleDialog

