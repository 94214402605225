import { useState, useEffect } from 'react';
import { useNavigate ,useLocation} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Table from '../../components/common/Table/Table';
import Button from '@mui/material/Button';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';
import { fetchToken ,isValidRoute} from '../../Auth'
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const MarketIntel = () => {
    const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
    
    const [dashboardId,setDashboardId] = useState("")

    const location = useLocation(); 
    const navigate = useNavigate();
  
    useEffect(() => {
        const fetchId = async () => {
          if (!dashboardId) {
            let id = await fetchDashboardId(location.pathname);
            setDashboardId(id);
          }
        };
      
        fetchId();
      }, []);
      
      const checkRoute = async () => {
        if (dashboardId) {
          const res = await isValidRoute(dashboardId);
          if (!res["valid"]) {
            navigate(res["redirect"]);
          }
        }
      };
  
    useEffect(() => {
      checkRoute();
    }, [dashboardId]); 

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '38px',
            height: 'auto',
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            maxHeight: '30px', // Set the maximum height for the value container
            overflowY: 'auto', // Enable vertical scrolling
            padding: '0 6px',
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
    };

    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }

    const [sites, setSites] = useState([
        // { value: "all", label: "All" },
        { value: "agoda", label: "Agoda" },
        { value: "booking", label: "Booking" },
        { value: "emt", label: "EaseMyTrip" },
        { value: "mmt", label: "MakeMyTrip" },
    ])
    const [ota, setOTA] = useState({ value: 'emt', label: 'EaseMyTrip' });
    const [catOTA, setCatOTA] = useState({ value: 'emt', label: 'EaseMyTrip' });

    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [catSelectedCity, setCatSelectedCity] = useState([]);


    const [checkInStart, setCheckInStart] = useState(new Date().toISOString().split('T')[0])
    const [checkInEnd, setCheckInEnd] = useState(new Date(Date.now() + 86400000).toISOString().split('T')[0])

    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0])
    const [toDate, setToDate] = useState(new Date(Date.now() + 86400000).toISOString().split('T')[0])



    const [losOptions, setLOSOptions] = useState([
        { value: [1, 2, 3], label: "1-3" },
        { value: [3, 4, 5], label: "3-5" },
        { value: [5, 6, 7], label: "5-7" },
        { value: [7, 8, 9, 10, 11, 12, 13, 14, 15], label: "7+" },
    ])
    const [los, setLOS] = useState(null);

    const [splitOptions, setSplitOptions] = useState([
        { value: "hotelName", label: "Hotel Name", is_room: true, is_cat: false },
        { value: "hotelType", label: "Hotel Type", is_room: true, is_cat: true },
        { value: "roomType", label: "Room Type", is_room: true, is_cat: false },
        { value: "mealPlan", label: "Meal Plan", is_room: true, is_cat: false },
        { value: "bedType", label: "Bed Type", is_room: true, is_cat: false },
        { value: "star", label: "Star", is_room: true, is_cat: true },
        { value: "ratings", label: "Ratings", is_room: false, is_cat: true },

    ]);

    const [selectedSplit, setSelectedSplit] = useState([]);
    const [catSelectedSplit, setCatSelectedSplit] = useState([]);
    const [rows, setRows] = useState([]);
    const [catRows, setCatRows] = useState([]);

    const getCities = async () => {
        try {
            const response = await axios.get(`/market-intel/get-cities/${ota['value']}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                },
            });
            if (response.status === 200) {
                setCities(response.data);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const generateCSV = async (ota, selectedCities, checkInStart, checkInEnd, los) => {
        if (!ota) {
            toast.error('Select an OTA!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!selectedCities || !selectedCities.length) {
            toast.error('Select one city!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!checkInStart) {
            toast.error('Invalid check-in start date!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!checkInEnd) {
            toast.error('Invalid check-in end date!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!los || !los.value) {
            toast.error('Select a value for Length of Stay!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        setLoading(true)
        try {
            const response = await axios.post(`/market-intel/generate-csv`,
                {
                    ota: ota.value,
                    cities: selectedCities.map(item => item.value),
                    checkInStart: checkInStart,
                    checkInEnd: checkInEnd,
                    los: los.value,
                    splitBy: selectedSplit.map(item => item.value),
                    dataType: "rooms",
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                });
            if (response.status === 200) {
                const url = response.data.url;
                const file = await fetch(url);
                const fileBlob = await file.blob();
                const fileURL = URL.createObjectURL(fileBlob);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = 'filename' in response.data ? response.data.filename : 'data.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setSelectedCities([]);
                setLOS(null);
                setSelectedSplit([]);
                getMarketIntelLogs();
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
        setLoading(false)
    }
    const generateCSVCatalogueLevel = async (ota, selectedCities, fromDate, toDate, los) => {
        // console.log(ota, selectedCities, fromDate, toDate, los, catSelectedSplit)
        if (!ota) {
            toast.error('Select an OTA!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!selectedCities) {
            toast.error('Select one city!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!fromDate) {
            toast.error('Invalid from date!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        if (!toDate) {
            toast.error('Invalid to date!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                theme: "colored",
                type: toast.TYPE.ERROR
            });
            return;
        }
        setLoading(true)
        try {
            const data = {
                ota: ota.value,
                cities: selectedCities.map(item => item.value),
                fromDate: fromDate,
                toDate: toDate,
                splitBy: catSelectedSplit.map(item => item.value),
                dataType: "catalogue",
            }
            const response = await axios.post("/market-intel/generate-csv-catalogue", data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                }
            )
            if (response.status === 200) {
                console.log(response.data)
                const url = response.data.url;
                const file = await fetch(url);
                const fileBlob = await file.blob();
                const fileURL = URL.createObjectURL(fileBlob);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = 'filename' in response.data ? response.data.filename : 'data.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setCatSelectedCity([]);
                setCatSelectedSplit([]);
                getMarketIntelCatLogs();
            }

        } catch (err) {
            console.error("Error", err)
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
        setLoading(false)
    }

    const downloadCSV = async (id, data_type) => {
        try {
            let url;
            if (data_type == "rooms") {
                url = `/market-intel/download-csv/${id}`
            } else if (data_type == "catalogue") {
                url = `/market-intel/download-csv-catalogue/${id}`
            }
            const response = await axios.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                });
            if (response.status === 200) {
                const url = response.data.url;
                const file = await fetch(url);
                const fileBlob = await file.blob();
                const fileURL = URL.createObjectURL(fileBlob);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = 'filename' in response.data ? response.data.filename : 'data.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getMarketIntelLogs = async () => {
        try {
            const response = await axios.get(`/market-intel/get-market-intel-logs`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                });
            if (response.status === 200) {
                setRows(response.data.map(row => (
                    { ...row, id: row['_id'] }
                )))
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }
    const getMarketIntelCatLogs = async () => {
        try {
            const response = await axios.get("/market-intel/get-market-intel-cat-logs",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                    },
                }
            );
            if (response.status === 200) {
                setCatRows(response.data.map(row => (
                    { ...row, id: row['_id'] }
                )))
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                })
            }
            if (err.response && err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        if (ota) getCities();
    }, [ota])

    useEffect(() => {
        if (selectedTab === 0) {
            getMarketIntelLogs();
        }
        else if (selectedTab === 1) {
            getMarketIntelCatLogs();
        }
    }, [selectedTab])

    const columns = [
        // { field: '_id', headerName: 'ID', hide: true, filterable: false},
        {
            field: 'ota',
            headerName: 'OTA',
            filterable: false,
            flex: 1,
            renderCell: (params) => (
                <span>{params.row.ota === 'all' ? 'All' : params.row.ota.toUpperCase()}</span>
            )
        },
        {
            field: 'cities',
            headerName: 'Cities',
            filterable: false,
            width: 150,
            renderCell: (params) => <span className='span-overflow' title={params.row.cities.join(', ')}>{params.row.cities.length > 1 ? `${params.row.cities[0]}...` : params.row.cities[0]}</span>,
            valueGetter: (params) => params.row.cities.join(', ')
        },
        {
            field: 'check_in_start',
            headerName: 'Check-in start',
            filterable: false,
            flex: 1
        },
        {
            field: 'check_in_end',
            headerName: 'Check-in end',
            filterable: false,
            flex: 1
        },
        {
            field: 'los',
            headerName: 'LoS',
            filterable: false,
            width: 50,
            // flex: 1,
            renderCell: (params) => (<span className='span-overflow' title={params.row.los.join(', ')}>{params.row.los[0]}-{params.row.los[params.row.los.length - 1]}</span>),
            valueGetter: (params) => params.row.los.join(', ')
        },
        {
            field: 'split_by',
            headerName: 'Split By',
            filterable: false,
            sortable: false,
            flex: 1,
            width: 150,
            renderCell: (params) => (
                <div className='span-overflow'>
                    <p className='mb-0' title={params.row.split_by.map(item => splitOptions.find(sp => sp.value === item) ? (splitOptions.find(sp => sp.value === item))['label'] : item).join(", ")}>
                        {(() => {
                            const firstOption = params.row.split_by[0];
                            if (!firstOption) {
                                return ""
                            }
                            const label = splitOptions.find(sp => sp.value === firstOption) ? splitOptions.find(sp => sp.value === firstOption)['label'] : firstOption;
                            return `${label}${params.row.split_by.length > 1 ? '...' : ''}`;
                        })()}
                    </p>
                </div>
            )
        },
        {
            field: "created_by",
            headerName: 'Created By',
            width: 300,
            renderCell: (params) => (
                <div title={params.row.created_by}>
                    <p className='mb-0'>{params.row.created_by}</p>
                    <small className='text-muted small'>at {params.row.created_dtm}</small>
                </div>
            )
        },
        {
            field: 'download',
            headerName: 'Download',
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => (
                <Button onClick={() => downloadCSV(params.row.id, "rooms")} size="large"><i className="fa-solid fa-download"></i></Button>
            )
        },
    ];
    const catColumns = [
        // { field: '_id', headerName: 'ID', hide: true, filterable: false},
        {
            field: 'ota',
            headerName: 'OTA',
            filterable: false,
            flex: 1,
            renderCell: (params) => (
                <span>{params.row.ota === 'all' ? 'All' : params.row.ota.toUpperCase()}</span>
            )
        },
        {
            field: 'cities',
            headerName: 'Cities',
            filterable: false,
            width: 150,
            renderCell: (params) => <span className='span-overflow' title={params.row.cities.join(', ')}>{params.row.cities.length > 1 ? `${params.row.cities[0]}...` : params.row.cities[0]}</span>,
            valueGetter: (params) => params.row.cities.join(', ')
        },
        {
            field: 'from_date',
            headerName: 'From Date',
            filterable: false,
            flex: 1
        },
        {
            field: 'to_date',
            headerName: 'To Date',
            filterable: false,
            flex: 1
        },
        {
            field: 'split_by',
            headerName: 'Split By',
            filterable: false,
            sortable: false,
            flex: 1,
            width: 150,
            renderCell: (params) => (
                <div className='span-overflow'>
                    <p className='mb-0' title={params.row.split_by.map(item => splitOptions.find(sp => sp.value === item) ? (splitOptions.find(sp => sp.value === item))['label'] : item).join(", ")}>
                        {(() => {
                            const firstOption = params.row.split_by[0];
                            if (!firstOption) {
                                return ""
                            }
                            const label = splitOptions.find(sp => sp.value === firstOption) ? splitOptions.find(sp => sp.value === firstOption)['label'] : firstOption;
                            return `${label}${params.row.split_by.length > 1 ? '...' : ''}`;
                        })()}
                    </p>
                </div>
            )
        },
        {
            field: "created_by",
            headerName: 'Created By',
            width: 300,
            renderCell: (params) => (
                <div title={params.row.created_by}>
                    <p className='mb-0'>{params.row.created_by}</p>
                    <small className='text-muted small'>at {params.row.created_dtm}</small>
                </div>
            )
        },
        {
            field: 'download',
            headerName: 'Download',
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => (
                <Button onClick={() => downloadCSV(params.row.id, "catalogue")} size="large"><i className="fa-solid fa-download"></i></Button>
            )
        },
    ];
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
        {/* <DashboardSessionTracker dashboardId={36}> </DashboardSessionTracker> */}
        {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}
        <section className="py-2">
            <div className="row">
                <div className="col-12">
                    <Grow
                        in={true}
                        {...(true ? { timeout: 500 } : {})}
                    >
                        <div className="admin-card bg-white p-4">
                            <h3 className="bold">Market Intel</h3>

                            <Box className='mt-3'>
                                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Room Level" {...a11yProps(0)} />
                                    <Tab label="Catalogue Level" {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            {selectedTab === 0 &&
                                <>
                                    <Box className='mt-3 mb-4' sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={0.8}>
                                            <Grid item xs={1.2}>
                                                <label className='medium-4 text-muted bold'>OTA<span className='text-danger'>*</span></label>
                                                <Select
                                                    placeholder="Select OTA"
                                                    value={ota}
                                                    label="OTA"
                                                    onChange={opt => {
                                                        setOTA(opt)
                                                    }}
                                                    options={sites.map(item => ({ 'label': item.label, 'value': item.value }))}
                                                    className="w-100"
                                                />
                                            </Grid>
                                            <Grid className='max-height' item xs={2.4}>
                                                <label className='medium-4 text-muted bold'>Cities<span className='text-danger'>*</span></label>
                                                <MultiSelect placeholderButtonLabel="Select Cities" value={selectedCities} onChange={opt => {
                                                    setSelectedCities(opt)
                                                }} isMulti styles={customStyles} closeMenuOnSelect={false} options={cities.map(item => ({ 'label': item, 'value': item }))} className="multi-select w-100" />

                                            </Grid>
                                            <Grid item xs={1.66}>
                                                <label className='medium-4 text-muted bold'>Check-in start<span className='text-danger'>*</span></label>
                                                <input value={checkInStart} onChange={(evt) => setCheckInStart(evt.target.value)} className='form-control' placeholder='Check in start' type="date" id="check_in_start" name="check_in_start" />
                                            </Grid>
                                            <Grid item xs={1.66}>
                                                <label className='medium-4 text-muted bold'>Check-in end<span className='text-danger'>*</span></label>
                                                <input value={checkInEnd} onChange={(evt) => setCheckInEnd(evt.target.value)} className='form-control' placeholder='Check in end' type="date" id="check_in_end" name="check_in_end" />
                                            </Grid>
                                            <Grid item xs={1.4}>
                                                <label className='medium-4 text-muted bold'>Length of Stay<span className='text-danger'>*</span></label>
                                                <Select
                                                    placeholder="Select LoS"
                                                    value={los}
                                                    label="LoS"
                                                    onChange={opt => {
                                                        setLOS(opt)
                                                    }}
                                                    options={losOptions.map(item => ({ 'label': item.label, 'value': item.value }))}
                                                    className="multi-select w-100"
                                                />
                                            </Grid>
                                            <Grid item xs={1.66}>
                                                <label className='medium-4 text-muted bold'>Split By</label>
                                                <MultiSelect
                                                    labelledBy="Select"
                                                    isMulti
                                                    placeholder="Split by"
                                                    placeholderButtonLabel="Split by"
                                                    value={selectedSplit}
                                                    onChange={opt => {
                                                        setSelectedSplit(opt)
                                                    }}
                                                    styles={customStyles}
                                                    customStyles={customStyles}
                                                    closeMenuOnSelect={false}
                                                    options={splitOptions
                                                        .filter(item => item.is_room)
                                                        .map(item => ({ 'label': item.label, 'value': item.value }))}
                                                    className="w-100"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button onClick={() => {
                                                    generateCSV(ota, selectedCities, checkInStart, checkInEnd, los)
                                                }} className='mt-3 p-2 w-100' size='medium' variant="contained">{loading ? <CircularProgress size={25} color='inherit' /> : 'Generate CSV'}</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <hr />
                                    <div className='mt-2'>
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <Table disableSelect={true} autoHeight={true} cols={columns} rows={rows} />
                                        </Box>
                                    </div>
                                </>
                            }
                            {selectedTab === 1 &&
                                <>
                                    <Box className='mt-3 mb-4' sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={0.8}>
                                            <Grid item xs={1.2}>
                                                <label className='medium-4 text-muted bold'>OTA<span className='text-danger'>*</span></label>
                                                <Select
                                                    placeholder="Select OTA"
                                                    value={catOTA}
                                                    label="OTA"
                                                    onChange={opt => {
                                                        setCatOTA(opt)
                                                    }}
                                                    options={sites.map(item => ({ 'label': item.label, 'value': item.value }))}
                                                    className="w-100"
                                                />
                                            </Grid>
                                            <Grid className='max-height' item xs={2.4}>
                                                <label className='medium-4 text-muted bold'>Cities<span className='text-danger'>*</span></label>
                                                <MultiSelect placeholder="Select City" value={catSelectedCity} onChange={opt => {
                                                    setCatSelectedCity(opt)
                                                }} isMulti={false} styles={customStyles} closeMenuOnSelect={false} options={cities.map(item => ({ 'label': item, 'value': item }))} className="multi-select w-100" />

                                            </Grid>
                                            <Grid item xs={1.66}>
                                                <label className='medium-4 text-muted bold'>From Date<span className='text-danger'>*</span></label>
                                                <input value={fromDate} onChange={(evt) => setFromDate(evt.target.value)} className='form-control' placeholder='From Date' type="date" id="from_date" name="from_date" />
                                            </Grid>
                                            <Grid item xs={1.66}>
                                                <label className='medium-4 text-muted bold'>To Date<span className='text-danger'>*</span></label>
                                                <input value={toDate} onChange={(evt) => setToDate(evt.target.value)} className='form-control' placeholder='To Date' type="date" id="to_date" name="to_date" />
                                            </Grid>
                                            <Grid item xs={1.66}>
                                                <label className='medium-4 text-muted bold'>Split By</label>
                                                <MultiSelect
                                                    labelledBy="Select"
                                                    isMulti
                                                    placeholder="Split by"
                                                    placeholderButtonLabel="Split by"
                                                    value={catSelectedSplit}
                                                    onChange={opt => {
                                                        setCatSelectedSplit(opt)
                                                    }}
                                                    styles={customStyles}
                                                    customStyles={customStyles}
                                                    closeMenuOnSelect={false}
                                                    options={splitOptions
                                                        .filter(item => item.is_cat)
                                                        .map(item => ({ 'label': item.label, 'value': item.value }))}
                                                    className="w-100"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button onClick={() => {
                                                    generateCSVCatalogueLevel(catOTA, catSelectedCity, fromDate, toDate)
                                                }} className='mt-3 p-2 w-100' size='medium' variant="contained">{loading ? <CircularProgress size={25} color='inherit' /> : 'Generate CSV'}</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <hr />
                                    <div className='mt-2'>
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <Table disableSelect={true} autoHeight={true} cols={catColumns} rows={catRows} />
                                        </Box>
                                    </div>

                                </>
                            }
                        </div>
                    </Grow>
                </div>
            </div>
        </section>
        </>
    )
};

export default MarketIntel;