import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TrainIcon from "@mui/icons-material/Train";
import CircleIcon from "@mui/icons-material/Circle";
import FlagIcon from "../../components/common/Calendar/FlagIcon";

const Legend = ({ setOpenDrawer }) => {
  const [filterNames, setFilterNames] = useState([
    {
      name: "Competition Tariff Increase",
      val: "comp_price_inc",
      color: "rgb(255, 174, 1)",
      icon: "inc",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CT",
      flagShort: "Comp price",
    },
    {
      name: "Competition Tariff Decrease",
      val: "comp_price_dec",
      color: "rgb(255, 174, 1)",
      icon: "dec",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CT",
      flagShort: "Comp price",
    },
    {
      name: "OverPriced",
      flagShort: "Over price",
      val: "overpriced",
      color: "#f68d8e",
      icon: "inc",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "OP",
    },
    {
      name: "UnderPriced",
      flagShort: "Under price",
      val: "underpriced",
      color: "#f68d8e",
      icon: "dec",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "UP",
    },
  ]);

  return (
    <>
      <Box sx={{ width: "20em" }}>
        <div className="px-2">
          <IconButton onClick={() => setOpenDrawer(false)} aria-label="delete">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="px-4 py-3 d-flex flex-column">
          <h6 className="text-muted bold mb-0">Abbreviations</h6>
          <hr className="my-1" />
          <div className="mt-2 mb-2 w-100">
          <p className="small">
              <span className="bold">LOS</span>: Length of Stay
            </p>
            <p className="small">
              <span className="bold">T</span>: Base Room Tariff
            </p>
            <p className="small">
              <span className="bold">O</span>: Overall Hotel Occupancy
            </p>
            <p className="small">
              <span className="bold">A/T</span>: Available Rooms/ Total Rooms
            </p>
            {/* <p className="small">
              <span className="bold">RT</span>: Base Room Type
            </p> */}
            <p className="small">
              <span className="bold">MP</span>: Meal Plan
            </p>
            {/* <p className="small">
              <span className="bold">LCP</span>: Lowest Competition Price
            </p> */}
            <p className="small">
              <span className="bold">CT</span>: Competition Tariff
            </p>
            <p className="small">
              <span className="bold">CT Min</span>: Minimum competitor tariff observed
            </p>
            {/* <p className="small">
              <span className="bold">CT Median</span>: Median of competitor tariffs
            </p> */}
            <p className="small">
              <span className="bold">CT Average</span>: Average of competitor tariffs
            </p>
            <p className="small">
              <span className="bold">CT Max</span>: Maximum competitor tariff observed
            </p>


          </div>
          <h6 className="text-muted bold mb-0">Legend</h6>
          <hr className="my-1" />
          <div className="mt-2 w-100">
            {/* <p className="small">
              <div className="d-flex">
              <span className="bold ms-2">
                <TrainIcon style={{ fontSize: "1.5em" }} />
              </span>{" "}
              <div style={{marginLeft:'0.9em'}}>
              Train Demand Spike
              </div>
              </div>
            </p> */}
            <p className="small">


              <div className="d-flex">
                <div className="me-2 mt-1">
                  <FlagIcon
                    filter={filterNames}
                    flag={"underpriced"}

                  />
                </div>
                <div>
                  Underpriced - Price significantly lower than focused competition price.
                </div>
              </div>
            </p>
            <p className="small d-flex align-items-center">
              <div className="d-flex">
                <div className="me-2 mt-1">
                  <FlagIcon
                    filter={filterNames}
                    flag={"overpriced"}

                  />
                </div>
                <div>
                  Overpriced - Price significantly higher than focused competition price.
                </div>
              </div>
            </p>
            {/* <p className="small d-flex align-items-center">
              <div className="d-flex">
                <div className="me-2 mt-1">
                  <FlagIcon
                    filter={filterNames}
                    flag={"comp_price_inc"}
                    className="me-1"
                  />
                </div>

                <div >
                  Competition Tariff Increase</div>
              </div>
            </p>
            <p className="small d-flex align-items-center">
              <div className="d-flex">
                <div className="me-2 mt-1">
                  <FlagIcon
                    filter={filterNames}
                    flag={"comp_price_dec"}
                    className="me-1"
                  />
                </div>
                <div >
                  Competition Tariff Decrease
                </div>
              </div>
            </p> */}
          </div>
        </div>
      </Box>
    </>
  );
};

export default Legend;
