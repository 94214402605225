import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../../Auth";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import '../../../assets/styles/zetta-charts.css';
import {
    Switch,
    TextField,
    styled,
    InputAdornment,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButton-root': {
        color: '#6b7280',
        borderBottom: '2px solid #FF9637',
        '&:hover': {
            backgroundColor: '#fff7ed',
        },
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#FF9637',
            borderBottom: '2px solid #FF9637',
        },
        fontSize: '0.8rem',
        minHeight: '20px',
        transition: 'all 0.2s ease-in-out',
    },
    '& .MuiToggleButton-root:not(:last-child)': {
        // marginRight: '8px',
    }
}));

const BookingHistory = ({ tenantId, propertyId, date, open }) => {
    const navigate = useNavigate();
    const [bookingSeries, setBookingSeries] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [timeRange, setTimeRange] = useState('all');

    const getBookingHistory = async (
        tenantId,
        propertyId,
        date,
    ) => {
        try {
            const data = {
                "tenant_id": tenantId,
                "property_id": propertyId,
                "date": date,
            }
            const response = await axios.post(
                `/fetch_booking_history`, data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                    },
                }
            );
            if (response.status === 200) {
                setBookingSeries(response.data.booking_series);
                // Process the booking data for the chart
                formatChartData(response.data.booking_series);
            }
        } catch (error) {
            console.error("Error Fetching Booking History!", error);
            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
    };

    const formatChartData = (data) => {
        if (!data || data.length === 0) return;

        // Filter data based on selected time range
        const now = new Date();
        const filteredData = data.filter(item => {
            const itemDate = new Date(item[0]);
            switch (timeRange) {
                case 'lastWeek':
                    return itemDate >= new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
                case 'lastMonth':
                    return itemDate >= new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
                default:
                    return true;
            }
        });

        const formattedData = filteredData.map(item => ({
            date: new Date(item[0]).getTime(),
            bookings: item[1]
        }));

        formattedData.sort((a, b) => a.date - b.date);

        let cumulativeBookings = 0;
        const cumulativeData = formattedData.map(item => {
            cumulativeBookings += item.bookings;
            return [item.date, cumulativeBookings];
        });

        // Rest of the chart options remain the same as in the previous implementation
        const options = {
            chart: {
                type: 'column',
                // backgroundColor: '#f5f5f5', // Light grey background
                zoomType: 'x',
                style: {
                    fontFamily: 'Arial, sans-serif'
                }
            },
            title: {
                text: '',
                style: {
                    color: '#000000',
                    fontWeight: 'bold'
                }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%Y-%m-%d}',
                    rotation: -45,
                    align: 'right',
                    style: {
                        color: '#000000'
                    }
                },
                title: {
                    text: 'Booking Date',
                    style: {
                        color: '#000000'
                    }
                },
                gridLineColor: 'rgba(0,0,0,0.1)',
                tickColor: '#000000'
            },
            yAxis: [
                {
                    title: {
                        text: 'Number of Bookings',
                        style: {
                            color: '#000000'
                        }
                    },
                    labels: {
                        style: {
                            color: '#000000'
                        }
                    },
                    min: 0,
                    gridLineColor: 'rgba(0,0,0,0.1)'
                },
                {
                    title: {
                        text: 'Cumulative Bookings',
                        style: {
                            color: '#FF8B00'
                        }
                    },
                    labels: {
                        style: {
                            color: '#FF8B00'
                        }
                    },
                    min: 0,
                    opposite: true,
                    gridLineColor: 'rgba(255,165,0,0.1)'
                }
            ],
            tooltip: {
                shared: true,
                crosshairs: true,
                xDateFormat: '%d-%m-%Y',
                backgroundColor: 'rgba(255,255,255,0.9)',
                borderColor: '#000000',
                style: {
                    color: '#000000'
                },
                headerFormat: '<b>Booking Date: {point.key}</b><br/>',
                pointFormat: '<span style="color:{series.color};">\u25CF {series.name}: <b>{point.y}</b></span><br/>'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.05,
                    groupPadding: 0.1,
                    borderWidth: 0,
                    color: '#000000', // Black columns
                    dataLabels: {
                        enabled: true,
                        color: '#000000',
                        style: {
                            textOutline: 'none'
                        }
                    }
                },
                line: {
                    marker: {
                        enabled: true,
                        radius: 3,
                        symbol: 'circle',
                        fillColor: '#FF8B00',
                        lineColor: '#FF8B00',
                        lineWidth: 2
                    },
                    lineWidth: 2,
                    color: '#FF8B00'
                }
            },
            series: [
                {
                    name: 'Daily Bookings',
                    type: 'column',
                    data: formattedData.map(item => [item.date, item.bookings]),
                    yAxis: 0
                },
                {
                    name: 'Cumulative Bookings',
                    type: 'line',
                    data: cumulativeData,
                    yAxis: 1,
                    tooltip: {
                        valueDecimals: 0
                    }
                }
            ],
            credits: {
                enabled: false
            },
            legend: {
                enabled: true,
                itemStyle: {
                    color: '#000000'
                },
                itemHoverStyle: {
                    color: 'orange'
                }
            }
        };

        setChartOptions(options);
    };

    useEffect(() => {
        if (tenantId && propertyId && date && open) {
            getBookingHistory(tenantId, propertyId, date);
        }
    }, [tenantId, propertyId, date, open]);

    // Rerun chart formatting when time range changes
    useEffect(() => {
        if (bookingSeries.length > 0) {
            formatChartData(bookingSeries);
        }
    }, [timeRange]);

    const handleTimeRangeChange = (event, newTimeRange) => {
        if (newTimeRange !== null) {
            setTimeRange(newTimeRange);
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-start align-items-center mb-3 ms-4">
                <StyledToggleButtonGroup
                    color="primary"
                    value={timeRange}
                    exclusive
                    onChange={handleTimeRangeChange}
                    aria-label="Booking Time Range"
                >
                    <ToggleButton value="all">All Time</ToggleButton>
                    <ToggleButton value="lastWeek">Last Week</ToggleButton>
                    <ToggleButton value="lastMonth">Last Month</ToggleButton>
                </StyledToggleButtonGroup>
            </div>
            <div style={{ width: "100%", height: "500px" }} id="price-history-chart-container">
                {Object.keys(chartOptions).length > 0 && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            ...chartOptions,
                            chart: {
                                ...chartOptions.chart,
                                width: null,
                                height: 500
                            }
                        }}
                        containerProps={{ style: { width: '100%', height: '100%' } }}
                    />
                )}
            </div>
        </div>
    );
};

export default BookingHistory;