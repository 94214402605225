import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { buttonClasses } from "@mui/base/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { fetchToken, isValidRoute } from "../../Auth";
import NewUser from "./NewUser";
import ManageUser from "./ManageUser";
import ManageRoles from "./ManageRoles";

import ManageDesignation from "./ManageDesignation";

import "../../assets/styles/base.css";
import DashboardSessionTracker from "../../components/common/SessionTracker";
import useUserStore from "../../context/UserContext";

const Tab = styled(BaseTab)`
  font-family: inherit;
  color: #1c1c1c;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: transparent;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(255, 155, 94, 0.1);
  }

  &.${tabClasses.selected} {
    background-color: #ff9b5e;
    color: white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: inherit;
  font-size: 0.875rem;
  padding: 20px 0;
`;

const TabsList = styled(BaseTabsList)`
  width: 60%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 4px;
  margin-top: 10px;

  & > button {
    flex: 1;
  }
`;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const UserManagement = () => {
  const [dashboardId, setDashboardId] = useState("");
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const [dashboards, setDashboards] = useState([]);
  const [functionalities, setFunctionalities] = useState({});
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [userRoleDetails, setuserRoleDetails] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const getAllClients = async () => {
    try {
      const response = await axios.get(`/fetch_all_clients`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        const temp = [];
        for (const item of response.data) {
          temp.push({ label: item[1], value: item[0] });
        }
        setClients(temp);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate("/login");
      }
    }
  };
  const getUserRoleDetails = async () => {
    let key = 1;
    try {
      const response = await axios.get(`/get_user_roles_details/${key}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setuserRoleDetails(response.data);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response !== undefined && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getDesignation = async () => {
    try {
      const response = await axios.get("/get_user_designation", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        const allDesignations = response.data.map((item) => ({
          id: item[0],
          name: item[1],
          createdBy: item[3],
          createdAt: new Date(
            item[4].replace(
              /^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/,
              "$3-$2-$1 $4"
            )
          ),
          updatedAt: item[5],
          status: item[2],
        }));

        // Filter to only include active designations (status === 1)
        const filteredDesignations = allDesignations.filter(
          (des) => des.status === 1
        );

        // Set the filtered designations in state
        setDesignation(filteredDesignations);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response !== undefined && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getUserRoles = async () => {
    try {
      const response = await axios.get("/get_user_roles", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        const allRoles = response.data.map((item) => ({
          id: item[0],
          name: item[1],
          createdBy: item[3],
          createdAt: new Date(
            item[4].replace(
              /^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/,
              "$3-$2-$1 $4"
            )
          ),
          updatedAt: item[5],
          status: item[2],
        }));

        // Filter to only include active roles (status === 1)
        const filteredRoles = allRoles.filter((role) => role.status === 1);

        setRoles(filteredRoles);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response !== undefined && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getDashboards = async () => {
    try {
      const response = await axios.get("/get_active_dashboards", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setDashboards(response.data);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getFunctionalities = async () => {
    try {
      const response = await axios.get(`/get_active_functionalities`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setFunctionalities(response.data);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    getUserRoles();
    getDashboards();
    getFunctionalities();
    getAllClients();
    getDesignation();
    getUserRoleDetails();
  }, []);

  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };

    fetchId();
  }, []);

  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {
    // When tab changes to "Manage User" (index 2), refresh the data
    if (value === 2 || value === 0) {
      getUserRoles();
      getUserRoleDetails();
      getDesignation();
    }
  }, [value]);
  useEffect(() => {
    checkRoute();
  }, [dashboardId]);

  return (
    <>
      {/* <DashboardSessionTracker dashboardId={6}> </DashboardSessionTracker> */}
      {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}
      <Box sx={{ width: "100%", px: 2 }}>
        <Tabs
          defaultValue={value}
          onChange={(_, newValue) => setValue(newValue)}
        >
          <TabsList>
            <Tab value={0}>Create User</Tab>
            <Tab value={1}>Manage Roles & Access</Tab>
            <Tab value={2}>Manage User</Tab>
            <Tab value={3}>Manage Designation</Tab>
          </TabsList>
          <TabPanel value={0}>
            <NewUser
              roles={roles}
              clients={clients}
              setUsers={setUsers}
              users={users}
              designation={designation}
              userRoleDetails={userRoleDetails}
            />
          </TabPanel>
          <TabPanel value={1}>
            <ManageRoles
              functionalities={functionalities}
              dashboards={dashboards}
            />
            {/* <User roles={roles} users={users} setUsers={setUsers} clients={clients} designation={designation} />
            <UserScope /> */}
          </TabPanel>
          <TabPanel value={2}>
            <ManageUser
              clients={clients}
              roles={roles}
              designationOptions={designation}
              userRoleDetails={userRoleDetails}
              functionalities={functionalities}
              dashboards={dashboards}
            />
            {/* <UserRoles roles={roles} setRoles={setRoles} dashboards={dashboards} functionalities={functionalities} /> */}
          </TabPanel>
          <TabPanel value={3}>
            <ManageDesignation />
            {/* <UserRoles roles={roles} setRoles={setRoles} dashboards={dashboards} functionalities={functionalities} /> */}
          </TabPanel>
        </Tabs>
      </Box>
    </>
  );
};

export default UserManagement;
