import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"

export default function BasicSelect({ options, label, size, onChange, disabled, style, className }) {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl size={size ? size : ""} fullWidth style={style} className={className}>
      <InputLabel id="select">{label}</InputLabel>
      <Select label={label} onChange={handleChange}  sx={{
          backgroundColor: disabled ? "#f5f5f5" : "inherit",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: disabled ? "#d3d3d3" : undefined,
          },
        }}disabled={disabled}>
        {options.map((opt, index) => (
          <MenuItem key={index} value={opt["value"]}>
            {opt["option"]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

