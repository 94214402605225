import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  Typography,
  Button,
  Box,
  Input,
  TextField,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ReplayIcon from "@mui/icons-material/Replay"
import ClearIcon from "@mui/icons-material/Clear";
// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: "12px 16px",
  "&.header": {
    backgroundColor: "#000000",
    fontWeight: 600,
    color: "#ffffff",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
}));

const SearchField = styled(TextField)({
  width: "250px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
  },
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
  "&:last-child td, &:last-child th": { border: 0 },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: "4px",
  "& .MuiSvgIcon-root": { fontSize: 20, color: "#000000" },
  "&.Mui-checked .MuiSvgIcon-root": { color: "#000000" },
  "&.Mui-disabled .MuiSvgIcon-root": { color: "#bbbbbb" },
}));

// Define Privilege Mappings
const privilegeLevels = [
  { id: 1, label: "Reader" },
  { id: 2, label: "Maker" },
  { id: 3, label: "Checker" },
  { id: 4, label: "Full Access" },
  { id: 5, label: "No Access" },
];

export const FeatureAccessTable = ({ features = [], setFeatureAccess, mode = "edit" }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [resetALL,setResetALL] = useState(false);

  useEffect(() => {
    const hasReset = features.some(feature => mode === "edit" && feature.is_reset === 1);
    setResetALL(hasReset);

  }, [features, mode]);
  
  

  // Handles access change
  const handleAccessChange = (feature, privilegeId) => {
    setFeatureAccess((prevFeatures) =>
      prevFeatures.map((f) =>
        f.functionality_id === feature.functionality_id
          ? {
              ...f,
              privilege: privilegeId,
              is_reset: mode === "edit" && f.defaultReset === 1 && privilegeId !== f.originalPrivilege ? 1 : 0,
            }
          : f
      )
    );
  };

  // Handles reset functionality (only for edit mode)
  const handleReset = (featureId) => {
    if (mode === "create") return; // No reset in create mode

    setFeatureAccess((prevFeatures) =>
      prevFeatures.map((f) =>
        f.functionality_id === featureId
          ? { ...f, privilege: f.originalPrivilege, is_reset: 0 }
          : f
      )
    );
  };
  const handleResetALL = () => {
    if (mode === "create") return; 
    setFeatureAccess((prevFeatures) =>
      prevFeatures.map((f) =>
        f.privilege !== f.originalPrivilege 
          ? { ...f, privilege: f.originalPrivilege, is_reset: 0 }
          : f
      )
    );
  
    setResetALL(false);
  };
  

  if (!features || features.length === 0) {
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="feature access table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="header" colSpan={6}>
                <Typography variant="body2" color="textSecondary">
                  Please select a user role to view available features
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box>
      {/* Search Bar */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", p: 1, borderBottom: "1px solid #e0e0e0", gap: 2 }}>
      <SearchField
  placeholder="Search functionality..."
  variant="outlined"
  size="small"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  InputProps={{
    endAdornment: (
      <>
        {searchQuery && (
          <IconButton size="small" onClick={() => setSearchQuery("")} sx={{ padding: "2px" }}>
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
        <SearchIcon color="action" />
      </>
    ),
  }}
/>
        {resetALL && (
            <Button
              size="small"
              variant="contained"
              sx={{
                fontSize: "0.7rem",
                bgcolor: "black",
                color: "white",
                "&:hover": { bgcolor: "#333", boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)" },
              }}
              onClick={() => handleResetALL()}
            >
              Reset All
            </Button>
          )}

      </Box>

      {/* Table */}
      <TableContainer component={Paper} elevation={0} sx={{ maxHeight: "400px", overflow: "auto" }}>
        <Table aria-label="feature access table">
          <TableHead>
            <TableRow>
              <StyledTableCell className="header">Functionality Name</StyledTableCell>
              {privilegeLevels.map((privilege) => (
                <StyledTableCell key={privilege.id} className="header" align="center">
                  {privilege.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {features
              .filter((feature) => feature.functionality_name.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((feature) => {
                const hasReset = mode === "edit" && feature.is_reset === 1;
                return (
                  <StyledTableRow key={feature.functionality_id} className={hasReset ? "disabled" : ""}>
                    {/* Feature Name */}
                    <StyledTableCell component="th" scope="row">
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                      <Typography variant="body2">{feature.functionality_name}</Typography>
                      {hasReset && (
                        <Button
                        size="small"
                        sx={{
                          minWidth: "auto",
                          p: 0.5,
                          ml: 0.5,
                          color: "black",
                          border: "1px solid #e0e0e0",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          "&:hover": {
                            bgcolor: "rgba(0, 0, 0, 0.04)",
                            borderColor: "#bdbdbd",
                          },
                        }}
                        onClick={() => handleReset(feature.functionality_id)}
                      >
                          <ReplayIcon  fontSize="small" />
                          Reset
                        </Button>
                      )}
                    </Box>
                  </StyledTableCell>

                    {/* Privilege Selection */}
                    {privilegeLevels.map((privilege) => (
                      <StyledTableCell key={privilege.id} align="center">
                        <StyledRadio
                          checked={feature.privilege === privilege.id}
                          onChange={() => handleAccessChange(feature, privilege.id)}
                          value={privilege.id}
                          // disabled={hasReset}
                        />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
