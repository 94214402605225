import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import {fetchToken} from '../../Auth' 
import Dashboards from './Dashboards'
import Functionalities from './Functionalities'
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const AdminHome = () => {

  

  const [dashboards, setDashboards] = useState([]);

  const navigate = useNavigate();

  const getDashboards = async () => {
    try {
        const response = await axios.get('/get_all_dashboards', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
          },
        });
        if(response.status == 200 && response.data !== undefined){
          let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': new Date(item[5].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')), 'updatedAt': item[6] ? new Date(item[6].replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4')) : '', 'status': item[3]}))
          setDashboards(temp)
        }
    } catch (err) {
      console.error("Error", err);
      if(err.response.status === 401){
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    getDashboards()
  }, [])

	return(
		<>
       {/* <DashboardSessionTracker dashboardId={5}> </DashboardSessionTracker> */}
       <Functionalities dashboards={dashboards}/>
       <Dashboards dashboards={dashboards} setDashboards={setDashboards}/>
		</>
	);
};

export default AdminHome;