import { Box, Typography, Button, Paper, TextField, IconButton, TableContainer, LinearProgress } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import SearchIcon from "@mui/icons-material/Search"
import { Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import { useState, useEffect, useCallback } from "react"
import AssignedUsersDialog from "./AssignedUsersDialog"
import EditRoleDialog from "./EditRoleDialog"
import CreateRoleDialog from "./CreateRoleDialog"
import { DataGrid } from "@mui/x-data-grid"
import { Switch } from "@mui/material"
import axios from "axios"
import { fetchToken } from "../../Auth"
import { useNavigate } from "react-router-dom"
import FunctionalityDetailsDialog from "./FunctionalityDetailsDialog"
import { toast } from "react-toastify"

// Styled components with memoization
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: "20px",
  boxShadow: "none",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  overflow: "hidden",
}))

const SearchField = styled(TextField)({
  width: "250px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
  },
})

const CreateButton = styled(Button)({
  backgroundColor: "#1a1a1a",
  color: "white",
  borderRadius: "8px",
  textTransform: "none",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "#333",
  },
})

const TotalRolesBox = styled(Paper)({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  marginBottom: "20px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
})

const TotalRolesNumber = styled(Typography)({
  fontSize: "32px",
  color: "#FF8A00",
  lineHeight: 1,
})

const TotalRolesText = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  color: "#666",
  marginBottom: "4px",
})

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "20px",
})

const RightSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  alignItems: "flex-end",
})

// Custom switch component - memoized outside the component
const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }),
)

// Reusable button style for clickable cells
const cellButtonStyle = {
  color: "#1a1a1a",
  textDecoration: "underline",
  minWidth: "auto",
  padding: "0",
  transition: "all 0.2s ease",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
    color: "#FF8A00",
    transform: "translateY(-1px)",
  },
}

export default function ManageRoles({ dashboards, functionalities }) {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [selectedRoleForEdit, setSelectedRoleForEdit] = useState(null)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [userRoleDetails, setUserRoleDetails] = useState([])
  const [isFunctionalityDialogOpen, setIsFunctionalityDialogOpen] = useState(false)
  const [selectedFunctionalities, setSelectedFunctionalities] = useState(null)
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [loading, setLoading] = useState(true)

  // Parse date string to timestamp for sorting
  const parseDateToTimestamp = useCallback((dateStr) => {
    if (!dateStr) return 0
    try {
      const date = new Date(dateStr.replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, "$3-$2-$1 $4"))
      return date.getTime()
    } catch (error) {
      return 0
    }
  }, [])

  // Format date helper function - moved outside of the mapping function
  const formatDate = useCallback((dateStr) => {
    if (!dateStr) return "N/A"
    try {
      const date = new Date(dateStr.replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2}:\d{2})$/, "$3-$2-$1 $4"))
      return date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
    } catch (error) {
      return "N/A"
    }
  }, [])

  // API call for status toggle
  const handleStatusToggle = useCallback(
    async (roleId, newStatus) => {
      try {
        if (!newStatus) {
          const role = userRoleDetails?.roles?.find((role) => role.user_role_id === roleId)

          if (role && role.user_count > 0) {
            toast.warning("Cannot disable the role if users are assigned to it")
            return
          }
        }
        const token = fetchToken("zetta_access_token")
        if (!token) {
          throw new Error("Authentication token not found")
        }

        const response = await axios.put(
          `/update_user_role_status/${roleId}`,
          { is_active: newStatus ? 1 : 0 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (response.data.success) {
          setUserRoleDetails((prevState) => {
            const updatedRoles = prevState.roles.map((role) => {
              if (role.user_role_id === roleId) {
                return {
                  ...role,
                  is_active: newStatus ? 1 : 0,
                  updated_at: response.data.updated_role.updated_at,
                  last_updated_by: response.data.updated_role.last_updated_by,
                }
              }
              return role
            })

            return {
              ...prevState,
              roles: updatedRoles,
            }
          })
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.error("Error updating role status:", error)
        if (error.response?.status === 403) {
          alert("Access Denied: Insufficient Permissions")
        } else if (error.response?.status === 404) {
          alert("User role not found")
        } else {
          alert(error.response?.data?.message || "Failed to update role status")
        }
      }
    },
    [userRoleDetails?.roles?.find],
  ) // Added userRoleDetails?.roles?.find as a dependency

  // Fetch user role details
  const getUserRoleDetails = useCallback(async () => {
    setLoading(true)
    const key = 0
    try {
      const token = fetchToken("zetta_access_token")
      const response = await axios.get(`/get_user_roles_details/${key}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status === 200 && response.data !== undefined) {
        setUserRoleDetails(response.data)
      }
    } catch (err) {
      console.error("Error", err)
      if (err.response !== undefined && err.response.status === 401) {
        navigate("/login")
      }
    } finally {
      setLoading(false)
    }
  }, [navigate])

  // Initial data fetch
  useEffect(() => {
    getUserRoleDetails()
  }, []) 

  // Dialog handlers
  const handleFunctionalityClick = useCallback((functionalities) => {
    setSelectedFunctionalities(functionalities)
    setIsFunctionalityDialogOpen(true)
  }, [])

  const handleOpenDialog = useCallback(
    (role) => {
      const selectedRole = userRoleDetails?.roles?.find((r) => r.user_role_id === role.id)
      setSelectedRole({
        ...role,
        users: selectedRole?.users || [],
      })
      setIsDialogOpen(true)
    },
    [userRoleDetails],
  )

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false)
    setSelectedRole(null)
  }, [])

  const handleOpenEditDialog = useCallback(
    (role) => {
      const selectedRole = userRoleDetails?.roles?.find((r) => r.user_role_id === role.id)
      setSelectedRoleForEdit({
        id: selectedRole?.user_role_id,
        role: selectedRole?.user_role_name,
        dashboards: selectedRole?.dashboards || [],
        functionalities: selectedRole?.functionalities || [],
        users: selectedRole?.users || [],
        user_count: selectedRole?.user_count,
        created_at: selectedRole?.created_at,
        created_by: selectedRole?.created_by,
        updated_at: selectedRole?.updated_at,
        last_updated_by: selectedRole?.last_updated_by,
      })
      setIsEditDialogOpen(true)
    },
    [userRoleDetails],
  )

  const handleCloseEditDialog = useCallback(
    (success) => {
      setIsEditDialogOpen(false)
      setSelectedRoleForEdit(null)
      getUserRoleDetails()
    },
    [getUserRoleDetails],
  )

  const handleOpenCreateDialog = useCallback(() => {
    setIsCreateDialogOpen(true)
  }, [])

  const handleCloseCreateDialog = useCallback(
    (success) => {
      setIsCreateDialogOpen(false)
      getUserRoleDetails()
    },
    [getUserRoleDetails],
  )

  // Update columns when handlers change
  useEffect(() => {
    setColumns([
      {
        field: "role",
        headerName: "User Role",
        width: 130,
        sortable: true,
      },
      {
        field: "assignedUsers",
        headerName: "Assigned Users",
        width: 100,
        sortable: true,
        renderCell: (params) => (
          <Button
            onClick={() => params.value !== "0" && handleOpenDialog(params.row)}
            sx={{
              color: "#1a1a1a",
              textDecoration: params.value !== "0" ? "underline" : "none",
              minWidth: "auto",
              padding: "0",
              transition: "all 0.2s ease",
              "&:hover": {
                background: "none",
                textDecoration: params.value !== "0" ? "underline" : "none",
                color: params.value !== "0" ? "#FF8A00" : "#1a1a1a",
                transform: params.value !== "0" ? "translateY(-1px)" : "none",
              },
              cursor: params.value !== "0" ? "pointer" : "default",
              opacity: params.value !== "0" ? 1 : 0.6,
            }}
            disabled={params.value === "0"}
          >
            {params.value}
          </Button>
        ),
      },
      {
        field: "dashboardsAssigned",
        headerName: "Dashboards Assigned",
        width: 200,
        sortable: true,
      },
      {
        field: "functionalities",
        headerName: "Functionalities",
        width: 200,
        sortable: true,
        renderCell: (params) => (
          <Button
            onClick={() => params.value !== "---" && handleFunctionalityClick(params.row.functionalitiesData)}
            sx={{
              ...cellButtonStyle,
              textDecoration: params.value !== "---" ? "underline" : "none",
              cursor: params.value !== "---" ? "pointer" : "default",
              opacity: params.value !== "---" ? 1 : 0.6,
              "&:hover": {
                background: "none",
                textDecoration: params.value !== "---" ? "underline" : "none",
                color: params.value !== "---" ? "#FF8A00" : "#1a1a1a",
                transform: params.value !== "---" ? "translateY(-1px)" : "none",
              },
            }}
            disabled={params.value === "---"}
          >
            {params.value}
          </Button>
        ),
      },
      {
        field: "edit",
        headerName: "Edit",
        width: 80,
        sortable: false,
        renderCell: (params) => (
          <IconButton
            onClick={() => handleOpenEditDialog(params.row)}
            sx={{
              backgroundColor: "black",
              padding: "6px",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            <EditIcon sx={{ color: "white", fontSize: "18px" }} />
          </IconButton>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IOSSwitch
              checked={params.row.isActive === 1}
              onChange={(e) => handleStatusToggle(params.row.id, e.target.checked)}
            />
          </Box>
        ),
      },
      {
        field: "createdAt",
        headerName: "Created At",
        width: 180,
        sortable: true,
        valueGetter: (params) => params.row.createdAtTimestamp,
        renderCell: (params) => params.row.createdAt,
      },
      {
        field: "createdBy",
        headerName: "Created By",
        width: 200,
        sortable: true,
      },
      {
        field: "lastEditedAt",
        headerName: "Last Edited at",
        width: 180,
        sortable: true,
        valueGetter: (params) => params.row.lastEditedAtTimestamp,
        renderCell: (params) => params.row.lastEditedAt,
      },
      {
        field: "lastEditedBy",
        headerName: "Last Edited by",
        width: 200,
        sortable: true,
      },
    ])
  }, [handleOpenDialog, handleOpenEditDialog, handleFunctionalityClick, handleStatusToggle])

  // Update rows when userRoleDetails changes
  useEffect(() => {
    if (!userRoleDetails?.roles) {
      setRows([])
      return
    }

    const newRows = userRoleDetails.roles.map((item) => {
      // Format the dashboards into a comma-separated string
      const dashboardsList = item.dashboards.map((d) => d.dashboard_name).join(", ")
      const functionalitiesList = item.functionalities.map((f) => f.functionality_name).join(", ")

      // Parse dates to timestamps for sorting
      const createdAtTimestamp = parseDateToTimestamp(item.created_at)
      const lastEditedAtTimestamp = parseDateToTimestamp(item.updated_at)

      return {
        id: item.user_role_id,
        role: item.user_role_name,
        assignedUsers: item.user_count.toString(),
        dashboardsAssigned: dashboardsList || "No dashboards assigned",
        createdAt: formatDate(item.created_at) || "N/A",
        createdAtTimestamp: createdAtTimestamp,
        createdBy: item.created_by || "N/A",
        isActive: item.is_active,
        lastEditedAt: formatDate(item.updated_at),
        lastEditedAtTimestamp: lastEditedAtTimestamp,
        lastEditedBy: item.last_updated_by || item.created_by || "N/A",
        // Additional data for dialogs
        users: item.users,
        dashboards: item.dashboards,
        functionalities: functionalitiesList || "---",
        functionalitiesData: item.functionalities,
      }
    })

    setRows(newRows)
  }, [userRoleDetails, formatDate, parseDateToTimestamp])

  // Update filtered rows when search text or rows change
  useEffect(() => {
    if (!searchText) {
      setFilteredRows(rows)
      return
    }

    const searchLower = searchText.toLowerCase()
    const filtered = rows.filter((row) =>
      Object.values(row).some(
        (value) => value && typeof value === "string" && value.toLowerCase().includes(searchLower),
      ),
    )
    setFilteredRows(filtered)
  }, [rows, searchText])

  // Memoized filtered rows
  //const filteredRows = useMemo(() => {
  //  if (!searchText) return rows

  //  const searchLower = searchText.toLowerCase()
  //  return rows.filter((row) =>
  //    Object.values(row).some(
  //      (value) => value && typeof value === "string" && value.toLowerCase().includes(searchLower),
  //    ),
  //  )
  //}, [rows, searchText])

  // DataGrid style configuration
  const dataGridSx = {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "black",
      color: "white",
      fontSize: 12,
      fontWeight: 300,
    },
    "& .MuiDataGrid-columnHeader": {
      whiteSpace: "normal",
      lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "normal",
      lineHeight: "20px",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "white",
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },
  }

  return (
    <Box sx={{ padding: "16px" }}>
      <HeaderContainer>
        <TotalRolesBox>
          <TotalRolesText>Total Roles</TotalRolesText>
          <TotalRolesNumber>{userRoleDetails?.roles?.length || 0}</TotalRolesNumber>
        </TotalRolesBox>

        <RightSection>
          <CreateButton variant="contained" startIcon={<AddIcon />} onClick={handleOpenCreateDialog}>
            Create New User Role
          </CreateButton>
          <SearchField
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {searchText && (
                    <IconButton size="small" onClick={() => setSearchText("")} sx={{ padding: "2px" }}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                  <SearchIcon color="action" />
                </>
              ),
            }}
          />
        </RightSection>
      </HeaderContainer>

      <StyledTableContainer component={Paper}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          sx={dataGridSx}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          
          pageSizeOptions={[5,10, 20]}
          disableRowSelectionOnClick
          sortingMode="client"
          loading={loading}
        />
      </StyledTableContainer>
      {loading && (
        <LinearProgress
          sx={{
            backgroundColor: "#FFF0E0", // Lighter background color
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FF8A00", // The actual moving bar color
            },
          }}
        />
      )}

      {/* Dialogs */}
      {isDialogOpen && (
        <AssignedUsersDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          roleTitle={selectedRole?.role}
          userRoleDetails={userRoleDetails}
          selectedRoleId={selectedRole?.id}
        />
      )}

      {isEditDialogOpen && (
        <EditRoleDialog
          open={isEditDialogOpen}
          onClose={handleCloseEditDialog}
          selectedRoleId={selectedRoleForEdit?.id}
          dashboards={dashboards}
          functionalities={functionalities}
          userRoleDetails={userRoleDetails}
          onSuccess={getUserRoleDetails}
        />
      )}

      {isCreateDialogOpen && (
        <CreateRoleDialog
          open={isCreateDialogOpen}
          onClose={handleCloseCreateDialog}
          dashboards={dashboards}
          functionalities={functionalities}
          onSuccess={getUserRoleDetails}
        />
      )}

      {isFunctionalityDialogOpen && (
        <FunctionalityDetailsDialog
          open={isFunctionalityDialogOpen}
          onClose={() => setIsFunctionalityDialogOpen(false)}
          functionalities={selectedFunctionalities}
        />
      )}
    </Box>
  )
}

