import { useEffect,useState  } from 'react'
import { Routes, Route , useNavigate,useLocation} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import {AppProvider} from '../../context/AppContext';
import { isValidRoute } from '../../Auth';

import ManageHotels from './ManageHotels';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const HotelManagement = ({ match }) => {
  const [dashboardId,setDashboardId] = useState("")
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };
  
    fetchId();
  }, []);
  
  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {
    checkRoute()
  }, [])

  return(
    <AppProvider>
          <DashboardSessionTracker dashboardId={34}> </DashboardSessionTracker>
      <BaseLayout>
        <Routes>
            <Route path="manage-hotels" element={<ManageHotels />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default HotelManagement;