import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { fetchToken } from "../../../Auth";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { toast } from "react-toastify";
import { openUrlInIncognito, getRoomURL, formatDate } from "../../../services/helper"
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const FocusComp = ({
  tenantId,
  propertyId,
  roomType,
  mealPlan,
  ota,
  setOta,
  selfInvSource,
  invIntegrated,
  selfTariffSource,
  tariffIntegrated,
  date,
  baseFare,
  masterData,
  setMasterData,
  hotelDetails,
  mealPlanNames,
  userRole,
  currencySymbol,
  clientDataSources,
  trackOtaList,
  setOpenSnackBar,
  setSnackBarMessage,
  setSnackBarSeverity,
}) => {

  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  const [selfRow, setSelfRow] = useState({});


  const getNonFCData = async (selfData, fcRows, compIds) => {
    try {
      const response = await axios.post(
        `/fetch_non_fc_comp`,
        {
          propertyId: propertyId,
          roomType: roomType,
          mealPlan: mealPlan,
          ota: ota,
          date: date,
          compIds: compIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setSelfRow(selfData);
      const formattedRows = [...fcRows, ...response.data].map((res, index) => ({
        ...res,
        id: index + 1,
      }));

      setRows(formattedRows);
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        `/fetch_fc_details/${tenantId}/${propertyId}/${roomType}/${mealPlan}/${ota}/${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );

      // getNonFCData(
      //   response.data.self_data,
      //   response.data.comp_data,
      //   response.data.comp_ids
      // );
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const toggleFc = async (event, row) => {
    try {
      const isChecked = event.target.checked;
      const compPropertyId = parseInt(row.id);


      const data = {
        "status": isChecked ? "enable" : "disable",
        "comp_property_id": compPropertyId,
        "tenant_id": masterData['tenant_id'],
        "property_id": masterData['property_id'],
        "check_in": date,
      }

      const response = await axios.post("/toggle_fc", data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });

      if (response.status === 200) {

        setMasterData((prevState) => {
          const currentFcDisabled = prevState.fc_disabled || [];

          let updatedFcDisabled;

          if (isChecked) {
            updatedFcDisabled = currentFcDisabled.filter(id => id !== compPropertyId);
          } else {
            updatedFcDisabled = currentFcDisabled.includes(compPropertyId)
              ? currentFcDisabled
              : [...currentFcDisabled, compPropertyId];
          }

          const updatedState = {
            ...prevState,
            fc_disabled: updatedFcDisabled
          };

          return updatedState;
        });

        setSnackBarMessage(response?.data?.msg ? response.data.msg : "FC status changed")
        setSnackBarSeverity("success")
        setOpenSnackBar(true);

      }


    } catch (error) {

      console.error('Error in toggleFc:', error);
      setSnackBarMessage(error?.response?.data?.msg ? error.response.data.msg : "Something went wrong!")
      setSnackBarSeverity("error")
      setOpenSnackBar(true);

      if (error.response && error.response.status === 401) {
        navigate("/login");
      }

    }
  };


  const getHotelName = (property_id) => {
    let hotelName = "-"
    try {
      if (hotelDetails?.[property_id]?.['property_name']) {
        return hotelDetails[property_id]['property_name']
      }
    } catch (error) {
      console.error("Error in getting hotel name", error)
    }
    return hotelName
  }


  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.75,
      renderCell: (params) => (
        <div
          title={params.row.id}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%"
          }}
          className="bold"
        >
          {params.row.id}
        </div>
      ),
      renderHeader: () => (
        <div className="d-flex flex-column w-100">
          <div className="">
            ID
          </div>
          <hr style={{ margin: 0 }} />
          <div className={showID() ? "" : "d-none"}>
            {propertyId}
          </div>
        </div>
      ),
    },
    {
      sortable: false,
      headerName: "",
      width: 15,
      renderHeader: () => (
        <div className="d-flex flex-column w-100">
          <div className="text-white">
            Equivalent Room
          </div>
          {/* <hr style={{ margin: 0 }} /> */}
          <div className="">
            {masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length ? (
              <Tooltip title="Lowest available room">
                <AutoFixHighIcon
                  style={{ color: "orange", fontSize: "1.5em" }}
                />
              </Tooltip>
            ) : null}
          </div>
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            {params.row.is_equ ? (
              <Tooltip title="Lowest available room">
                <AutoFixHighIcon
                  style={{ color: "orange", fontSize: "1.5em" }}
                />
              </Tooltip>
            ) : null}
          </>
        );
      },
    },

    {
      field: `${ota}_name`,
      headerName: "Hotel Name",
      flex: 2.5,
      renderHeader: () => (
        <>
          <div className="d-flex flex-column w-100">
            <div>Hotel Name</div>
            <hr style={{ margin: 0 }} />
            <div
              title={getHotelName(propertyId)}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {/* {selfRow[`${ota}_name`]} */}
              {getHotelName(propertyId)}
            </div>
          </div>
        </>
      ),
      renderCell: (params) => (
        <div
          title={params.row[`${ota}_name`]}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%"  // Important for cell content
          }}
        >
          {params.row[`${ota}_name`]}
        </div>
      )
    },
    {
      field: "comp_mapped_room_type",
      headerName: "Room Type",
      flex: 1,
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const roomType = equivalentRoom ? masterData['equivalent_room'][selfDataSource].room_type : isIntegrated ? masterData.pms_room_type : masterData.room_type;
        // const initialRoomType = isIntegrated ? masterData.pms_room_type : masterData.room_type;
        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const roomType = equivalentRoom ? masterData['equivalent_room'][ota].room_type : masterData.room_type;
        const initialRoomType = masterData.room_type;
        return (
          <div
            title={roomType || "-"}
            className="d-flex flex-column"
          >
            <div>Room Type</div>
            <hr style={{ margin: 0 }} />
            <div title={(equivalentRoom ? `Equivalent Room Type: ${roomType}` : `Actual Room Type: ${roomType}`) || ""}>
              {roomType || "-"}
            </div>

            {
              equivalentRoom ?
                <div title={`Actual Room Type: ${initialRoomType}` || ""} className="text-decoration-line-through">
                  {initialRoomType || "-"}
                </div> : null
            }
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            <span title={params.row.comp_mapped_room_type ? `${params.row.is_equ ? "Equivalent Room Type: " : "Actual Room Type: "} ${params.row.comp_mapped_room_type}` : "-"}>
              {params.row.comp_mapped_room_type ? params.row.comp_mapped_room_type : "-"}
            </span>
            {params.row.is_equ &&
              <span title={params.row.initial_room_type ? `Actual Room Type: ${params.row.initial_room_type}` : "-"} className="text-decoration-line-through">
                {params.row.initial_room_type ? params.row.initial_room_type : "-"}
              </span>

            }
          </div>
        );
      },
    },
    {
      field: "comp_mapped_meal_plan",
      headerName: "Meal Plan",
      flex: 1,
      sortable: true,
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const mealPlan = equivalentRoom ? masterData['equivalent_room'][selfDataSource].meal_plan : isIntegrated ? masterData.pms_meal_plan : masterData.meal_plan;
        // const mealPlanTitle = mealPlanNames?.[mealPlan] || mealPlan;
        // const initialMealPlan = isIntegrated ? masterData.pms_meal_plan : masterData.meal_plan;
        // const initialMealPlanTitle = mealPlanNames?.[initialMealPlan] || initialMealPlan;

        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const mealPlan = equivalentRoom ? masterData['equivalent_room'][ota].meal_plan : masterData.meal_plan;
        const mealPlanTitle = mealPlanNames?.[mealPlan] || mealPlan;
        const initialMealPlan = masterData.meal_plan;
        const initialMealPlanTitle = mealPlanNames?.[initialMealPlan] || initialMealPlan;


        return (
          <div className="d-flex flex-column">
            <div>Meal Plan</div>
            <hr style={{ margin: 0 }} />
            <div title={(equivalentRoom ? `Equivalent Meal Plan: ${mealPlanTitle ? mealPlanTitle : mealPlan}` : `Actual Meal Plan: ${mealPlanTitle ? mealPlanTitle : mealPlan}`) || ""}>
              {mealPlan || "-"}
            </div>
            {equivalentRoom ?
              <div className="text-decoration-line-through" title={initialMealPlan ? `Actual Meal Plan: ${initialMealPlanTitle ? initialMealPlanTitle : initialMealPlan}` : "-"}>{initialMealPlan}</div>
              : null

            }
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            <span title={params.row.comp_mapped_meal_plan ? `${params.row.is_equ ? "Equivalent Meal Plan: " : "Actual Meal Plan: "} ${params.row.comp_mapped_meal_plan}` : "-"}>
              {params.row.comp_mapped_meal_plan ? params.row.comp_mapped_meal_plan : "-"}
            </span>
            {params.row.is_equ &&
              <span title={params.row.initial_meal_plan ? `Actual Meal Plan: ${params.row.initial_meal_plan}` : "-"} className="text-decoration-line-through">
                {params.row.initial_meal_plan ? params.row.initial_meal_plan : "-"}
              </span>

            }
          </div>
        );
      },

    },
    {
      field: "base_fare",
      headerName: "Fare",
      flex: 1,
      sortable: true,
      renderCell: (params) =>
        params.row.base_fare === "-"
          ? "-"
          : `${currencySymbol} ${parseInt(params.row.base_fare).toLocaleString("en-IN")}`,
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const priceInfo = equivalentRoom
        //   ? masterData['equivalent_room'][selfDataSource]['price_info']
        //   : masterData?.["price_info"]?.[selfDataSource];

        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const priceInfo = equivalentRoom
          ? masterData['equivalent_room'][ota]['price_info']
          : masterData?.["price_info"]?.[ota];

        const basePrice = priceInfo && priceInfo.length
          ? `${currencySymbol} ${Math.round(priceInfo[1]).toLocaleString("en-IN")}`
          : "-";

        return (
          <div className="d-flex flex-column">
            <div>Tariff</div>
            <hr style={{ margin: 0 }} />
            <div title={basePrice}>
              {basePrice}
            </div>
          </div>
        );
      },
    },
    {
      field: "base_fare_with_fees",
      headerName: "Total Fare",
      flex: 1,
      sortable: true,
      // renderCell: (params) =>
      //   params.row.base_fare_with_fees === "-"
      //     ? "-"
      //     : `${currencySymbol} ${parseInt(params.row.base_fare_with_fees).toLocaleString("en-IN")}`,

      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            {/* <div className={`${params.row.is_equ ? 'text-decoration-line-through' : ''}`}> */}
            <div title={params.row.base_fare_with_fees === "-" ? "-" : `${params.row.is_equ ? 'Equivalent Tariff: ' : 'Total Tariff: '} ${currencySymbol} ${parseInt(params.row.base_fare_with_fees).toLocaleString("en-IN")}`}>
              {
                params.row.base_fare === "-"
                  ? "-"
                  : `${currencySymbol} ${parseInt(params.row.base_fare_with_fees).toLocaleString("en-IN")}`
              }
            </div>
            {
              params.row.is_equ &&
              <div title={params.row.room_norm_price === "-" ? "-" : `Room Normalized Tariff: ${currencySymbol} ${parseInt(params.row.room_norm_price).toLocaleString("en-IN")}`}>
                {
                  params.row.room_norm_price === "-"
                    ? "-"
                    : `${currencySymbol} ${parseInt(params.row.room_norm_price).toLocaleString("en-IN")}`
                }
              </div>
            }

          </div>
        )
      },

      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const priceInfo = equivalentRoom
        //   ? masterData['equivalent_room'][selfDataSource]['price_info']
        //   : masterData?.['price_info']?.[selfDataSource];

        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const priceInfo = equivalentRoom
          ? masterData['equivalent_room'][ota]['price_info']
          : masterData?.['price_info']?.[ota];

        const totalPrice = priceInfo && priceInfo.length
          ? `${currencySymbol} ${Math.round(parseFloat(priceInfo[1]) + parseFloat(priceInfo[2])).toLocaleString("en-IN")}`
          : "-";

        return (
          <div className="d-flex flex-column">
            <div>Total Tariff</div>
            <hr style={{ margin: 0 }} />
            <div title={totalPrice}>
              {totalPrice}
            </div>
          </div>
        );
      },
    },
    {
      field: "parity_price",
      headerName: "Parity Fare",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const displayValue = params.row.parity_price === "-"
          ? "-"
          : `${currencySymbol} ${parseInt(params.row.parity_price).toLocaleString("en-IN")}`;

        return (
          <div title={params.row.parity_price === "-" ? "-" : `Normalized Tariff: ${displayValue}`}>
            {displayValue}
          </div>
        );
      },
      sortComparator: (v1, v2) => {
        if (v1 === "-" && v2 === "-") return 0;
        if (v1 === "-") return -1;
        if (v2 === "-") return 1;
        return parseInt(v1) - parseInt(v2);
      },
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const parityPrice = equivalentRoom
        //   ? masterData['equivalent_room'][selfDataSource]?.['room_norm_price'] ? `${currencySymbol} ${(Math.round(parseFloat(masterData['equivalent_room'][selfDataSource]['room_norm_price']))).toLocaleString("en-IN")}` : "-"
        //   : "-"

        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const parityPrice = equivalentRoom
          ? masterData['equivalent_room'][ota]?.['room_norm_price'] ? `${currencySymbol} ${(Math.round(parseFloat(masterData['equivalent_room'][ota]['room_norm_price']))).toLocaleString("en-IN")}` : "-"
          : "-"



        return (
          <div className="d-flex flex-column">
            <div title="Normalized Tariff">Normalized Tariff</div>
            <hr style={{ margin: 0 }} />
            <div title={parityPrice}>
              {parityPrice}
            </div>
          </div>
        );
      },
    },
    // {
    //   field: "discount",
    //   headerName: "Discount",
    //   sortable: false,
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params) =>
    //     params.row.discount === "-"
    //       ? "-"
    //       : `₹ ${parseInt(params.row.discount).toLocaleString("en-IN")}`,
    //   sortComparator: (v1, v2) => {
    //     if (v1 === "-" && v2 === "-") return 0;
    //     if (v1 === "-") return -1;
    //     if (v2 === "-") return 1;
    //     return parseInt(v1) - parseInt(v2);
    //   },
    //   renderHeader: () => (
    //     <>
    //       <div className="d-flex flex-column">
    //         <div>Discount</div>
    //         <hr style={{ margin: 0 }} />
    //         <div title={
    //           masterData?.["price_info"]?.[ota] ? `₹ ${Math.round(parseFloat(masterData["price_info"][ota][3])).toLocaleString("en-IN")}` : "-"
    //         }
    //         >
    //           {
    //             masterData?.["price_info"]?.[ota] ? `₹ ${Math.round(parseFloat(masterData["price_info"][ota][3])).toLocaleString("en-IN")}` : "-"
    //           }
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      field: "is_sold_out",
      headerName: "Status",
      flex: 1,
      sortComparator: (v1, v2) => {
        const order = {
          "Available": 1,
          "Sold Out": 2,
          "-": 3
        };
        return order[v1] - order[v2];
      },
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const soldOutStatus = equivalentRoom
        //   ? false  // Equivalent rooms imply availability
        //   : masterData?.["is_sold_out"]?.[selfDataSource];

        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const soldOutStatus = equivalentRoom
          ? false  // Equivalent rooms imply availability
          : masterData?.["is_sold_out"]?.[ota];

        const statusText = soldOutStatus !== undefined
          ? (soldOutStatus ? "Sold Out" : "Available")
          : "-";

        return (
          <div className="d-flex flex-column">
            <div>Status</div>
            <hr style={{ margin: 0 }} />
            <div title={statusText}>
              {statusText}
            </div>
          </div>
        );
      },
    },
    {
      field: "scrape_date",
      headerName: "Refresh Date",
      flex: 1.2,
      renderHeader: () => {
        // const equivalentRoom = masterData?.['is_sold_out']?.[selfDataSource] && masterData?.['equivalent_room']?.[selfDataSource] && Object.keys(masterData['equivalent_room'][selfDataSource]).length;
        // const lastUpdatedTime = equivalentRoom
        //   ? masterData['equivalent_room'][selfDataSource].last_updtm
        //   : masterData.last_updtm?.[selfDataSource];

        const equivalentRoom = masterData?.['is_sold_out']?.[ota] && masterData?.['equivalent_room']?.[ota] && Object.keys(masterData['equivalent_room'][ota]).length;
        const lastUpdatedTime = equivalentRoom
          ? masterData['equivalent_room'][ota].last_updtm
          : masterData.last_updtm?.[ota];

        const [date, time_tag] = lastUpdatedTime
          ? formatDate(lastUpdatedTime)?.split("@") || ["-", ""]
          : ["-", ""];

        return (
          <div className="d-flex flex-column">
            <div>Refresh Date</div>
            <hr style={{ margin: 0 }} />
            {date && time_tag ? (
              <div title={`${date} at ${time_tag}`}>
                <p className='m-0' style={{ height: "1rem" }}>{date}</p>
                <small className='text-muted small'>{`at ${time_tag}`}</small>
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
      renderCell: (params) => {
        const [date, time_tag] = formatDate(params.row.scrape_date)?.split("@") || ["-", ""]
        return (

          <div title={`${date}at${time_tag}`}>
            <p className='mb-0'>{date}</p>
            <small className='text-muted small'>at {time_tag}</small>
          </div>
        );
      },
    },
    {
      field: "is_fc",
      type: "actions",
      headerName: "Is Critical FC",
      flex: 1,
      cellClassName: "is_fc",
      getActions: ({ row }) => {
        return [
          <Switch
            checked={row.is_fc}
            className={"is_self" in row && row.is_self ? "d-none" : ""}
            size="small"
            // onChange={(event) => { toggleFc(event, row) }}
            disabled
          />,
        ];
      },
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>Is Focused</div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-center">-</div>
          </div>
        </>
      ),
    },
    {
      field: "room_url",
      type: "actions",
      headerName: "View on OTA",
      flex: 1,
      cellClassName: "room_url",
      renderHeader: () => (
        <>
          <div className="d-flex flex-column">
            <div>View On OTA</div>
            <hr style={{ margin: 0 }} />
            <div className="d-flex justify-content-center mt-3">
              {getRoomURL(ota, propertyId, date, hotelDetails, masterData?.los ? masterData.los : 1) ? (

                // <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(getRoomURL(selfDataSource, propertyId, date, hotelDetails)) }} />
                <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(getRoomURL(ota, propertyId, date, hotelDetails, masterData?.los ? masterData.los : 1)) }} />

              ) : (
                <OpenInNewIcon sx={{ color: "grey" }} />
              )}
            </div>
          </div>
        </>
      ),
      renderCell: (params) =>
        params.value ? (
          <OpenInNewIcon sx={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => { openUrlInIncognito(params.value) }} />
        ) : (
          <OpenInNewIcon sx={{ color: "grey" }} />
        ),
    },
  ];

  useEffect(() => {
    if (ota && date && tenantId && propertyId && roomType && mealPlan) {
      // getData();
    }
  }, [ota, date, tenantId, propertyId, roomType, mealPlan]);

  const generateCompRows = (comp) => {
    let dataList = []
    Object.entries(comp).forEach(([key, value]) => {

      try {
        let soldOut = null
        let isEqu = false
        let dataSource = {}
        let initialRoomType = value?.['room_type'] ? value['room_type'] : null
        let initialMealPlan = value?.['meal_plan'] ? value['meal_plan'] : null
        if (value?.['is_sold_out'] && value?.['equivalent_room'] && Object.keys(value['equivalent_room']).length) {
          dataSource = value['equivalent_room']
          isEqu = true
          soldOut = false  // Equivalent rooms imply availability
        } else {
          dataSource = value
          if (value?.['is_sold_out'] === true || value?.['is_sold_out'] === false) {
            soldOut = value['is_sold_out']
          }
        }
        const data = {
          id: key,
          [ota + "_name"]: getHotelName(key),
          comp_mapped_room_type: dataSource?.['room_type'] ? dataSource['room_type'] : "-",
          comp_mapped_meal_plan: dataSource?.['meal_plan'] ? dataSource['meal_plan'] : "-",
          comp_mapped_meal_plan_name: dataSource?.['meal_plan'] ? mealPlanNames?.[dataSource['meal_plan']] ? mealPlanNames[dataSource['meal_plan']] : "-" : "-",
          base_fare: dataSource?.['price_info'] && 1 in dataSource['price_info'] ? Math.round(dataSource['price_info'][1]) : "-",
          base_fare_with_fees: dataSource?.['price_info'] && dataSource['price_info'].length ? (parseFloat(dataSource['price_info'][1]) + parseFloat(dataSource['price_info'][2])) : "-",
          discount: dataSource?.['price_info'] ? Math.round(parseFloat(dataSource['price_info'][3])).toLocaleString("en-IN") : "-",
          is_sold_out: soldOut !== null ? soldOut ? "Sold Out" : "Available" : "-",
          scrape_date: dataSource?.['last_updtm'] ? dataSource.last_updtm : null,
          // is_fc: hotelDetails?.[key]?.['is_focused'] ? hotelDetails[key]['is_focused'] ? true : false : false,
          is_self: false,
          is_equ: isEqu,
          room_url: getRoomURL(ota, key, date, hotelDetails, masterData?.los ? masterData.los : 1),
          parity_price: dataSource?.['parity_price'] ? Math.round(dataSource['parity_price']) : "-",
          room_norm_price: dataSource?.['room_norm_price'] ? Math.round(dataSource['room_norm_price']) : "-",
          initial_room_type: initialRoomType,
          initial_meal_plan: initialMealPlan,
          is_fc: !masterData['fc_disabled'].includes(parseInt(key)),
        }
        dataList.push(data)
      } catch (error) {
        console.error("Error in iteration of generateCompRows", error)
      }
    });
    return dataList
  }

  const showID = () => {
    if ([1, 2, 6, 11].includes(parseInt(userRole))) {
      return true
    }
    return false
  }

  return (
    <div className="focus-comp-table-container">
      <div className="form-group mb-3">
        <FormControl fullWidth variant="outlined" className="w-15 mb-2">
          <InputLabel id="ota-select-label">OTA</InputLabel>
          <Select
            labelId="ota-select-label"
            id="ota-select"
            value={ota}
            label="OTA"
            onChange={(event) => { setOta(event.target.value) }}
          >
            {trackOtaList.map((opt) => (
              <MenuItem key={opt} value={opt}>{clientDataSources[opt].name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <DataGrid
        rows={
          masterData?.['comp']?.[ota] ? generateCompRows(masterData['comp'][ota]) : []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'is_sold_out',
                sort: 'asc'
              }
            ]
          }
        }}
        pageSizeOptions={[10]}
        // checkboxSelection
        disableColumnMenu
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
        }
        columnVisibilityModel={{
          id: showID(),
        }}
      />
      {/* <DataGrid
      rows={masterData?.['comp']?.[ota] ? generateCompRows(masterData['comp'][ota]) : []}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'is_sold_out',
              sort: 'asc',
            },
          ],
        },
      }}
      pageSizeOptions={[20]}
      disableColumnMenu
      disableRowSelectionOnClick
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
      }
      columnVisibilityModel={{
        id: showID(),
      }}
      sx={{
        height: '25em',
        overflowY: 'auto',
      }}
    /> */}

    </div>
  );
};

export default FocusComp;
