import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Calendar from "../../components/common/Calendar/Calendar";
import axios from "axios";
import {
  format,
  addDays,
  startOfWeek,
  setMonth,
  setYear,
  getYear,
  getMonth,
} from "date-fns";
import { fetchToken, isValidRoute } from "../../Auth";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import Drawer from "@mui/material/Drawer";
import FlagsSummary from "./FlagsSummary";
import LegendDrawer from "./Legend";

import { useTheme } from "@mui/material/styles";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import React from "react";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

import Collapse from "@mui/material/Collapse";

import FlagIcon from "../../components/common/Calendar/FlagIcon";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';


import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';


const drawerWidth = 100;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  width: "100%",
}));

const Master = () => {

  // const dashboardId = useUserStore((state) => state.dashboardId);
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const [dashboardId, setDashboardId] = useState("")
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log('Resetting dashboardId synchronously');
  //   useUserStore.getState().setdashId(null);
  // }, [location.pathname]);

  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };

    fetchId();
  }, []);

  const checkRoute = async () => {

    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {

    if (dashboardId) {
      checkRoute();

      getProperties();
    }
  }, [dashboardId]);

  const { name, email, role } = useUserStore();
  const currentDate = new Date();


  const [openDrawer, setOpenDrawer] = useState(false);
  const [tenantId, setTenantId] = useState("");
  const [monthOptions, setMonthOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [clientDataSources, setClientDataSources] = useState({})


  // StartDate and EndDate
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)


  // Selected Values
  const [currencyName, setCurrencyName] = useState("INR");
  const [currencyNameMain, setCurrencyNameMain] = useState("INR");

  const [currencySymbol, setCurrencySymbol] = useState("₹");
  const [currencySymbolMain, setCurrencySymbolMain] = useState("₹");

  const [ota, setOTA] = useState(null);
  const [otaMain, setOTAMain] = useState(null);

  const [selfInvSource, setSelfInvSource] = useState(null);
  const [selfInvSourceMain, setSelfInvSourceMain] = useState(null);

  const [invIntegrated, setInvIntegrated] = useState(false);
  const [invIntegratedMain, setInvIntegratedMain] = useState(false);

  const [selfTariffSource, setSelfTariffSource] = useState(null);
  const [selfTariffSourceMain, setSelfTariffSourceMain] = useState(null);

  const [tariffIntegrated, setTariffIntegrated] = useState(false);
  const [tariffIntegratedMain, setTariffIntegratedMain] = useState(false);



  const [selfDataSource, setSelfDataSource] = useState(null);
  const [pmsId, setPmsId] = useState("STAAH");
  const [month, setMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getMonth(currentDate));
  const [selectedYear, setSelectedYear] = useState(getYear(currentDate));

  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedPropertyMain, setSelectedPropertyMain] = useState("");

  const [selectedPropertyName, setSelectedPropertyName] = useState("");
  const [selectedPropertyNameMain, setSelectedPropertyNameMain] = useState("");

  const [trackOtaList, setTrackOtaList] = useState([]);
  const [trackOtaListMain, setTrackOtaListMain] = useState([]);

  // Master Data
  const [data, setData] = useState({});
  const [mealPlanNames, setMealPlanNames] = useState({})
  const [hotelDetails, setHotelDetails] = useState({});
  const [updateTime, setUpdateTime] = useState("");
  const [equRoomData, setEquRoomData] = useState({});

  // Travel Data
  const [trainData, setTrainData] = useState({});

  // Drawer Open
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [monthName, setMonthName] = useState("");
  const [dateRangeName, setDateRangeName] = useState("");
  const [filterOpen, setFilterOpen] = useState(true);

  const [filterApplied, setFilterApplied] = useState(false)

  // const [filteredData, setFilteredData] = useState({})

  const [customDate, setCustomDate] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [dateRanges, setDateRanges] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [filterNames, setFilterNames] = useState([
    {
      name: "Competition Price Increase",
      val: "comp_price_inc",
      color: "rgb(255, 174, 1)",
      icon: "inc",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CP",
      flagShort: "Comp price",
    },
    {
      name: "Competition Price Decrease",
      val: "comp_price_dec",
      color: "rgb(255, 174, 1)",
      icon: "dec",
      background: "linear-gradient(90deg, #FFC46B 0%, #FF5C01 100%)",
      initial: "CP",
      flagShort: "Comp price",
    },
    {
      name: "OverPriced",
      flagShort: "Over price",
      val: "overpriced",
      color: "#f68d8e",
      icon: "inc",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "OP",
    },
    {
      name: "UnderPriced",
      flagShort: "Under price",
      val: "underpriced",
      color: "#f68d8e",
      icon: "dec",
      background: "linear-gradient(90deg, #FFBBBB 0%, #FF3737 100%)",
      initial: "UP",
    },
  ]);

  const handleFilterOpen = () => {
    setFilterOpen((prevState) => !prevState);
  };

  // Flags
  const [flagsOpen, setFlagsOpen] = useState(false);
  const [flagCols, setFlagCols] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterCols, setFilterCols] = useState(["Enable/Disable", "Flags"]);
  const [filterRows, setFilterRows] = useState([
    { name: "Comp Tariff Increase", val: "comp_price_inc" },
    { name: "Comp Tariff Decrease", val: "comp_price_dec" },
    { name: "Overpriced", val: "overpriced" },
    { name: "Underpriced", val: "underpriced" },
    // { name: "Train Demand", val: "DemandSpike" },
  ]);
  const [rows, setRows] = useState([]);


  const getPreferredOTA = async () => {
    try {
      const response = await axios.get(`/get_preferred_ota/${selectedProperty}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      setOTA(response.data.preferred_ota)
      setSelfDataSource(response.data.self_data_source)
      // handleSubmit();
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  }

  const getProperties = async () => {
    try {
      const response = await axios.get(`/fetch_client_properties/${dashboardId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setTenantId(parseInt(response.data.client_id));

        setTrackOtaList(response.data.client_properties[0]['track_otas'])

        setTrackOtaListMain(response.data.client_properties[0]['track_otas'])

        setOTA(response.data.client_properties[0].preferred_ota);
        setOTAMain(response.data.client_properties[0].preferred_ota);

        setSelfInvSource(response.data.client_properties[0].self_inv_source);
        setSelfInvSourceMain(response.data.client_properties[0].self_inv_source);

        setSelfTariffSource(response.data.client_properties[0].self_tariff_source);
        setSelfTariffSourceMain(response.data.client_properties[0].self_tariff_source);

        setInvIntegrated(response.data.client_properties[0].inv_integrated);
        setInvIntegratedMain(response.data.client_properties[0].inv_integrated);

        setTariffIntegrated(response.data.client_properties[0].tariff_integrated);
        setTariffIntegratedMain(response.data.client_properties[0].tariff_integrated);

        setCurrencyName(response.data.client_properties[0].currency_name);
        setCurrencyNameMain(response.data.client_properties[0].currency_name);

        setCurrencySymbol(response.data.client_properties[0].currency_symbol);
        setCurrencySymbolMain(response.data.client_properties[0].currency_symbol);


        setProperties(response.data.client_properties);
        setSelectedProperty(
          parseInt(response.data.client_properties[0]["propertyId"])
        );
        setSelectedPropertyMain(
          parseInt(response.data.client_properties[0]["propertyId"])
        );

        setSelectedPropertyName(
          response.data.client_properties[0]["propertyName"]
        );
        setSelectedPropertyNameMain(
          response.data.client_properties[0]["propertyName"]
        );

      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getClientDataSources = async () => {
    try {
      const response = await axios.get("/get_client_data_sources", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
        }
      });
      if (response.status == 200 && response.data !== undefined) {
        setClientDataSources(response.data)
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  }

  // useEffect(() => {
  //   if (selectedProperty) getPreferredOTA();
  // }, [selectedProperty]);

  useEffect(() => {
    // getProperties();
    getClientDataSources();
  }, [])

  const [filters, setFilters] = useState({
    comp_price_inc: false,
    comp_price_dec: false,
    overpriced: false,
    underpriced: false,
    train_demand: false,
  });
  const [filterVal, setFilterVal] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  const handleFilterChange = (key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: !prevFilters[key],
    }));
  };
  const handleOptionChange = (event, value) => {
    setFilterOptions(value);
    setSelectedOptions(value);
    if (!value.length) {
      setSelectedFlagIndex(null);
      setSelectedFlagMonth(null);
      setSelectedFlagYear(null);
      setSelectedFlagName(null);
      setSelectedFlagNameMain(null);
      setFilters({
        comp_price_inc: false,
        comp_price_dec: false,
        overpriced: false,
        underpriced: false,
        train_demand: false,
      });
      setFilterVal([]);
    } else {
      const newFilters = Object.keys(filters).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Set selected options to true
      value.forEach((option) => {
        if (newFilters.hasOwnProperty(option.val)) {
          newFilters[option.val] = true;
        }
      });

      setFilters(newFilters);
      const trueKeys = Object.keys(newFilters).filter((key) => newFilters[key]);
      setFilterVal(trueKeys);
    }
  };

  const handleFilterApply = () => {
    setFilterApplied(true)
    // console.log(Object.keys(filters).filter(key => filters[key]))
    setSelectedFlagIndex(null);
    setSelectedFlagMonth(null);
    setSelectedFlagYear(null);
    setSelectedFlagName(null);
    setSelectedFlagNameMain(null);
    setFilterVal(Object.keys(filters).filter((key) => filters[key]));
    setFilterOptions([
      ...filterRows.filter((flag) => filters[flag.val] === true),
    ]);
    setSelectedOptions([
      ...filterRows.filter((flag) => filters[flag.val] === true),
    ]);
    setFiltersOpen(false);
  };

  function createData(
    name,
    d1,
    d2,
    d3,
    d4,
    d5,
    d6,
    d7,
    d8,
    d9,
    d10,
    d11,
    d12,
    d13
  ) {
    return { name, d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13 };
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const Legend = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  }));

  const LegendBox = styled("div")(({ color }) => ({
    width: "20px",
    height: "20px",
    backgroundColor: color,
    marginRight: "8px",
  }));

  const handlePropertyChange = (event) => {
    setSelectedProperty(event.target.value);
    const tempProperty = properties.find(
      (property) => property.propertyId === event.target.value
    );
    setOTA(tempProperty.preferred_ota);

    setSelectedPropertyName(tempProperty.propertyName);

    setSelfInvSource(tempProperty.self_inv_source);
    setInvIntegrated(tempProperty.inv_integrated);

    setSelfTariffSource(tempProperty.self_tariff_source);
    setTariffIntegrated(tempProperty.tariff_integrated);

    setTrackOtaList(tempProperty.track_otas);

    setCurrencyName(tempProperty.currency_name);
    setCurrencySymbol(tempProperty.currency_symbol);

  };



  const fetchFlagSummary = async () => {
    try {
      const response = await axios.get(
        `/flag_summary/${tenantId}/${selectedProperty}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setRows([
        createData(
          response.data[0][0],
          response.data[0][1],
          response.data[0][2],
          response.data[0][3],
          response.data[0][4],
          response.data[0][5],
          response.data[0][6],
          response.data[0][7],
          response.data[0][8],
          response.data[0][9],
          response.data[0][10],
          response.data[0][11],
          response.data[0][12],
          response.data[0][13]
        ),
        createData(
          response.data[1][0],
          response.data[1][1],
          response.data[1][2],
          response.data[1][3],
          response.data[1][4],
          response.data[1][5],
          response.data[1][6],
          response.data[1][7],
          response.data[1][8],
          response.data[1][9],
          response.data[1][10],
          response.data[1][11],
          response.data[1][12],
          response.data[1][13]
        ),
        createData(
          response.data[2][0],
          response.data[2][1],
          response.data[2][2],
          response.data[2][3],
          response.data[2][4],
          response.data[2][5],
          response.data[2][6],
          response.data[2][7],
          response.data[2][8],
          response.data[2][9],
          response.data[2][10],
          response.data[2][11],
          response.data[2][12],
          response.data[2][13]
        ),
        createData(
          response.data[3][0],
          response.data[3][1],
          response.data[3][2],
          response.data[3][3],
          response.data[3][4],
          response.data[3][5],
          response.data[3][6],
          response.data[3][7],
          response.data[3][8],
          response.data[3][9],
          response.data[3][10],
          response.data[3][11],
          response.data[3][12],
          response.data[3][13]
        ),
        // createData(
        //   response.data[4][0],
        //   response.data[4][1],
        //   response.data[4][2],
        //   response.data[4][3],
        //   response.data[4][4],
        //   response.data[4][5],
        //   response.data[4][6],
        //   response.data[4][7],
        //   response.data[4][8],
        //   response.data[4][9],
        //   response.data[4][10],
        //   response.data[4][11],
        //   response.data[4][12],
        //   response.data[4][13]
        // ),
      ]);
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  function getMonthAndYear(dateString) {
    const date = new Date(dateString);
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return { monthIndex, year };
  }

  function filterAndTake28FromKey(data, filterDate) {
    const entries = Object.entries(data);
    const sortedEntries = entries.sort(([keyA], [keyB]) => {
      return new Date(keyA) - new Date(keyB);
    });
    const filterIndex = sortedEntries.findIndex(([key]) => key === filterDate);
    if (filterIndex === -1) {
      throw new Error("Filter date not found in the data.");
    }
    const slicedEntries = sortedEntries.slice(filterIndex, filterIndex + 28);
    return Object.fromEntries(slicedEntries);
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function formatDateOrdinal(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const day = date.getDate();
    const month = months[date.getMonth()];

    const year = date.getFullYear().toString().slice(-2);

    const getOrdinal = (n) => {
      if (n > 3 && n < 21) return 'th';
      switch (n % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${day}${getOrdinal(day)} ${month} ${year}`;
  }



  const handleSubmit = async () => {
    setIsLoading(true)
    if (!selectedProperty) {
      alert("Please select a property!");
      return;
    }

    let filterDate;
    if (customDate === "") {
      toast.success("Please select a valid custom date", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    }
    if (customDate) {

      filterDate = date;
      setDateRangeName(`Custom Date: ${date}`);

    } else {

      filterDate = formatDate(selectedDateRange.range.start)

    }




    try {
      let modifiedDate = "";
      let [y, m, d] = filterDate.split("-");
      modifiedDate = `${y}-${String(m).padStart(2, "0")}-${String(d).padStart(
        2,
        "0"
      )}`;


      const inputDateObj = new Date(modifiedDate);
      const twentyEightDaysFromNow = new Date();
      twentyEightDaysFromNow.setDate(twentyEightDaysFromNow.getDate() - 29);


      if (inputDateObj < twentyEightDaysFromNow) {
        alert('Custom date cannot be less than 28 days from the current date');
        return;
      }


      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

      if (inputDateObj > oneYearFromNow) {
        alert('Custom date cannot be greater than 1 year');
        return;
      }


      const response = await axios.get(
        `/master_data/${selectedProperty}/${modifiedDate}`,
        {
          // const response = await axios.get(`/master_data/${selectedProperty}/${ota}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      if (response.status == 200 && response.data !== undefined) {

        setOTAMain(ota);

        setSelectedPropertyMain(selectedProperty);

        setSelectedPropertyNameMain(selectedPropertyName);

        setSelfInvSourceMain(selfInvSource);

        setInvIntegratedMain(invIntegrated);

        setSelfTariffSourceMain(selfTariffSource);

        setTariffIntegratedMain(tariffIntegrated)

        setTrackOtaListMain(trackOtaList);

        setCurrencyNameMain(currencyName);

        setCurrencySymbolMain(currencySymbol);

        setHotelDetails(response.data.hotel_details);

        setMealPlanNames(response.data.meal_plan_names);



        setData({})

        setSelectedDate(filterDate);
        // setOTA(response.data.preferred_ota)
        // setSelfDataSource(response.data.self_data_source)
        const fetchedData = response.data.data;
        // const currentDate = new Date();

        const mondayDate = new Date(getMondayDate(filterDate));
        const dayGap = daysBetweenDates(new Date(filterDate), mondayDate);
        const finalDate = getFinalSundayDate(filterDate, 30);


        setStartDate(mondayDate);
        setEndDate(finalDate);

        const filteredData = Object.keys(fetchedData)
          .filter((key) => {
            const date = new Date(key);

            // return date >= mondayDate && date < new Date(mondayDate.getTime() + (31 + dayGap) * 24 * 60 * 60 * 1000);
            return date >= mondayDate && date <= new Date(finalDate);
            // return date <= new Date(finalDate)
          })
          .reduce((obj, key) => {
            obj[key] = fetchedData[key];
            return obj;
          }, {});


        const index = getMonthAndYear(modifiedDate);

        const startDate = new Date(selectedYear, selectedMonth, 1);
        const lengthFromStart = getLengthFromStartDate(startDate, fetchedData);

        const dayOfWeek = new Date(modifiedDate).getDay();
        if (dayOfWeek === 1) {
          setCalLength(28);
        } else {
          setCalLength(35);
        }

        // setCalLength(28)
        const result = filterAndTake28FromKey(filteredData, modifiedDate);
        setData(result);
        // setSelectedFlagMonth(index.monthIndex)
        // setSelectedFlagYear(index.year)
        setFilterVal(filterVal)
        setSelectedFlagMonth(null)
        setSelectedFlagYear(null)
        setUpdateTime(response.data.last_update_dtm);
        setSelectedFlagIndex(selectedDateRange['index'])
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
    fetchFlagSummary();
  };

  const getEquRooms = async (dates) => {
    try {
      const response = await axios.post(
        `/fetch_equ_rooms`,
        {
          tenantId: tenantId,
          propertyId: selectedProperty,
          dates: dates,
          ota: ota,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setEquRoomData(response.data);
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getTrainData = async (dates) => {
    try {
      const response = await axios.post(
        `/travel_data/fetch_train_data`,
        {
          tenantId: tenantId,
          propertyId: selectedProperty,
          dates: dates,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setTrainData(response.data);
    } catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      let dates = [];
      for (let date in data) {
        if (data[format(date, "yyyy-MM-dd")]["master_doc_missing"]) {
          dates.push(format(date, "yyyy-MM-dd"));
        }
      }

      // getEquRooms(dates);
      // getTrainData(Array.from(Object.keys(data))); // uncomment this to get train data
    }
  }, [data]);

  const [selectedFlagIndex, setSelectedFlagIndex] = useState(null);
  const [selectedFlagName, setSelectedFlagName] = useState(null);
  const [selectedFlagNameMain, setSelectedFlagNameMain] = useState(null);
  const [selectedFlagMonth, setSelectedFlagMonth] = useState(null);
  const [selectedFlagYear, setSelectedFlagYear] = useState(null);
  const createMonthArray = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthArray = {};

    for (let i = 0; i < 13; i++) {
      const monthIndex = (currentMonth + i) % 12;
      const year = currentYear + Math.floor((currentMonth + i) / 12);
      const monthName = new Date(year, monthIndex, 1).toLocaleString(
        "default",
        { month: "short" }
      );

      monthArray[i + 1] = year;
    }

    return monthArray;
  };

  const handleFlagClick = async (index, flagName, count) => {
    setFilterApplied(false)
    setFilters({
      comp_price_inc: false,
      comp_price_dec: false,
      overpriced: false,
      underpriced: false,
    });
    setFilterVal([]);

    const monthArray = createMonthArray();
    if (!count) {
      return;
    }
    setSelectedFlagIndex(index);
    setSelectedFlagNameMain(flagName);
    // setSelectedFlagMonth(monthArrayMain[flagCols[index - 1]]);
    // setSelectedFlagYear(monthArray[index]);
    setSelectedFlagYear(null)
    setSelectedFlagMonth(null)
    const flag_key = filterRows.find((filter) => {
      return filter.name === flagName;
    });
    setSelectedOptions([flag_key]);
    setFilterOptions([]);
    setSelectedFlagName(flag_key.val);
    const selectedFilterVals = filterRows.reduce((acc, filterRow) => {
      if (flagName === filterRow.name) {
        acc.push(filterRow.val);
      }
      return acc;
    }, []);

    let filterDate = `${monthArray[index]}-${monthArrayMain[flagCols[index - 1]]
      }-01`;

    let modifiedDate = "";
    let [y, m, d] = filterDate.split("-");
    modifiedDate = `${y}-${String(m).padStart(2, "0")}-${String(d).padStart(
      2,
      "0"
    )}`;

    setSelectedDateRange(dateRanges[index - 1])
    let new_date = formatDate(dateRanges[index - 1].range.start)
    const response = await axios.get(
      `/master_data/${selectedProperty}/${new_date}`,
      {
        // const response = await axios.get(`/master_data/${selectedProperty}/${ota}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      }
    );
    if (response.status == 200 && response.data !== undefined) {
      setData({})
      const fetchedData = response.data.data;

      const mondayDate = new Date(getMondayDate(new_date));
      const finalDate = getFinalSundayDate(new_date, 30);

      const filteredData = Object.keys(fetchedData)
        .filter((key) => {
          const date = new Date(key);
          return date >= mondayDate && date <= new Date(finalDate);
        })
        .reduce((obj, key) => {
          obj[key] = fetchedData[key];
          return obj;
        }, {});

      setSelectedDate(new_date);
      setSelectedYear(monthArray[index]);
      if (monthArrayMain[flagCols[index - 1]] == 12) {
        setSelectedMonth(monthArrayMain[flagCols[index - 1]] + 1);
      }
      else {
        setSelectedMonth(monthArrayMain[flagCols[index - 1]]);

      }
      const dayOfWeek = new Date(new_date).getDay();
      if (dayOfWeek === 1) {
        setCalLength(28);
      } else {
        // setCalLength(Object.keys(filteredData).length);
        setCalLength(35);
      }
      // setCalLength(28)

      setMonthName(
        getMonthName(monthArrayMain[flagCols[index - 1]] - 1, monthArray[index])
      );
      setFilterVal(selectedFilterVals);
      const result = filterAndTake28FromKey(filteredData, new_date);
      setData(result);
      let months = [monthArrayMain[flagCols[index - 1]] - 1, monthArray[index]];
      setFilterOpen(filterOpen);
      setMonth(months);
      // setFilterOpen(false)
    }
  };

  function createData(
    name,
    d1,
    d2,
    d3,
    d4,
    d5,
    d6,
    d7,
    d8,
    d9,
    d10,
    d11,
    d12,
    d13
  ) {
    return { name, d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13 };
  }

  useEffect(() => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth(); // Get month index (0-11)
    const currentYear = currentDate.getFullYear(); // Get full year (e.g., 2024)

    const formattedMonthYear = getMonthName(currentMonthIndex, currentYear);
    setMonthName(formattedMonthYear);
  }, []);
  const [calLength, setCalLength] = useState(0);
  const [date, setDate] = useState("");

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };



  const handleDateRangeChange = (newValue) => {
    setSelectedDateRange(newValue)
    setDateRangeName(newValue.label)
    if (newValue.key === "custom_date") {
      setCustomDate(true);
    } else {
      setDate("")
      setCustomDate(false);
    }

  };

  const handleMonthChange = (selectedOption) => {
    const selectedMonth = selectedOption.month[0]; // Extract the month from the selected option
    const selectedYear = selectedOption.month[1]; // Extract the year
    setMonth(selectedOption.month); // Set the month state
    setSelectedMonth(selectedMonth);
    setSelectedYear(selectedYear);
    setMonthName(getMonthName(selectedMonth, selectedYear));

    if (selectedMonth === 12) {
      setCustomDate(true);
    } else {
      setCustomDate(false);
    }
  };

  function getMonthName(index, value) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "Custom",
    ];
    if (index >= 0 && index < monthNames.length) {
      return `${monthNames[index]} ${value}`;
    } else {
      return "Invalid month index"; // Handle out-of-range indices
    }
  }

  useEffect(() => {
    const result = [];
    const shortMonthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    let currentStartDate = new Date();
    let currentLabelIndex = 0;
    let currentFlagIndex = 0;


    // Generate date ranges for 1 year (12 months)
    for (let i = 0; i < 13; i++) {
      const rangeStartDate = new Date(currentStartDate);
      const rangeEndDate = new Date(currentStartDate);
      rangeEndDate.setDate(rangeStartDate.getDate() + 27);
      result.push({
        label: `${formatDateOrdinal(rangeStartDate)} - ${formatDateOrdinal(rangeEndDate)}`, // Label for the range
        range: {
          start: rangeStartDate,
          end: rangeEndDate,
        },
        key: `R${currentLabelIndex} ${shortMonthNames[rangeStartDate.getMonth()]}`,
        index: i + 1,
        summaryLabel: `R${i}`
      });

      currentStartDate = new Date(rangeEndDate);
      currentStartDate.setDate(currentStartDate.getDate() + 1);

      currentLabelIndex++;
    }

    const prevRangeStartDate = new Date();
    prevRangeStartDate.setDate(prevRangeStartDate.getDate() - 28);
    const prevRangeEndDate = new Date(prevRangeStartDate);
    prevRangeEndDate.setDate(prevRangeStartDate.getDate() + 27);

    result.push({
      label: `${formatDateOrdinal(prevRangeStartDate)} - ${formatDateOrdinal(prevRangeEndDate)}`,
      range: {
        start: prevRangeStartDate,
        end: prevRangeEndDate,
      },
      key: "RO(-1) Previous Range",
      index: 13,
      summaryLabel: `R -1`
    });

    result.push({
      label: 'Custom Date',
      key: `custom_date`,
    });

    setSelectedDateRange(result[0]);
    setDateRanges(result);
    setDateRangeName(result[0].label)

    const ro_values = Array.from({ length: 13 }, (_, i) => `R${i}`);
    setFlagCols(
      result.slice(0, 13)
    );
  }, []);



  function getMondayDate(date) {
    const result = new Date(date);
    const dayOfWeek = result.getDay();
    // Calculate how many days to subtract to get to Monday
    const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    result.setDate(result.getDate() - daysToMonday);

    // Set hours, minutes, seconds, and milliseconds to zero
    result.setHours(0, 0, 0, 0);
    return result;
  }

  const getLengthFromStartDate = (startDate, dataObject) => {
    let length = 0;

    for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
        const date = new Date(key);
        const normalizedDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        const normalizedStartDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        );
        if (normalizedDate.getTime() === normalizedStartDate.getTime()) {
          break;
        }

        length++;
      }
    }

    return length;
  };

  const [selectedDate, setSelectedDate] = useState("");

  function daysBetweenDates(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
  }

  function addDaysToDate(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function getNextSunday(date) {
    const result = new Date(date);
    const dayOfWeek = result.getDay();

    // If the day is not Sunday (0), calculate the next Sunday
    if (dayOfWeek !== 0) {
      result.setDate(result.getDate() + (7 - dayOfWeek));
    }

    return result;
  }

  function setTimeToDate(date, hours, minutes) {
    const newDate = new Date(date);
    newDate.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds
    return newDate;
  }

  function getFinalSundayDate(filterDate, daysToAdd) {
    // Add days to the initial date
    const dateAfterAddingDays = addDaysToDate(filterDate, daysToAdd);

    // Check the day of the week for the new date
    const dayOfWeekAfterAddingDays = dateAfterAddingDays.getDay();

    // Get the final Sunday date if not already a Sunday
    const finalDate =
      dayOfWeekAfterAddingDays === 0
        ? dateAfterAddingDays
        : getNextSunday(dateAfterAddingDays);

    // Set time to 5:30 PM
    return setTimeToDate(finalDate, 17, 30); // 17:30 in 24-hour format
  }

  useEffect(() => {
    if (Object.keys(data).length) {
      let dates = [];
      for (let date in data) {
        if (data[format(date, "yyyy-MM-dd")]["master_doc_missing"]) {
          dates.push(format(date, "yyyy-MM-dd"));
        }
      }
      // getEquRooms(dates);
    }
  }, [data]);

  const monthArrayMain = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  return (
    <>
      {/* <DashboardSessionTracker dashboardId={35}> </DashboardSessionTracker> */}
      {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}
      <section className="admin-card px-3 mt-2 mb-3 w-100 master-section-card">
        <Collapse in={filterOpen} timeout={140}>
          {/* {filterOpen && */}
          <div className="row master-filter-container">
            <div className="col-7 d-flex">
              <div className="col-4">

                <FormControl fullWidth
                  title={selectedDateRange ? selectedDateRange.label : "Select Date Range"}
                >
                  <Autocomplete
                    id="date-range-autocomplete"
                    options={dateRanges}
                    getOptionLabel={(option) => option.label}
                    value={selectedDateRange}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleDateRangeChange(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" placeholder="Select Date Range" label="Date Range" />
                    )}
                  />
                </FormControl>





              </div>
              {customDate && (
                <div className="ml-3" style={{ fontSize: "1.25em" }}>
                  <input
                    min={new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0]}
                    max={new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]}
                    type="date"
                    id="date-picker"
                    value={date}
                    onChange={handleDateChange}
                    style={{
                      width: "12em",
                      padding: "15px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              )}

              <div className="col-4 ml-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Property</InputLabel>
                  {typeof properties !== "undefined" &&
                    properties.length &&
                    selectedProperty ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedProperty}
                      label="Property"
                      onChange={handlePropertyChange}
                    >
                      {properties.map((item) => (
                        <MenuItem value={item.propertyId}>
                          {item.propertyName}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : null}
                </FormControl>
              </div>

              <div className="col-1 ml-3 d-flex align-items-center">
                <Button onClick={handleSubmit} size="large" variant="contained" disabled={isLoading}>
                  Submit
                </Button>
              </div>
              <div className="col-1 ml-2 d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </div>
            </div>
            <div className="col-5 row">
              {selectedOptions.length ? (

                <div className="d-flex align-items-center justify-content-end">
                  <div className="d-flex justify-content-end align-items-center">
                    <Autocomplete
                      multiple
                      freeSolo
                      id="tags-outlined"
                      options={filterOptions}
                      getOptionLabel={(option) => option.name}
                      size="small"
                      renderTags={(value, getTagProps) => {
                        return value.length > 0 ? (
                          <>
                            <div title={value.map((opt) => opt.name).join(", ")}>
                              <Chip label={value[0].name} {...getTagProps({ index: 0 })} />
                              {value.length > 1 && (
                                <span>...</span>
                              )}
                            </div>
                          </>
                        ) : null;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          label={filterApplied ? "Applied Filter" : "Applied Flags"}
                        />
                      )}
                      value={selectedOptions}
                      sx={{ width: "100%" }}
                      open={false}
                      disableCloseOnSelect
                      onChange={handleOptionChange}
                    >
                    </Autocomplete>



                  </div>
                  <div className="d-flex ml-2">
                    <Button
                      size="large"
                      variant="outlined"
                      disabled={Array.from(Object.keys(data)).length === 0}
                      onClick={() => {
                        setFiltersOpen(true);
                      }}
                    >
                      <i className="fa-solid fa-filter mx-1"></i> Filters
                    </Button>
                    <Button
                      sx={{ marginLeft: "1em" }}
                      disabled={Array.from(Object.keys(data)).length === 0}
                      onClick={() => setFlagsOpen(true)}
                      size="large"
                      variant="outlined"
                    >
                      <i className="fa-solid fa-flag mx-1"></i> Flags
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="col-12 d-flex justify-content-end align-items-center">
                  <Button
                    size="large"
                    variant="outlined"
                    disabled={Array.from(Object.keys(data)).length === 0}
                    onClick={() => {
                      setFiltersOpen(true);
                    }}
                  >
                    <i className="fa-solid fa-filter mx-1"></i> Filters
                  </Button>
                  <Button
                    sx={{ marginLeft: "1em" }}
                    disabled={Array.from(Object.keys(data)).length === 0}
                    onClick={() => setFlagsOpen(true)}
                    size="large"
                    variant="outlined"
                  >
                    <i className="fa-solid fa-flag mx-1"></i> Flags
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* } */}
        </Collapse>



        <div
          className={`${filterOpen ? '' : 'mt-3'} d-flex justify-content-between ${filterOpen ? "drawer-btn-container" : "drawer-btn-container-close"
            } `}
        >
          <div className="col-4 d-flex align-items-center">
            <button onClick={handleFilterOpen} className="collapse-btn">
              {filterOpen ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
            </button>
            {!filterOpen &&
              (
                <>
                  <div className="d-flex align-items-center ml-3">

                    <h5 className="bold">{dateRangeName}</h5>
                  </div>
                </>
              )}
          </div>



          {!filterOpen &&
            Object.keys(data).length > 0 &&
            (filterOptions.length > 0 ? (
              <div className="d-flex align-items-center drawer-filter">
                <div className="col-7 d-flex justify-content-end align-items-center">
                  <Autocomplete
                    multiple
                    freeSolo
                    id="tags-outlined"
                    options={filterOptions}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        label={filterApplied ? "Applied Filter" : "Applied Flags"}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      return value.length > 0 ? (
                        <>
                          <div title={value.map((opt) => opt.name).join(", ")}>
                            <Chip label={value[0].name} {...getTagProps({ index: 0 })} />
                            {value.length > 1 && (
                              <span>...</span>
                            )}
                          </div>
                        </>
                      ) : null;
                    }}

                    value={selectedOptions}
                    sx={{ width: "100%" }}
                    open={false}
                    disableCloseOnSelect
                    onChange={handleOptionChange}
                  />
                </div>
                <div className="col-5 d-flex ml-3">
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ height: "2.8em" }}
                    disabled={Object.keys(data).length === 0}
                    onClick={() => setFiltersOpen(true)}
                  >
                    <i className="fa-solid fa-filter mx-1"></i> Filters
                  </Button>
                  <Button
                    sx={{ marginLeft: "1em", height: "2.5em" }}
                    size="medium"
                    variant="outlined"
                    disabled={Object.keys(data).length === 0}
                    onClick={() => setFlagsOpen(true)}
                  >
                    <i className="fa-solid fa-flag mx-1"></i> Flags
                  </Button>
                </div>
              </div>
            ) : (
              <div className="col-7 d-flex justify-content-end align-items-center ml-3">
                {/* <div className="d-flex align-items-center">
                  
                  <h5 className="bold">{dateRangeName}</h5>
                </div> */}
                {/* <div className="col-7 d-flex justify-content-end align-items-center">
                  {!filterOpen
                    &&
                    (
                      <>
                        <Autocomplete
                          multiple
                          freeSolo
                          id="tags-outlined"
                          options={filterOptions}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                              label={filterApplied ? "Applied Filter" : "Applied Flags"}
                            />
                          )}
                          renderTags={(value, getTagProps) => {
                            return value.length > 0 ? (
                              <>
                                <div title={value.map((opt) => opt.name).join(", ")}>
                                  <Chip label={value[0].name} {...getTagProps({ index: 0 })} />
                                  {value.length > 1 && (
                                    <span>...</span>
                                  )}
                                </div>
                              </>
                            ) : null;
                          }}

                          value={selectedOptions}
                          sx={{ width: "100%" }}
                          open={false}
                          disableCloseOnSelect
                          onChange={handleOptionChange}
                        />
                      </>

                    )
                  }

                </div> */}
                <div className="col-5 d-flex ml-3">
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={Object.keys(data).length === 0}
                    onClick={() => setFiltersOpen(true)}
                    sx={{ height: "2.8em" }}
                  >
                    <i className="fa-solid fa-filter mx-1"></i> Filters
                  </Button>
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{ marginLeft: "1em", height: "2.5em" }}
                    disabled={Object.keys(data).length === 0}
                    onClick={() => setFlagsOpen(true)}
                  >
                    <i className="fa-solid fa-flag mx-1"></i> Flags
                  </Button>
                </div>
              </div>
            ))}
        </div>

        {/* {data && Object.keys(data).length != 0 && (
          <div
            className={`${filterOpen ? "calender-card" : "calender-card-close"
              } mt-2`}
          >
            {Object.keys(data).length ? (
              <Calendar
                today={selectedDate}
                ota={otaMain}
                selfInvSource={selfInvSourceMain}
                invIntegrated={invIntegratedMain}
                selfTariffSource={selfTariffSourceMain}
                tariffIntegrated={tariffIntegratedMain}
                pms={pmsId}
                tenantId={tenantId}
                data={data}
                equRoomData={equRoomData}
                hotelDetails={hotelDetails}
                trainData={trainData}
                updateTime={updateTime}
                selectedMonth={selectedMonth + 1}
                selectedYear={selectedYear}
                selectedProperty={selectedPropertyMain}
                selectedPropertyName={selectedPropertyNameMain}
                filters={filterVal}
                calLength={calLength}
                selectedFlagMonth={selectedFlagMonth}
                selectedFlagYear={selectedFlagYear}
                selectedFlagIndex={selectedFlagIndex}
                selectedFlagNameMain={selectedFlagNameMain}
                startDate={startDate}
                endDate={endDate}
                filterOpen={filterOpen}
                mealPlanNames={mealPlanNames}
                clientDataSources={clientDataSources && clientDataSources}
                userRole={role}
                trackOtaList={trackOtaListMain}
                isLoading={isLoading}
              />
            ) : null}
          </div>
        )} */}

        <div
          className={`${filterOpen ? "calender-card" : "calender-card-close"
            } mt-2`}
        >

          <Calendar
            today={selectedDate}
            ota={otaMain}
            selfInvSource={selfInvSourceMain}
            invIntegrated={invIntegratedMain}
            selfTariffSource={selfTariffSourceMain}
            tariffIntegrated={tariffIntegratedMain}
            pms={pmsId}
            tenantId={tenantId}
            data={data}
            equRoomData={equRoomData}
            hotelDetails={hotelDetails}
            trainData={trainData}
            updateTime={updateTime}
            selectedMonth={selectedMonth + 1}
            selectedYear={selectedYear}
            selectedProperty={selectedPropertyMain}
            selectedPropertyName={selectedPropertyNameMain}
            filters={filterVal}
            calLength={calLength}
            selectedFlagMonth={selectedFlagMonth}
            selectedFlagYear={selectedFlagYear}
            selectedFlagIndex={selectedFlagIndex}
            selectedFlagNameMain={selectedFlagNameMain}
            startDate={startDate}
            endDate={endDate}
            filterOpen={filterOpen}
            mealPlanNames={mealPlanNames}
            clientDataSources={clientDataSources && clientDataSources}
            userRole={role}
            trackOtaList={trackOtaListMain}
            isLoading={isLoading}
            currencySymbol={currencySymbolMain}
          />

        </div>

      </section>

      <Dialog
        open={flagsOpen}
        onClose={() => setFlagsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="d-flex justify-content-between align-items-center">
            <h4>
              <span className="bold">Flags Summary</span> -{" "}
              {typeof properties.find(
                (item) => item.propertyId == selectedProperty
              ) !== "undefined"
                ? properties.find(
                  (item) => item.propertyId == selectedProperty
                )["propertyName"]
                : ""}
            </h4>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setFlagsOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
        </DialogTitle>
        <DialogContent sx={{ marginBottom: "1em" }}>
          <FlagsSummary
            rows={rows}
            flagCols={flagCols}
            handleFlagClick={handleFlagClick}
            selectedFlagIndex={selectedFlagIndex}
            selectedFlagNameMain={selectedFlagNameMain}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="d-flex justify-content-between align-items-center">
            <h4>
              <span className="bold">Flags Filter</span>
            </h4>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
        </DialogTitle>
        <DialogContent>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {filterCols.map((col) => (
                  <StyledTableCell align="left">{col}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterRows.map((row) => (
                <StyledTableRow
                  key={row.val}
                  onClick={() => {
                    handleFilterChange(row.val);
                  }}
                >
                  <StyledTableCell style={{ 'padding': 0 }}>
                    {/* <Checkbox checked={filters[row.val]} onChange={() => { handleFilterChange(row.val) }} /> */}
                    <Checkbox checked={filters[row.val]} />
                  </StyledTableCell>
                  <StyledTableCell sx={{ padding: 0 }}>{row.name}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions
          sx={{
            marginBottom: "1em",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex ml-3">
            <Legend>
              <LegendBox color="black" />
              <span>Exact Match</span>
            </Legend>
            <Legend>
              <LegendBox color="#1565c0" />
              <span>Partial Match</span>
            </Legend>
          </div>
          <div>
            <Button
              onClick={() => {
                setFilters({
                  comp_price_inc: false,
                  comp_price_dec: false,
                  overpriced: false,
                  underpriced: false,
                });
                setFilterVal([]);
                setFilterOptions([])
                setSelectedOptions([])


              }}
              color="primary"
            >
              Clear
            </Button>
            <Button
              variant="contained"
              onClick={handleFilterApply}
              color="primary"
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <LegendDrawer setOpenDrawer={setOpenDrawer} />
      </Drawer>
      {/* <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ ml: 2 }}>

        </Typography>
      </Backdrop> */}
    </>
  );
};

export default Master;
