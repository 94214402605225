import { create } from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";
import { fetchToken } from "../Auth";

const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      name: "",
      email: "",
      role: "",
      dashboardId: null,

      featurePrivileges: {},
      propertyPrivileges: {},
      dashboards: [],
      userScope: [],  
      propertyAccess: [], 
      dashboardRoutes: {}, 
      loading: false,

      setUser: (userData) => set({ user: userData }),

      setName: (name) => set({ name }),
      setEmail: (email) => set({ email }),
      setRole: (role) => set({ role }),
      setDashboardId: (dashboardId) => set({ dashboardId }), 

      setUser: async (userData) => {
        set({ user: userData });
        // console.log("Api call is made to get the featch dashboard id")
        await get().fetchDashboardRoutes(); // Fetch dashboard routes when user is set
      },

      clearUserData: () => set({ 
        name: "", email: "", role: "",
        user: null, 
        featurePrivileges: {}, 
        propertyPrivileges: {}, 
        dashboards: [], 
        userScope: [], 
        propertyAccess: [],
        dashboardRoutes: {} 
      }),

      userSignOut: () => {  
        set({ 
          name: "", email: "", role: "",
          user: null, 
          dashboardId: null, 
          featurePrivileges: {}, 
          propertyPrivileges: {}, 
          dashboards: [], 
          userScope: [], 
          propertyAccess: [],
          dashboardRoutes: {} 
        });
        localStorage.removeItem("user-storage");  
      },

      fetchPrivileges: async () => {
        set({ loading: true });
        try {
          const response = await axios.get(`/get_user_privileges`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
            },
          });

          const data = response.data;
          if (data && Object.keys(data).length > 0) {  
            set({
              featurePrivileges: data.featurePrivileges || {},  
              propertyPrivileges: data.propertyPrivileges || {},
              dashboards: data.dashboards || [],
              userScope: data.user_scope || [],
              propertyAccess: data.property_access || [],
            });
          } else {
            console.log("Data is empty, skipping state update.");
          }
        } catch (error) {
          console.error("Error fetching privileges:", error);
        } finally {
          set({ loading: false });
        }
      },

      fetchDashboardRoutes: async () => {
        try {
          const response = await axios.get(`/get_dashboard_routes`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
            },
          });

          const data = response.data;
          if (data && data.dashboard_routes) {  
            set({ dashboardRoutes: data.dashboard_routes });
          }
        } catch (error) {
          console.error("Error fetching dashboard routes:", error);
        }
      },
      fetchDashboardId: async (currentUrl) => {
        let dashboardRoutes = get().dashboardRoutes;
    
        // If dashboardRoutes is empty, fetch it first
        if (!dashboardRoutes || Object.keys(dashboardRoutes).length === 0) {
            // console.log("Dashboard routes not found, fetching...");
            await get().fetchDashboardRoutes();
            dashboardRoutes = get().dashboardRoutes;  // Update with new data
        }
    
        // console.log(currentUrl, 'this is current url');
        // console.log(dashboardRoutes, 'this is dashboard routes');
    
        const dashboardId = dashboardRoutes[currentUrl] || null;
        // console.log(dashboardId, 'this is my updated id');
    
        set({ dashboardId });
        return dashboardId;
    },
    
    

      // fetchDashboardId: async (currentUrl) => {
      //   console.log(currentUrl,'this is current url')
      //   const dashboardRoutes = get().dashboardRoutes;
      //   console.log(dashboardRoutes,'this is dashboard rute')
      //   const dashboardId = dashboardRoutes[currentUrl] || null; 
      //   console.log(dashboardId,'this is my updated id')
      //   set({ dashboardId });
      //   return dashboardId;
      // },
    }),
    {
      name: "user-storage",
      getStorage: () => localStorage,
    }
  )
);

export default useUserStore;
