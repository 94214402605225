import { Dialog, DialogTitle, DialogContent, IconButton, TextField, Box, Typography, Chip } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { styled } from "@mui/material/styles"
import { useState, useEffect } from "react"
import { Clear as ClearIcon } from "@mui/icons-material"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "910px",
    width: "100%",
  },
}))

const DialogHeader = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  padding: "16px 24px",
  borderBottom: "1px solid #e0e0e0",
  "& .MuiTypography-root": {
    color: "#FF8A00",
    fontWeight: 500,
  },
})

const SearchField = styled(TextField)({
  width: "250px",
  marginBottom: "20px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
  },
})

const StatusChip = styled(Chip)(({ status }) => ({
  borderRadius: "16px",
  backgroundColor: status === "Active" ? "#e8f5e9" : "#ffebee",
  color: status === "Active" ? "#2e7d32" : "#c62828",
  fontWeight: 500,
  fontSize: "12px",
}))

const AssignedUsersDialog = ({ open, onClose, userRoleDetails, selectedRoleId }) => {
  const [searchText, setSearchText] = useState("")
  const [selectedRole, setSelectedRole] = useState({})
  const [users, setUsers] = useState([])
  const [filteredRows, setFilteredRows] = useState([])
  const [columns, setColumns] = useState([])

  // Update selected role when props change
  useEffect(() => {
    const role = userRoleDetails?.roles?.find((role) => role.user_role_id === selectedRoleId) || {}
    setSelectedRole(role)
  }, [userRoleDetails, selectedRoleId])

  // Process users data when selected role changes
  useEffect(() => {
    if (!selectedRole?.users?.length) {
      setUsers([])
      return
    }

    const processedUsers = selectedRole.users
      .filter((user) => user.username) // Filter out null entries
      .map((user, index) => ({
        id: user.username || `user-${index}`, // Ensure unique id
        name: user.full_name || user.username || "N/A",
        status: user.user_status === 1 ? "Active" : "Inactive",
        email: user.username || "N/A",
        lastUpdateAt: user.last_updated_at || "N/A",
        lastUpdateBy: user.last_updated_by || "N/A",
      }))

    setUsers(processedUsers)
  }, [selectedRole])

  // Filter users based on search text
  useEffect(() => {
    if (!searchText) {
      setFilteredRows(users)
      return
    }

    const searchLower = searchText.toLowerCase()
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchLower) ||
        user.email.toLowerCase().includes(searchLower) ||
        user.status.toLowerCase().includes(searchLower) ||
        user.lastUpdateAt.toLowerCase().includes(searchLower) ||
        user.lastUpdateBy.toLowerCase().includes(searchLower),
    )

    setFilteredRows(filtered)
  }, [users, searchText])

  // Update columns definition
  useEffect(() => {
    setColumns([
      {
        field: "name",
        headerName: "User Name",
        width: 150,
        sortable: true,
      },
      {
        field: "status",
        headerName: "User Status",
        width: 130,
        sortable: true,
        renderCell: (params) => <StatusChip label={params.value} status={params.value} size="small" />,
      },
      {
        field: "email",
        headerName: "Email Address",
        width: 200,
        sortable: true,
      },
      {
        field: "lastUpdateAt",
        headerName: "Last Update At",
        width: 180,
        sortable: true,
      },
      {
        field: "lastUpdateBy",
        headerName: "Last Update By",
        width: 200,
        sortable: true,
      },
    ])
  }, []) // Empty dependency array since columns don't depend on any props or state

  // Custom empty state component
  const NoRowsOverlay = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "20px",
        color: "text.secondary",
      }}
    >
      No users assigned to this role
    </Box>
  )

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogHeader>
        <Typography variant="h6" component="div">
          {selectedRole?.user_role_name || "Role"} | Assigned Users
        </Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ color: "#666666" }}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, mt: 1 }}>
          <SearchField
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {searchText && (
                    <IconButton size="small" onClick={() => setSearchText("")} sx={{ padding: "2px" }}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                  <SearchIcon color="action" />
                </>
              ),
            }}
          />
        </Box>

        <Box sx={{ height: 400, width: "100%", overflow: "hidden" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            sx={{
              border: "1px solid #e0e0e0",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black",
                color: "white",
                fontSize: 16,
              },
              "& .MuiDataGrid-columnHeader": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "20px",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
              "& .MuiDataGrid-menuIcon": {
                display: "none",
              },
              "& .MuiDataGrid-overlay": {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              },
              "& .MuiDataGrid-footer": {
                backgroundColor: "#f5f5f5",
              },
              "& .MuiTablePagination-root": {
                color: "black",
              },
              "& .MuiTablePagination-displayedRows": {
                marginBottom: 0,
              },
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-select, & .MuiTablePagination-selectIcon": {
                display: "none",
              },
            }}
            disableRowSelectionOnClick
            disableColumnMenu
            sortingMode="client"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5 ,10]}
            slots={{
              noRowsOverlay: NoRowsOverlay,
            }}
            pagination
          />
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default AssignedUsersDialog

