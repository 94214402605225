import React, { useEffect, useState } from 'react'
import Home from "./Home"
import axios from "axios";
import { fetchToken,isValidRoute } from "../Auth";
import { useNavigate,useLocation } from "react-router-dom";
import BaseLayout from '../components/common/Layout/BaseLayout'
import Dashboard from "./Dashboard"
import DashboardSessionTracker from "../components/common/SessionTracker"
import useUserStore from '../context/UserContext';




const HomeLayout = () => {
  // const dashboardId = useUserStore((state) => state.dashboardId);
  const [dashboardId,setDashboardId] = useState("");
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    useUserStore.getState().setDashboardId(null);
  }, [location.pathname]);
  
  
  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };
  
    fetchId();
  }, []);
  
  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };


  

  const [tenantId, setTenantId] = useState(null)
  const getProperties = async () => {
    try {
      const response = await axios.get(`/fetch_client_properties/${dashboardId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setTenantId(parseInt(response.data.client_id));
      }
    }
    catch (err) {
      console.error("Error", err);
      if (err.response && err.response.status === 401) {
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    if(dashboardId){
      checkRoute()
      getProperties()
    }
  }, [dashboardId])


  return (
    <>
    
    {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}
      <BaseLayout>
        {tenantId == 1 && (
          <Dashboard />
        ) }
        {(tenantId != 1 && tenantId != null) && (
        
          <Home />
        )}
      </BaseLayout>
    </>

  )
}

export default HomeLayout