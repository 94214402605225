import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { fetchToken } from "../../Auth";
import { validateEmail, validateName } from "../../services/validators";
import MenuItem from "@mui/material/MenuItem";
import { Chip, Paper, Divider } from "@mui/material";

import "../../assets/styles/base.css";
import { FeatureAccessTable } from "../../components/common/FeatureTable.js";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const customStyles = {
  menu: (provided) => ({
    ...provided,
    maxHeight: "120px", // Set the maximum height for the dropdown menu
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "120px", // Set the maximum height for the dropdown menu
  }),
  control: (provided, state) => ({
    ...provided,
    "&:hover": {
      border: "1.25px solid #556",
    },
  }),
};

const NewUser = ({
  roles,
  clients,
  users,
  setUsers,
  designation,
  userRoleDetails,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileDesignation, setProfileDesignation] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [userType, setUserType] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [scope, setScope] = useState({});
  const [assignedDashboards, setAssignedDashboards] = useState([]);
  const [dashboardProgress, setDashboardProgress] = useState(0);
  const [featuresProgress, setFeaturesProgress] = useState(0);

  //Data checks
  const [emailValid, setEmailValid] = useState(true);
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);

  //Progress
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);
  const [progress5, setProgress5] = useState(0);
  const [progress6, setProgress6] = useState(0);

  const [selectedTenants, setSelectedTenants] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [tenantProgress, setTenantProgress] = useState(0);
  const [propertiesData, setPropertiesData] = useState([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [tenantPropertiesCache, setTenantPropertiesCache] = useState({}); // Added tenantPropertiesCache state
  const [selectedPropertyForRole, setSelectedPropertyForRole] = useState(null);
  const [propertyRolesList, setPropertyRolesList] = useState([]);
  const [propertyBasedRole, setPropertyBasedRole] = useState(null);
  // Add sample dashboard options - replace with actual dashboard data
  const [dashboardOptions, setDashboardOptions] = useState([]);
  // Add state for functionalities
  const [featureAccess, setFeatureAccess] = useState([]);
  const navigate = useNavigate();

  const rolesOptions = [];
  for (const item of roles) {
    rolesOptions.push({ label: item["name"], value: item["id"] });
  }

  const designationOptions = [];
  for (const item of designation) {
    designationOptions.push({ label: item["name"], value: item["id"] });
  }

  const createUser = async () => {
    if (!firstName || !firstNameValid) {
      setFirstNameValid(false);
      toast.error("Enter a valid first name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!lastName || !lastNameValid) {
      setLastNameValid(false);
      toast.error("Enter a valid last name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!email || !emailValid) {
      setEmailValid(false);
      toast.error("Enter a valid email address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!profileDesignation) {
      toast.error("Please select a designation!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (userType === "internal" && !selectedClients.length) {
      toast.error("Assign at least one  tenant for an internal user!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (userType === "external" && !Object.keys(selectedClient).length) {
      toast.error("Select a tenant for an external user!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!userRole) {
      toast.error("Assign a user role for this user!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!profileDesignation) {
      toast.error("Please select a designation!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!Object.keys(scope).length) {
      toast.error("Assign properties for the user", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    if (!assignedDashboards || assignedDashboards.length === 0) {
      toast.error("Assign at least one dashboard!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
      return;
    }
    const formattedData = {
      firstName,
      lastName,
      email,
      userRole: Number(userRole),
      userType,
      selectedClient: Number(
        userType === "internal" ? 1 : selectedClient?.value
      ),
      selectedClients:
        userType === "internal"
          ? selectedClients.map((client) => ({
              value: Number(client.value),
            }))
          : selectedClient
          ? [
              {
                value: Number(selectedClient.value),
              },
            ]
          : [],
      scope:
        userType === "internal"
          ? Object.entries(scope).reduce((acc, [clientId, cities]) => {
              acc[clientId] = cities;
              return acc;
            }, {})
          : selectedClient
          ? {
              [selectedClient.value]: Object.entries(scope).reduce(
                (acc, [_, cities]) => ({ ...acc, ...cities }),
                {}
              ),
            }
          : {},
      userDesignation: Number(profileDesignation),
      dashboards: assignedDashboards.map((d) => Number(d.value)),
      property_access: propertyRolesList.map((item) => ({
        property_id: Number(item.propertyId),
        role_id: Number(item.roleId),
      })),
      features: featureAccess.map(({ functionality_id, privilege }) => ({
        feature_id: Number(functionality_id),
        privilege: Number(privilege) || 5,
      })),
    };

    // Debug log with formatted JSON
    // console.log(
    //   "Create User Payload:",
    //   JSON.stringify(
    //     {
    //       ...formattedData,
    //     },
    //     null,
    //     2,
    //   ),
    // )

    try {
      const response = await axios.post(`/create_new_user`, formattedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });

      if (response.status === 201 && response.data !== undefined) {
        setUsers([
          ...users,
          { label: response.data[0], value: response.data[1] },
        ]);
        toast.success("User created successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.SUCCESS,
        });
        setFirstName("");
        setLastName("");
        setEmail("");
        setProfileDesignation("");
        setUserRole(null);
        setUserType("");
        setSelectedClient(null);
        setSelectedClients([]);
        setScope({});
        setAssignedDashboards([]);
        setSelectedTenants([]);
        setSelectedProperties([]);
        setPropertyRolesList([]);
        setFeatureAccess([]);
        setPropertyBasedRole(null);
        setSelectedPropertyForRole(null);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response?.status === 401) {
        navigate("/login");
      }
      if (err.response?.status === 400) {
        const errorMessage =
          err.response?.data?.msg || `Email address ${email} already exists`;
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR,
        });
      }
    }
  };

  const getPropertiesForTenants = async (tenantIds) => {
    setIsLoadingProperties(true);
    try {
      const newPropertiesToFetch = [];
      const cachedProperties = [];

      // Check which tenants need to be fetched
      for (const id of tenantIds) {
        if (tenantPropertiesCache[id]) {
          cachedProperties.push(...tenantPropertiesCache[id]);
        } else {
          newPropertiesToFetch.push(id);
        }
      }

      // Only fetch properties for tenants that aren't cached
      if (newPropertiesToFetch.length > 0) {
        const promises = newPropertiesToFetch.map((id) =>
          axios.get(`/get_client_properties/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
            },
          })
        );

        const responses = await Promise.all(promises);
        const newProperties = responses.flatMap((response, index) => {
          if (response.status === 200 && response.data) {
            const tenantId = newPropertiesToFetch[index];
            const properties = Object.entries(response.data).flatMap(
              ([cityName, cityData]) =>
                cityData.properties.map((prop) => ({
                  property_id: prop.property_id,
                  property_name: prop.property_name,
                  city: cityName,
                  tenant_id: prop.tenant_id || tenantId, // Use provided tenant_id or fallback to current tenant
                  tenant_name: prop.tenant_name,
                  is_active: prop.is_active,
                  room_types: prop.room_types || {},
                }))
            );

            // Cache the properties for this tenant in state
            setTenantPropertiesCache((prev) => ({
              ...prev,
              [tenantId]: properties,
            }));

            return properties;
          }
          return [];
        });

        setPropertiesData([...cachedProperties, ...newProperties]);
      } else {
        setPropertiesData(cachedProperties);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/login");
      }
      console.error("Error fetching properties:", err);
      toast.error("Failed to fetch properties!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    } finally {
      setIsLoadingProperties(false);
    }
  };

  const handleTenantsChange = (selectedOptions) => {
    // Ensure selectedOptions is always an array, even if empty
    const options = selectedOptions || [];

    // For external users, ensure only one tenant is selected
    if (userType === "external") {
      const selectedOption = Array.isArray(options) ? options[0] : options;
      const singleOption = selectedOption ? [selectedOption] : [];
      setSelectedTenants(singleOption);
      setSelectedProperties([]);
      setPropertyRolesList([]);
      setScope({});
      if (selectedOption) {
        setSelectedClient({
          value: selectedOption.value,
          label: selectedOption.label,
        });
        // Fetch properties for the selected tenant
        getPropertiesForTenants([selectedOption.value]);
      } else {
        setSelectedClient(null);
        setSelectedProperties([]);
        setPropertiesData([]);
      }
      return;
    }

    // Handle internal user case (multiple tenants)
    setSelectedTenants(options);
    setSelectedClients(
      options.map((opt) => ({
        value: opt.value,
      }))
    );

    // Clear properties and related data if no tenants selected
    if (!options.length) {
      setSelectedProperties([]);
      setPropertiesData([]);
      setSelectedClient(null);
      setPropertyRolesList([]);
      setSelectedClients([]);
      setScope({});
      return;
    }

    const tenantIds = options.map((opt) => opt.value);

    setSelectedProperties((prev) =>
      (prev || []).filter((prop) => tenantIds.includes(prop.tenant_id))
    );
    setPropertyRolesList((prevList) => {
      const validProperties = propertiesData.filter((prop) =>
        tenantIds.includes(prop.tenant_id)
      );
      const validPropertyIds = validProperties.map((prop) => prop.property_id);
      return prevList.filter((item) =>
        validPropertyIds.includes(item.propertyId)
      );
    });

    // Fetch properties for selected tenants
    getPropertiesForTenants(tenantIds);
  };

  const getPropertyOptions = () => {
    return (propertiesData || []).map((prop) => ({
      value: prop.property_id,
      label: `${prop.property_name}`,
      displayLabel: `${prop.property_name} - ${prop.city} - ${prop.tenant_name}`,
      displayLabelForProperty: `${prop.property_name}`,
      tenant_id: prop.tenant_id,
      tenant_name: prop.tenant_name,
      city: prop.city, // Add city to the option object
    }));
  };

  const propertySelectStyles = {
    ...customStyles,
    loadingMessage: (provided) => ({
      ...provided,
      color: "#666",
    }),
  };

  useEffect(() => {
    if (email && !validateEmail(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  }, [email]);

  useEffect(() => {
    if (firstName && !validateName(firstName)) {
      setFirstNameValid(false);
    } else {
      setFirstNameValid(true);
    }
  }, [firstName]);

  useEffect(() => {
    if (lastName && !validateName(lastName)) {
      setLastNameValid(false);
    } else {
      setLastNameValid(true);
    }
  }, [lastName]);

  useEffect(() => {
    if (userType) {
      // Clear all tenant and property related fields
      setSelectedClient(null);
      setSelectedClients([]);
      setScope({});
      setSelectedTenants([]);
      setSelectedProperties([]);
      setPropertyRolesList([]);
      setSelectedPropertyForRole(null);
      setPropertyBasedRole(null);
      setPropertiesData([]);

      // Set progress
      setProgress2(100);
    } else {
      setProgress2(0);
    }
  }, [userType]);

  useEffect(() => {
    let value = 0;
    if (firstName && validateName(firstName)) {
      value += 25;
    }
    if (lastName && validateName(lastName)) {
      value += 25;
    }
    if (email && validateEmail(email)) {
      value += 25;
    }
    if (profileDesignation) {
      value += 25;
    }
    setProgress1(value);
  }, [firstName, lastName, email, profileDesignation]);

  useEffect(() => {
    if (
      userType === "external" &&
      selectedClient &&
      Object.keys(selectedClient).length &&
      selectedClient["value"]
    ) {
      setProgress3(100);
    } else if (
      userType === "internal" &&
      selectedClients &&
      selectedClients.length
    ) {
      setProgress3(100);
    } else {
      setProgress3(0);
    }
  }, [selectedClients, selectedClient, userType]); // Added userType to dependencies

  useEffect(() => {
    if (userRole) {
      setProgress4(100);
    } else {
      setProgress4(0);
    }
  }, [userRole]);

  useEffect(() => {
    if (assignedDashboards && assignedDashboards.length > 0) {
      setDashboardProgress(100);
    } else {
      setDashboardProgress(0);
    }
  }, [assignedDashboards]);

  useEffect(() => {
    if (featureAccess && featureAccess.length > 0) {
      setFeaturesProgress(100);
    } else {
      setFeaturesProgress(0);
    }
  }, [featureAccess]);

  const scopeAssigned = useCallback(() => {
    if (userType === "external") {
      for (const key in scope) {
        if (scope[key].length) {
          return true;
        }
      }
      return false;
    } else if (userType === "internal") {
      for (const client in scope) {
        for (const key in scope[client]) {
          if (scope[client][key].length) {
            return true;
          }
        }
      }
      return false;
    }
  }, [scope, userType]);

  useEffect(() => {
    if (userType) {
      if (scopeAssigned()) {
        setProgress5(100);
      } else {
        setProgress5(0);
      }
    }
  }, [userType, scopeAssigned]);

  useEffect(() => {
    let progress = 0;
    if (selectedTenants.length > 0) progress += 50;
    if (selectedProperties.length > 0) progress += 50;
    setTenantProgress(progress);
  }, [selectedTenants, selectedProperties]);

  // Add progress tracking

  const handleToggle = (city, propertyId, tenantId) => {
    if (userType === "internal") {
      setScope((prevState) => {
        const updatedState = { ...prevState };

        if (!updatedState[tenantId]) {
          updatedState[tenantId] = {};
        }

        if (!updatedState[tenantId][city]) {
          updatedState[tenantId][city] = [];
        }

        const propertyIndex = updatedState[tenantId][city].indexOf(propertyId);

        if (propertyIndex !== -1) {
          // Remove property
          updatedState[tenantId][city].splice(propertyIndex, 1);

          // Clean up empty arrays and objects
          if (updatedState[tenantId][city].length === 0) {
            delete updatedState[tenantId][city];
          }
          if (Object.keys(updatedState[tenantId]).length === 0) {
            delete updatedState[tenantId];
          }
        } else {
          // Add property
          updatedState[tenantId][city].push(propertyId);
        }

        return updatedState;
      });
    } else if (userType === "external") {
      const tenantId = selectedClient.value;
      setScope((prevState) => {
        const updatedState = { ...prevState };

        if (!updatedState[tenantId]) {
          updatedState[tenantId] = {};
        }

        if (!updatedState[tenantId][city]) {
          updatedState[tenantId][city] = [];
        }

        const propertyIndex = updatedState[tenantId][city].indexOf(propertyId);

        if (propertyIndex !== -1) {
          // Remove property
          updatedState[tenantId][city].splice(propertyIndex, 1);

          // Clean up empty arrays and objects
          if (updatedState[tenantId][city].length === 0) {
            delete updatedState[tenantId][city];
          }
          if (Object.keys(updatedState[tenantId]).length === 0) {
            delete updatedState[tenantId];
          }
        } else {
          // Add property
          updatedState[tenantId][city].push(propertyId);
        }

        return updatedState;
      });
    }
  };

  useEffect(() => {
    if (userRole && userRoleDetails?.roles) {
      const selectedRoleData = userRoleDetails.roles.find(
        (role) => role.user_role_id === userRole
      );

      if (selectedRoleData) {
        // Update dashboards
        const availableDashboards = selectedRoleData.dashboards.map(
          (dashboard) => ({
            value: dashboard.dashboard_id,
            label: dashboard.dashboard_name,
          })
        );
        setDashboardOptions(availableDashboards);
        setAssignedDashboards([]);

        // Update functionalities
        setFeatureAccess(selectedRoleData.functionalities || []);
      }
    } else {
      setDashboardOptions([]);
      setFeatureAccess([]);
    }
  }, [userRole, userRoleDetails]);
  const handlePropertyChange = (opt) => {
    const selectedOpts = opt || [];
    setSelectedProperties(selectedOpts);
    if (
      selectedPropertyForRole &&
      !selectedOpts.some((prop) => prop.value === selectedPropertyForRole.value)
    ) {
      setSelectedPropertyForRole(null);
    }

    // Get the IDs of the currently selected properties
    const selectedPropertyIds = selectedOpts.map((prop) => prop.value);

    // Remove property roles for any properties that are no longer selected
    setPropertyRolesList((prevList) =>
      prevList.filter((item) => selectedPropertyIds.includes(item.propertyId))
    );

    // Update scope based on selected properties
    const newScope = {};
    selectedOpts.forEach((property) => {
      const tenantId = property.tenant_id;
      const city = property.city || "default"; // Ensure we have a city value

      if (!newScope[tenantId]) {
        newScope[tenantId] = {};
      }
      if (!newScope[tenantId][city]) {
        newScope[tenantId][city] = [];
      }
      newScope[tenantId][city].push(property.value); // property.value is the property_id
    });

    setScope(newScope);
  };

  // Function to handle adding a property role
  const handleAddPropertyRole = () => {
    const selectedProperty = selectedPropertyForRole;
    const selectedRole = rolesOptions.find(
      (opt) => opt.value === propertyBasedRole.value
    );
    if (selectedProperty && selectedRole) {
      // Check if this property already has this role assigned
      const isDuplicate = propertyRolesList.some(
        (item) => item.propertyId === selectedProperty.value
      );

      if (isDuplicate) {
        toast.error("This property already has a role assigned!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
        });
        return;
      }

      setPropertyRolesList([
        ...propertyRolesList,
        {
          propertyId: selectedProperty.value,
          propertyName: selectedProperty.displayLabel,
          roleId: selectedRole.value,
          roleName: selectedRole.label,
        },
      ]);
      setSelectedPropertyForRole(null);
      setPropertyBasedRole(null);
    }
  };
  return (
    <section className="p-3">
      <div>
        <Grow in={true}>
          <Accordion
            defaultExpanded={true}
            className="mb-3 py-2 accordion-item"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="w-100 row">
                <div className="col-10">
                  <h6 className="bold text-orange d-flex align-items-center">
                    <i className="fa-solid fa-shield-halved mx-2 grey"></i>{" "}
                    Profile Details
                    <Tooltip
                      title="Profile Details section contains personal information fields required for your user profile"
                      placement="right"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </h6>
                </div>
                <div className="col-2">
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={progress1} />
                  </Box>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className="col-6">
                  <TextField
                    error={!firstNameValid ? true : false}
                    style={{ background: "#fff" }}
                    size="small"
                    onChange={(evt) => setFirstName(evt.target.value)}
                    className="w-100"
                    value={firstName}
                    id="first-name"
                    label="First Name"
                    variant="outlined"
                    required
                  />
                  {!firstNameValid ? (
                    <small className="mx-2 text-danger">
                      Invalid first name
                    </small>
                  ) : null}
                </div>
                <div className="col-6">
                  <TextField
                    error={!lastNameValid ? true : false}
                    style={{ background: "#fff" }}
                    size="small"
                    value={lastName}
                    onChange={(evt) => setLastName(evt.target.value)}
                    className="w-100"
                    id="last-name"
                    label="Last Name"
                    variant="outlined"
                    required
                  />
                  {!lastNameValid ? (
                    <small className="mx-2 text-danger">
                      Invalid last name
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 row">
                <div className="col-6">
                  <div className="d-flex justify-content-between align-items-center">
                    <TextField
                      error={!emailValid ? true : false}
                      style={{ background: "#fff" }}
                      size="small"
                      value={email}
                      onChange={(evt) => setEmail(evt.target.value)}
                      className="w-100"
                      id="email"
                      label="Email ID"
                      variant="outlined"
                      required
                    />
                  </div>
                  {!emailValid ? (
                    <small className="mx-2 text-danger">
                      Please enter a valid organization email
                    </small>
                  ) : null}
                </div>
                <div className="col-6">
                  <TextField
                    select
                    required
                    fullWidth
                    size="small"
                    label="Designation"
                    value={profileDesignation}
                    onChange={(event) =>
                      setProfileDesignation(event.target.value)
                    }
                    variant="outlined"
                    style={{ background: "#fff" }}
                    className="w-100"
                  >
                    {designationOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="d-flex justify-content-end"></div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grow>

        <Grow in={true} {...(true ? { timeout: 500 } : {})}>
          <Accordion className="mb-3 py-2 accordion-item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <div className="w-100 row">
                <div className="col-10">
                  <h6 className="bold text-orange">
                    <i class="fa-solid fa-lock mx-2 grey"></i> User Type
                  </h6>
                </div>
                <div className="col-2">
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={progress2} />
                  </Box>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d-flex flex-row justify-content-between align-items-center w-40 row">
                <div className="checkbox-wrapper-16">
                  <label className="mx-3 checkbox-wrapper">
                    <input
                      value="internal"
                      onClick={(evt) =>
                        setUserType(userType === "internal" ? "" : "internal")
                      }
                      checked={userType === "internal" ? "checked" : ""}
                      type="checkbox"
                      className="checkbox-input"
                    />
                    <span className="checkbox-tile">
                      <span className="checkbox-icon"></span>
                      <span className="checkbox-label">Internal</span>
                    </span>
                  </label>
                  <label className="mx-3 checkbox-wrapper">
                    <input
                      value="external"
                      onClick={(evt) =>
                        setUserType(userType === "external" ? "" : "external")
                      }
                      checked={userType === "external" ? "checked" : ""}
                      type="checkbox"
                      className="checkbox-input"
                    />
                    <span className="checkbox-tile">
                      <span className="checkbox-icon"></span>
                      <span className="checkbox-label">External</span>
                    </span>
                  </label>
                  <Tooltip
                    title="Set user type as 'Internal' for Sciative users and 'External' for external client users. An internal user can be assigned multiple clients while an external user can be assigned a single client only.
		        		User type once set, cannot be changed later."
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grow>

        <Grow in={true} {...(true ? { timeout: 1500 } : {})}>
          <Accordion className="mb-3 py-2 accordion-item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <div className="w-100 row">
                <div className="col-10">
                  <h6 className="bold text-orange">
                    <i class="fa-solid fa-wrench mx-2 grey"></i> User Role
                  </h6>
                </div>
                <div className="col-2">
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={progress4} />
                  </Box>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className="col-6">
                  <Select
                    placeholder="Select Role"
                    styles={customStyles}
                    onChange={(opt) => setUserRole(Number.parseInt(opt.value))}
                    className="w-100"
                    options={rolesOptions}
                    value={
                      rolesOptions.find(
                        (option) => option.value === userRole
                      ) || null
                    }
                  />
                </div>
                <div className="col-2">
                  <Tooltip
                    title="Assign a user role to set what dashboards a user can have access to and set his/her privileges (reader/maker/checker). Note that user roles created are general and not client specific"
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grow>
        <Grow in={true} timeout={1600}>
          <Accordion className="mb-3 py-2 accordion-item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4c-content"
              id="panel4c-header"
            >
              <div className="w-100 row">
                <div className="col-10">
                  <h6 className="bold text-orange">
                    <i className="fa-solid fa-building mx-2 grey"></i> Tenant &
                    Property
                  </h6>
                </div>
                <div className="col-2">
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={tenantProgress} />
                  </Box>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                {!userType ? (
                  <div className="p-2">
                    <Alert severity="info">
                      Select a user type in the section above to assign tenants
                      and properties
                    </Alert>
                  </div>
                ) : (
                  <>
                    {/* First section: Tenant and Property assignment */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="mb-3">
                          <label className="form-label d-flex align-items-center">
                            {userType === "external"
                              ? "Assign Tenant"
                              : "Assign Tenants"}
                            <span className="text-danger">*</span>
                            <Tooltip
                              title={`Select the tenant organization${
                                userType === "internal" ? "s" : ""
                              } this user belongs to`}
                              placement="right"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <Select
                            placeholder="Select Tenants"
                            styles={customStyles}
                            isMulti={userType === "internal"}
                            value={selectedTenants}
                            onChange={handleTenantsChange}
                            className="w-100"
                            options={clients}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label className="form-label d-flex align-items-center">
                            Assign Property
                            <span className="text-danger">*</span>
                            <Tooltip
                              title="Select the properties this user will have access to"
                              placement="right"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <Select
                            placeholder="Select Properties"
                            styles={propertySelectStyles}
                            isMulti
                            value={selectedProperties || []}
                            onChange={handlePropertyChange}
                            className="w-100"
                            options={getPropertyOptions()}
                            isDisabled={!selectedTenants?.length}
                            formatOptionLabel={({ displayLabel }) =>
                              displayLabel || ""
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Divider between sections */}
                    <Divider
                      sx={{
                        my: 1 /* Increased vertical margin */,
                        borderColor: "rgba(0,0,0,0.15)",
                        borderBottomWidth: 1 /* Increased thickness */,
                        width: "100%",
                        opacity: 0.8,
                      }}
                    />

                    {/* Second section: Property Role assignment - stacked vertically */}
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label d-flex align-items-center">
                            Property Name
                            <Tooltip
                              title="Select a property"
                              placement="right"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <Select
                            placeholder="Select Property"
                            styles={propertySelectStyles}
                            value={selectedPropertyForRole}
                            onChange={(opt) => setSelectedPropertyForRole(opt)}
                            className="w-100"
                            options={getPropertyOptions().filter((option) =>
                              selectedProperties.some(
                                (selectedProp) =>
                                  selectedProp.value === option.value
                              )
                            )}
                            isDisabled={!selectedTenants?.length}
                            formatOptionLabel={({ displayLabelForProperty }) =>
                              displayLabelForProperty || ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label d-flex align-items-center">
                            Property Based Role
                            <Tooltip
                              title="Select the role for this property"
                              placement="right"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <Select
                            placeholder="Select Role"
                            styles={customStyles}
                            value={propertyBasedRole}
                            onChange={(opt) => setPropertyBasedRole(opt)}
                            className="w-100"
                            options={rolesOptions}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <Button
                          variant="contained"
                          disabled={
                            !selectedPropertyForRole || !propertyBasedRole
                          }
                          onClick={handleAddPropertyRole}
                          sx={{
                            backgroundColor:
                              !selectedPropertyForRole || !propertyBasedRole
                                ? "#E0E0E0"
                                : "#FF8A00",
                            color:
                              !selectedPropertyForRole || !propertyBasedRole
                                ? "#9E9E9E"
                                : "white",
                            "&:hover": {
                              backgroundColor:
                                !selectedPropertyForRole || !propertyBasedRole
                                  ? "#E0E0E0"
                                  : "#ff9d2f",
                            },
                            transition: "all 0.3s ease",
                            fontWeight: "500",
                            textTransform: "none",
                            boxShadow:
                              !selectedPropertyForRole || !propertyBasedRole
                                ? "none"
                                : "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        >
                          Add Property Role
                        </Button>
                      </div>
                    </div>

                    {propertyRolesList.length > 0 && (
                      <Paper variant="outlined" sx={{ p: 3 }}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                          Assigned Properties and Roles
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "12px",
                          }}
                        >
                          {propertyRolesList.map((item, index) => (
                            <Chip
                              key={index}
                              label={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography component="span">
                                    {item.propertyName}
                                  </Typography>
                                  <Typography component="span" sx={{ mx: 0.5 }}>
                                    -
                                  </Typography>
                                  <Typography
                                    component="span"
                                    color="text.secondary"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {item.roleName}
                                  </Typography>
                                </Box>
                              }
                              onDelete={() => {
                                setPropertyRolesList(
                                  propertyRolesList.filter(
                                    (_, i) => i !== index
                                  )
                                );
                              }}
                              sx={{
                                padding: "4px",
                                "& .MuiChip-label": {
                                  display: "flex",
                                  alignItems: "center",
                                  px: 1,
                                },
                              }}
                            />
                          ))}
                        </Box>
                      </Paper>
                    )}
                  </>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1700 } : {})}>
          <Accordion className="mb-3 py-2 accordion-item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4b-content"
              id="panel4b-header"
            >
              <div className="w-100 row">
                <div className="col-10">
                  <h6 className="bold text-orange">
                    <i className="fa-solid fa-chart-line mx-2 grey"></i>{" "}
                    Dashboards
                  </h6>
                </div>
                <div className="col-2">
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={dashboardProgress} />
                  </Box>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {!userRole ? (
                <div className="p-2">
                  <Alert severity="info">
                    Select a user Role to see Assigned Dashboards
                  </Alert>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6">
                    <Select
                      placeholder="Assign Dashboards"
                      styles={customStyles}
                      isMulti
                      value={assignedDashboards}
                      onChange={(opt) => setAssignedDashboards(opt || [])}
                      className="w-100"
                      options={dashboardOptions}
                      isDisabled={!userRole}
                    />
                  </div>
                  <div className="col-2">
                    <Tooltip
                      title="Assign specific dashboards that this user will have access to based on their role and permissions"
                      placement="right"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </Grow>
        <Grow in={true} {...(true ? { timeout: 1800 } : {})}>
          <Accordion className="mb-3 py-2 accordion-item">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <div className="w-100 row">
                <div className="col-10">
                  <h6 className="bold text-orange d-flex align-items-center">
                    <i className="fa-solid fa-shield-halved mx-2 grey"></i>{" "}
                    Functionality Access Levels
                    <Tooltip
                      title="Set specific access permissions for each Feature."
                      placement="right"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </h6>
                </div>
                <div className="col-2">
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={featuresProgress} />
                  </Box>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                {!userRole ? (
                  <div className="p-2">
                    <Alert severity="info">
                      Select a user Role to see feature table
                    </Alert>
                  </div>
                ) : (
                  <div className="col-12">
                    <FeatureAccessTable
                      features={featureAccess}
                      setFeatureAccess={setFeatureAccess}
                      mode="create"
                    />

                    {/* <FeatureAccessTable
                    functionalities={featureAccess}
                    selectedRole={userRole}
                    setFeatureAccess={setFeatureAccess}
                  /> */}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </Grow>

        <div className="w-100 mt-4 row d-flex justify-content-center align-items-center">
          <Button
            size="large"
            style={{
              width: "20%",
              backgroundColor: "#FF8A00",
              color: "white",
              "&:hover": {
                backgroundColor: "#ff9d2f",
              },
            }}
            className="create-user-btn"
            onClick={createUser}
            variant="contained"
          >
            Create User
          </Button>
        </div>
      </div>
    </section>
  );
};

export default NewUser;
