import { useEffect,useState} from 'react';
import { Routes, Route, useNavigate,useLocation } from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import { isValidRoute } from '../../Auth';
import {AppProvider} from '../../context/AppContext';
import Client from './Client';
import ClientDetails from './ClientDetails';
import NewClient from './NewClient';
import ClientDashboards from './ClientDashboards';

import '../../assets/styles/admin.css'
import '../../assets/styles/base.css'
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const ClientManagement = ({ match }) => {
  const [dashboardId,setDashboardId] = useState("")
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };
  
    fetchId();
  }, []);
  
  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {
    checkRoute();
  }, [dashboardId]); 

  return(
    
    <AppProvider>
      {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}
    <BaseLayout background={'bg-grey'}>
      <Routes>
          <Route path="client-management" element={<Client />} />
          <Route path="setup-client" element={<NewClient />} />
          <Route path="details" element={<ClientDetails />} />
          {/* <Route path="client-dashboards" element={<ClientDashboards />} /> */}
      </Routes>
    </BaseLayout>
    </AppProvider>
  )
};

export default ClientManagement;