import { useState, useEffect, useCallback, memo } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  Typography,
  Button,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material"
import axios from "axios"
import { fetchToken } from "../../Auth"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

const customStyles = {
  menu: (provided) => ({
    ...provided,
    maxHeight: "120px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "120px",
  }),
  control: (provided) => ({
    ...provided,
    "&:hover": {
      border: "1.25px solid #556",
    },
    minHeight: "56px",
  }),
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "800px",
    width: "100%",
  },
}))

const DialogHeader = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  padding: "16px 24px",
  borderBottom: "1px solid #e0e0e0",
  "& .MuiTypography-root": {
    color: "#FF8A00",
    fontWeight: 500,
  },
})

const StyledTableCell = styled(TableCell)({
  padding: "12px 16px",
  fontSize: "14px",
  "&.MuiTableCell-head": {
    backgroundColor: "#1a1a1a",
    color: "white",
    fontWeight: 500,
  },
})

const StyledRadio = styled(Radio)({
  color: "#000000",
  "&.Mui-checked": {
    color: "#000000",
  },
})

const SaveButton = styled(Button)({
  backgroundColor: "#FF8A00",
  color: "white",
  "&:hover": {
    backgroundColor: "#ff9d2f",
  },
})

const CancelButton = styled(Button)({
  color: "#666",
  borderColor: "#ddd",
})
const AnimatedPaper = styled(Paper)(({ theme }) => ({
  transition: theme.transitions.create(["transform", "opacity"], {
    duration: theme.transitions.duration.standard,
  }),
  "&.entering, &.entered": {
    transform: "translateY(0)",
    opacity: 1,
  },
  "&.exiting, &.exited": {
    transform: "translateY(-20px)",
    opacity: 0,
  },
}))

const EditRoleDialog = memo(function EditRoleDialog({
  open,
  onClose,
  dashboards,
  functionalities,
  userRoleDetails,
  selectedRoleId,
  onSuccess,
}) {
  const [roleName, setRoleName] = useState("")
  const [selectedDashboards, setSelectedDashboards] = useState([])
  const [permissions, setPermissions] = useState({})
  const [isRoleNameExpanded, setIsRoleNameExpanded] = useState(false)
  const [isDashboardAccessExpanded, setIsDashboardAccessExpanded] = useState(false)
  const [isFeatureAccessExpanded, setIsFeatureAccessExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (open && userRoleDetails && selectedRoleId) {
      const selectedRole = userRoleDetails.roles.find((role) => role.user_role_id === selectedRoleId)

      if (selectedRole) {
        setRoleName(selectedRole.user_role_name)
        setSelectedDashboards(selectedRole.dashboards || [])
        const initialPermissions = {}
        functionalities.forEach((func) => {
          const existingFunc = selectedRole.functionalities.find((f) => f.functionality_id === func.functionality_id)
          initialPermissions[func.functionality_id] = existingFunc ? existingFunc.privilege : 5
        })
        setPermissions(initialPermissions)
      }
    }
  }, [open, userRoleDetails, selectedRoleId, functionalities])

  const toggleRoleNameSection = useCallback(() => {
    setIsRoleNameExpanded((prev) => !prev)
  }, [])

  const toggleDashboardAccessSection = useCallback(() => {
    setIsDashboardAccessExpanded((prev) => !prev)
  }, [])

  const toggleFeatureAccessSection = useCallback(() => {
    setIsFeatureAccessExpanded((prev) => !prev)
  }, [])

  const handleRoleNameChange = useCallback((e) => {
    setRoleName(e.target.value)
  }, [])
  const handleAccordionChange = (event, expanded) => {
    setIsExpanded(expanded)
  }
  const handleDashboardChange = useCallback(
    (selectedOptions) => {
      const options = selectedOptions || []
      const selectedDashboardObjects = options.map((option) => {
        const dashboard = dashboards.find((d) => d.dashboard_id === option.value)
        return dashboard || { dashboard_id: option.value, dashboard_name: option.label }
      })
      setSelectedDashboards(selectedDashboardObjects)
    },
    [dashboards],
  )

  const handlePermissionChange = useCallback((functionalityId, permission) => {
    setPermissions((prev) => ({
      ...prev,
      [functionalityId]: permission,
    }))
  }, [])

  const validateDesignationName = (name) => {
    const regex = /^[a-zA-Z0-9\s]+$/
    return regex.test(name)
  }

  const validateForm = useCallback(() => {
    if (!roleName.trim()) {
      toast.error("Please enter a role name")
      return false
    }
    if (!validateDesignationName(roleName)) {
      toast.warning("Role name should not contain special characters")
      return false
    }

    if (selectedDashboards.length === 0) {
      toast.error("Please select at least one dashboard")
      return false
    }

    return true
  }, [roleName, selectedDashboards])

  const handleSaveChanges = useCallback(async () => {
    if (!validateForm()) {
      return
    }

    setIsLoading(true)
    setError("")

    try {
      const token = fetchToken("zetta_access_token")
      if (!token) {
        toast.error("Authentication token not found")
        throw new Error("Authentication token not found")
      }

      const payload = {
        roleId: selectedRoleId,
        roleName: roleName.trim(),
        dashboards_assigned: selectedDashboards.map((d) => ({
          dashboard_id: d.dashboard_id,
        })),
        functionality_assigned: Object.entries(permissions).map(([id, privilege]) => ({
          functionality_id: Number(id),
          privilege: Number(privilege),
        })),
      }

      const response = await axios.post("/update_user_role", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.data.message === "User role updated successfully") {
        toast.success("Role updated successfully")
        onSuccess?.()
        onClose(true)
      } else {
        toast.error(response.data.message || "Failed to update role")
      }
    } catch (error) {
      console.error("Error updating role:", error)
      const errorMessage =
        error.response?.data?.detail ||
        error.response?.data?.message ||
        error.message ||
        "Failed to update role. Please try again."

      setError(errorMessage)
      toast.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }, [selectedRoleId, roleName, selectedDashboards, permissions, onClose, validateForm])

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth disableEscapeKeyDown={isLoading}>
      <DialogHeader>
        <Typography component="div" variant="h6">
          Edit Role: {roleName}
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "#666" }} disabled={isLoading}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent sx={{ p: 3 , mt:1 }}>
        <Accordion
          expanded={isExpanded}
          onChange={handleAccordionChange}
          sx={{
            borderRadius: "10px",
            mb: 3,
            p: 0,
            "&:before": {
              display: "none",
            },
            backgroundColor: "#1e1e2d",
            "&.Mui-expanded": {
              margin: 0,
              marginBottom: 3,
            },
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          }}
        >
          <AccordionSummary
            expandIcon={
              isRoleNameExpanded ? (
                <KeyboardArrowUp sx={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              )
            }
            aria-controls="role-name-content"
            id="role-name-header"
            sx={{
              padding: "0 16px",
              minHeight: "58px",
              backgroundColor: "#2a2a3c",
              borderRadius: "10px",
              overflow: "hidden",
              "&.Mui-expanded": {
                minHeight: "58px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>Role Name</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px 0",
              transition: "all 0.3s ease-in-out",
              backgroundColor: "#ededf2",
            }}
          >
            <Box sx={{ px: 3 }}>
              <AnimatedPaper
                initial={{ opacity: 0, y: -20 }}
                animate={{
                  opacity: isExpanded ? 1 : 0,
                  y: isExpanded ? 0 : -20,
                }}
                transition={{ duration: 0.3 }}
                sx={{
                  backgroundColor: "#fcfcfc",
                  p: 3,
                  borderRadius: "8px",
                }}
                elevation={2}
              >
                <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, color: "#666" }}>
              User Role Name
            </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter User Role Name"
                    value={roleName}
                    onChange={handleRoleNameChange}
                    disabled={isLoading}
                    inputProps={{
                      maxLength: 100,
                    }}
                    sx={{
                      mt: 1,
                      mb: 1,
                      "& .MuiOutlinedInput-root": {
                        color: "#080707",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "8px",
                        "& fieldset": {
                          borderColor: "rgba(3, 0, 0, 0.15)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(14, 13, 13, 0.3)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#6366f1",
                        },
                        padding: "4px 8px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "12px 14px",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "rgba(4, 4, 4, 0.5)",
                        opacity: 1,
                      },
                    }}
                  />
                </Box>
              </AnimatedPaper>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isDashboardAccessExpanded}
          onChange={() => setIsDashboardAccessExpanded(!isDashboardAccessExpanded)}
          sx={{
            borderRadius: "10px",
            mb: 3,
            p: 0,
            "&:before": {
              display: "none",
            },
            backgroundColor: "#1e1e2d",
            "&.Mui-expanded": {
              margin: 0,
              marginBottom: 3,
            },
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          }}
        >
          <AccordionSummary
            expandIcon={
              isDashboardAccessExpanded ? (
                <KeyboardArrowUp sx={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              )
            }
            aria-controls="dashboard-access-content"
            id="dashboard-access-header"
            sx={{
              padding: "0 16px",
              minHeight: "58px",
              backgroundColor: "#2a2a3c",
              borderRadius: "10px",
              overflow: "hidden",
              "&.Mui-expanded": {
                minHeight: "58px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>Manage Role and Dashboard Access</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px 0",
              transition: "all 0.3s ease-in-out",
              backgroundColor: "#ededf2",
            }}
          >
            <Box sx={{ px: 3 }}>
              <AnimatedPaper
                sx={{
                  backgroundColor: "#fcfcfc",
                  p: 3,
                  borderRadius: "8px",
                }}
                elevation={2}
              >
                <Box>
                <Typography variant="subtitle2" sx={{ mb: 1, color: "#666" }}>
              Assign Dashboards*
            </Typography>
                  <Select
                    placeholder="Select Dashboards"
                    styles={customStyles}
                    isMulti
                    value={selectedDashboards.map((dashboard) => ({
                      value: dashboard.dashboard_id,
                      label: dashboard.dashboard_name,
                    }))}
                    onChange={handleDashboardChange}
                    options={dashboards.map((dashboard) => ({
                      value: dashboard.dashboard_id,
                      label: dashboard.dashboard_name,
                    }))}
                    className="w-100"
                    isDisabled={isLoading}
                  />
                </Box>
              </AnimatedPaper>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={isFeatureAccessExpanded}
          onChange={() => setIsFeatureAccessExpanded(!isFeatureAccessExpanded)}
          sx={{
            borderRadius: "10px",
            mb: 3,
            p: 0,
            "&:before": {
              display: "none",
            },
            backgroundColor: "#1e1e2d",
            "&.Mui-expanded": {
              margin: 0,
              marginBottom: 3,
            },
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          }}
        >
          <AccordionSummary
            expandIcon={
              isFeatureAccessExpanded ? (
                <KeyboardArrowUp sx={{ color: "#fff" }} />
              ) : (
                <KeyboardArrowDown sx={{ color: "#fff" }} />
              )
            }
            aria-controls="feature-access-content"
            id="feature-access-header"
            sx={{
              padding: "0 16px",
              minHeight: "58px",
              backgroundColor: "#2a2a3c",
              borderRadius: "10px",
              overflow: "hidden",
             
              "&.Mui-expanded": {
                minHeight: "58px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>Manage Role and Feature Access</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px 0",
              transition: "all 0.3s ease-in-out",
              backgroundColor: "#ededf2",
            }}
          >
            <Box sx={{ px: 3 }}>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                  border: "1px solid #e0e0e0",
                  maxHeight: "400px",
                  overflow: "auto",
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Functionality Name</StyledTableCell>
                      <StyledTableCell align="center">Reader</StyledTableCell>
                      <StyledTableCell align="center">Maker</StyledTableCell>
                      <StyledTableCell align="center">Checker</StyledTableCell>
                      <StyledTableCell align="center">Full Access</StyledTableCell>
                      <StyledTableCell align="center">No Access</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {functionalities.map((functionality) => (
                      <TableRow key={functionality.functionality_id}>
                        <StyledTableCell>{functionality.functionality_name}</StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[functionality.functionality_id] === 1}
                            onChange={() => handlePermissionChange(functionality.functionality_id, 1)}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[functionality.functionality_id] === 2}
                            onChange={() => handlePermissionChange(functionality.functionality_id, 2)}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[functionality.functionality_id] === 3}
                            onChange={() => handlePermissionChange(functionality.functionality_id, 3)}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[functionality.functionality_id] === 4}
                            onChange={() => handlePermissionChange(functionality.functionality_id, 4)}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StyledRadio
                            checked={permissions[functionality.functionality_id] === 5}
                            onChange={() => handlePermissionChange(functionality.functionality_id, 5)}
                            disabled={isLoading}
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
        {error && (
          <Box
            sx={{
              color: "error.main",
              mt: 2,
              textAlign: "center",
              padding: "8px",
              backgroundColor: "error.light",
              borderRadius: "4px",
            }}
          >
            {error}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2, justifyContent: "center" }}>
        <SaveButton variant="contained" onClick={handleSaveChanges} disableElevation disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save Changes"}
        </SaveButton>
        <CancelButton variant="outlined" onClick={onClose} disabled={isLoading}>
          Cancel
        </CancelButton>
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </StyledDialog>
  )
})

export default EditRoleDialog

