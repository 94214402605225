import { useState, useEffect, useCallback, memo } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { fetchToken } from "../../Auth"
import axios from "axios"
// Remove react-toastify import as we'll handle toasts in parent

// Styled components remain the same...
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    width: "100%",
  },
}))

const DialogHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FF8A00",
  color: "white",
  "&:hover": {
    backgroundColor: "#ff9d2f",
  },
}))

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[700],
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}))

const EditDesignationDialog = memo(function EditDesignationDialog({ open, onClose, role, setDesignationDetails }) {
  const [designationName, setDesignationName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [apiError, setApiError] = useState(null)

  // Move validation to a utility function that can be shared
  const validateDesignationName = (name) => {
    const regex = /^[a-zA-Z0-9\s]+$/
    return regex.test(name)
  }

  useEffect(() => {
    if (open && role) {
      setDesignationName(role.role || "")
      setError(null)
      setApiError(null)
    }
  }, [open, role])

  const handleInputChange = useCallback((e) => {
    setDesignationName(e.target.value)
    setError(null)
    setApiError(null)
  }, [])

  const handleUpdateDesignation = useCallback(async () => {
    const trimmedName = designationName.trim()

    if (!trimmedName) {
      setError("Designation name is required")
      return
    }

    // Only validate, don't show toast here
    if (!validateDesignationName(trimmedName)) {
      setError("Designation name should not contain special characters")
      return
    }

    try {
      setIsLoading(true)
      setError(null)
      setApiError(null)

      const token = fetchToken("zetta_access_token")
      if (!token) {
        throw new Error("Authentication token not found")
      }

      const response = await axios.post(
        "/update_user_designation",
        {
          designationId: role?.id,
          designationName: trimmedName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (response.data.success) {
        setDesignationDetails((prevState) => ({
          ...prevState,
          designations: prevState.designations.map((item) =>
            item.user_designation_id === role?.id
              ? {
                  ...item,
                  user_designation_name: response.data.designation.designation_name,
                  updated_at: response.data.designation.updated_at,
                  created_by: response.data.designation.updated_by,
                }
              : item,
          ),
        }))
        handleClose()
      } else {
        setApiError(response.data.message || "Failed to update designation")
      }
    } catch (error) {
      console.error("Error updating designation:", error)
      setApiError(error.response?.data?.message || "An error occurred while updating the designation")
    } finally {
      setIsLoading(false)
    }
  }, [designationName, role, setDesignationDetails])

  const handleClose = useCallback(() => {
    setError(null)
    setApiError(null)
    setDesignationName("")
    onClose()
  }, [onClose])

  const isSaveDisabled = isLoading || !designationName.trim() || (role && designationName.trim() === role.role)

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth disableEscapeKeyDown={isLoading}>
      <DialogHeader>
        <Typography variant="h6" component="div">
          Edit User Designation
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: "#666" }} disabled={isLoading}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent sx={{ p: 3 }}>
        {apiError && (
          <Typography color="error" variant="body2" sx={{ mb: 2, p: 1, bgcolor: "#fff4f4", borderRadius: 1 }}>
            {apiError}
          </Typography>
        )}
        <TextField
          fullWidth
          label="Designation Name"
          placeholder="Enter Designation Name"
          value={designationName}
          onChange={handleInputChange}
          error={!!error}
          helperText={error}
          sx={{ mt: 2 }}
          disabled={isLoading}
          autoFocus
          aria-label="Designation Name"
          inputProps={{
            maxLength: 100,
          }}
        />
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2, justifyContent: "center" }}>
        <SaveButton variant="contained" onClick={handleUpdateDesignation} disableElevation disabled={isSaveDisabled}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Save Changes"}
        </SaveButton>
        <CancelButton variant="outlined" onClick={handleClose} disabled={isLoading}>
          Cancel
        </CancelButton>
      </DialogActions>
    </StyledDialog>
  )
})

export default EditDesignationDialog

