import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grow from "@mui/material/Grow";
import Table from "../../components/common/Table/Table";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";
import { fetchToken } from "../../Auth";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TextField, Toolbar } from '@mui/material';
import useUserStore from "../../context/UserContext";
import ApprovalStore from "../../context/RoomTypeMealTypeApprovalContext";
import RoomTypeApprovalModal from "./RoomTypeApprovalModal";
import RefreshIcon from '@mui/icons-material/Refresh';
import Chip from '@mui/material/Chip';
import { Autocomplete } from '@mui/material';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import auth from "../../assets/img/auth.png";
import Alert from '@mui/material/Alert';


const RoomTypeMappings = () => {
  const {
    roomTypeApprovalCount,
    mealPlanApprovalCount,
    incrementRoomTypeApprovalCount,
    decrementRoomTypeApprovalCount,
    incrementMealPlanApprovalCount,
    decrementMealPlanApprovalCount,
    fetchApprovalCount
  } = ApprovalStore();
  const { name, email, role,featurePrivileges } = useUserStore();
  const [popUpOpen, setPopUpOpen] = useState(false)
  const [approvalData, setApprovalData] = useState({})
  const [mappings, setMappings] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [newRoomType, setNewRoomType] = useState("");
  // const [mappedRoomTypeOptions] = useState(['standard', 'suite', 'apartment']);
  const [updatedRow, setUpdatedRow] = useState({});
  const [selectedMappedRoomType, setSelectedMappedRoomType] = useState("");
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState([]);
  const [roomTypeToMappedRoomType, setRoomTypeToMappedRoomType] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [mappingApprovalAccess, setMappingApprovalAccess] = useState(false);
  const [mappingEditAccess, setMappingEditAccess] = useState(false);
  const [newRoomTypeMappingAccess, setNewRoomTypeMappingAccess] = useState(false);
  const [addNewRoomTypeAccess, setAddNewRoomTypeAccess] = useState(false);
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [newMappedRoomType, setNewMappedRoomType] = useState("");
  const [showNewRoomTypeAdditionAlert, setShowNewRoomTypeAdditionAlert] = useState(false);
  const [showMapAdditionAlert, setShowMapAdditionAlert] = useState(false);
  const [mapAlertMessage, setMapAlertMessage] = useState('')
  const [alertMessage, setAlertMessage] = useState("");



  const popUpClose = () => {
    setPopUpOpen(false)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    if (!isEditing) {
      setIsEditing(true)
    } else {
      return;
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "mapped_room_type" },
    }));
    const editedRow = rows.find((row) => row.id === id);
  };

  const handleSaveClick = (id) => async () => {
    // console.log(id)
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
    // await updateRoomMapping(id);
  };

  const handleCancelClick = (id) => () => {
    setIsEditing(false)
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }

  };

  const updateMapping = async (id) => {
    await updateRoomMapping(id);
  }

  useEffect(() => {
    if (Object.keys(updatedRow).length) {
      updateMapping(parseInt(updatedRow['id']))
    }
  }, [updatedRow])

  const processRowUpdate = (newRow) => {
    // console.log(newRow)
    setUpdatedRow((prevRow) => ({ ...newRow }))
    return { ...newRow, isNew: false };
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };



  const cols = [
    {
      field: "room_type",
      headerName: "Room Type",
      headerClassName: "bg-grey-3",
      flex: 2,
    },
    // {
    //   field: "mapped_room_type",
    //   headerName: "Mapped Room Type",
    //   headerClassName: "bg-grey-3",
    //   flex: 1,
    //   editable: true,
    //   type: "singleSelect",
    //   valueOptions: roomTypeToMappedRoomType,
    // },
    {
      field: "mapped_room_type",
      headerName: "Mapped Room Type",
      headerClassName: "bg-grey-3",
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          freeSolo
          options={roomTypeToMappedRoomType}
          value={params.value}
          onChange={(event, newValue) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
          }}
          onInputChange={(event, newInputValue) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: newInputValue });
          }}
          renderInput={(params) => <TextField {...params} />}
          fullWidth
        />
      ),
    },
    // {
    //   field: "suggested_mapped_room_type",
    //   headerName: "Suggested Mapping",
    //   headerClassName: "bg-grey-3",
    //   flex: 1,
    // },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "bg-grey-3",
      flex: 1,
      sortable: false,
      // align: 'center',
      // headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.is_approved) {
          return (
            <Chip
              label="Approved"
              color="success"
              size="small"
              sx={{ "width": "15em" }}
            />
          );
        } else if (!params.row.is_approved && params.row.approval_pending && params.row.auto_approval) {
          return (
            <Chip
              label="Automated Mapping"
              sx={{ backgroundColor: "#0091ea", color: "white", "width": "15em" }}
              size="small"
            />
          )
        } else if (!params.row.is_approved && params.row.approval_pending) {
          return (
            <Chip
              label="Awaiting Checker’s Approval"
              sx={{ backgroundColor: "#f57f17", color: "white", "width": "15em" }}
              size="small"
            />
          )
        }
        return null;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "bg-grey-3",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={row.approval_pending || !mappingEditAccess}
          />,
        ];
      },
    },
    {
      field: "approvals",
      type: "actions",
      headerName: "Approvals",
      headerClassName: "bg-grey-3",
      flex: 0.5,
      cellClassName: "approvals",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<UpdateOutlinedIcon />}
            label="Approve"
            disabled={!mappingApprovalAccess || row.is_approved}
            onClick={() => {
              setApprovalData(row)
              setPopUpOpen(true)
            }}
          />
        ];
      },
    },


  ]

  const navigate = useNavigate();
  const handleMappedRoomTypeChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, mapped_room_type: value } : row
      )
    );
  };

  const get_room_type_to_mapped_room_type = async () => {
    try {
      const response = await axios.get("/uniq-room-type-mappings", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      // setRoomTypeToMappedRoomType(response.data.msg[0].all);
      setRoomTypeToMappedRoomType(response.data);
    } catch (error) {
      console.error("Error", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
      toast.error("Error while getting Room Type mappings");
    }
  };
  const getRoomTypeMappings = async () => {
    try {
      const response = await axios.get("/get_room_type_mappings", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status === 200) {
        setRows(response.data);
        if (searchText) {
          const filtered = response.data.filter((row) =>
            Object.values(row).some((value) =>
              String(value).toLowerCase().includes(searchText)
            )
          );
          setFilteredRows(filtered)
        } else {
          setFilteredRows(response.data)
        }

      }
    } catch (error) {
      console.error("Error", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const updateRoomMapping = async (id) => {
    try {
      const data = {
        ...updatedRow,
        mapping_approval_access: mappingApprovalAccess,
        functionality_id:34,
        required_privilege:2
      }
      if (!data.mapped_room_type || data.mapped_room_type.trim() === "") {
        toast.error("Invalid room type.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.ERROR
        });
        setIsEditing(false)
        setUpdatedRow({});
        return
      }
      const response = await axios.post("/update_room_type_mapping", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status === 200) {
        setIsEditing(false)
        setUpdatedRow({});
        getRoomTypeMappings();
        get_room_type_to_mapped_room_type();
        if (!mappingApprovalAccess) {
          incrementRoomTypeApprovalCount()
        }
        toast.success(response.data.msg, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.SUCCESS
        });
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === updatedRow.id ? { ...updatedRow, isNew: false } : row
          )
        );
      }
    } catch (error) {
      console.error("Error adding mapping", error);
      setIsEditing(false)
      setUpdatedRow({});
      toast.error("Error updating room type mapping.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR
      });
    }
  };
  const handleAddMapping = async () => {
    try {
      const newMapping = {
        room_type: newRoomType,
        mapped_room_type: selectedMappedRoomType,
        functionality_id:35,
        required_privilege:3
      };
      if (newRoomType.trim() === "" || !newRoomType) {
        // toast.error("");
        setMapAlertMessage("Enter valid room type.")
        setShowMapAdditionAlert(true);
        return
      }
      if (!selectedMappedRoomType || selectedMappedRoomType.trim() === "") {
        setMapAlertMessage("Enter a valid mapping.")
        setShowMapAdditionAlert(true);
        return
      }
      let isMatched = false;
      for (const item of rows) {
        if (item.room_type.includes(newRoomType)) {
          isMatched = true;
          break;
        }
      }
      if (isMatched) {
        setMapAlertMessage("Mapping for the room type already exists!")
        setShowMapAdditionAlert(true);
        return
      }

      const response = await axios.post("/add_room_type_mapping", newMapping, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });

      if (response.status == 200) {
        setOpenForm(false);
        getRoomTypeMappings();
        get_room_type_to_mapped_room_type();
        toast.success("Room type mapping added successfully!");
      }
    } catch (error) {
      console.error("Error adding mapping", error);
      toast.error("Error adding room type mapping.");
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
    setNewRoomType('')
    setSelectedMappedRoomType('')
  };

  const handleAddMappedRoomType = async () => {
    try {
      const data = {
        new_mapped_room_type: newMappedRoomType,
        functionality_id:36,
        required_privilege:3
      }
      if (!newMappedRoomType || newMappedRoomType.trim() === "") {
        setAlertMessage("Enter a valid room type")
        setShowNewRoomTypeAdditionAlert(true);
        return;
      }
      const response = await axios.post("/add_mapped_room_type", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200) {

        setOpenAddNewModal(false);
        get_room_type_to_mapped_room_type();
        toast.success("Room type added successfully!");
      }
    } catch (error) {
      console.error("Error added mapped room type", error)
      if (error.response && error.response.status === 400) {
        setAlertMessage(error.response.data.msg);
        setShowNewRoomTypeAdditionAlert(true)
      }

      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
    setNewMappedRoomType("")
  }

  const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    // console.log(searchValue)
    setSearchText((preVal) => searchValue);

    const filtered = rows.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchValue)
      )
    );

    setFilteredRows(filtered);
  };

  useEffect(() => {
    getRoomTypeMappings();
    get_room_type_to_mapped_room_type();
  }, []);


  const hasPrivilege = (functionalityId, requiredPrivilege) => {
    return featurePrivileges?.[functionalityId] >= requiredPrivilege && featurePrivileges?.[functionalityId] != 5;
  };

  useEffect(() => {
    setMappingApprovalAccess(hasPrivilege(33, 3));
    setMappingEditAccess(hasPrivilege(34, 2));
    setNewRoomTypeMappingAccess(hasPrivilege(35, 3)); 
    setAddNewRoomTypeAccess(hasPrivilege(36, 3)); 
  }, [featurePrivileges, role]);

  return (
    <>
      <Grow in={true} {...(true ? { timeout: 500 } : {})}>
        <div className="px-4 py-2 mt-3 white-card">
          <div className="mt-3">
            <div className="bg-grey p-1 py-2 corner w-100 row">
              <div className="col-8">
                <h3 className="dashboard-card-title">Room Type Mappings</h3>
                <p className="grey-2 small mt-1">
                  View room types mapped across all OTAs
                </p>
              </div>
              {newRoomTypeMappingAccess &&
                <div className="col-2 d-flex justify-content-between align-items-center">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setOpenForm(true)}
                  >
                    Map new room type
                  </Button>
                </div>
              }
              {addNewRoomTypeAccess &&
                <div className="col-2 d-flex justify-content-between align-items-center">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setOpenAddNewModal(true)}
                  >
                    Add new room type
                  </Button>
                </div>
              }

            </div>
          </div>

          <div className="mt-1">

            <TextField
              className='my-3 w-40'
              size='small'
              variant="outlined"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && (
                      <IconButton
                        aria-label="clear search"
                        onClick={() => {
                          handleSearch({ target: { value: '' } });
                        }}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ width: "100%" }}>

              {filteredRows.length > 0 && (
                <DataGrid
                  autoHeight={true}
                  rows={filteredRows?.map((item, index) => ({
                    id: index,
                    room_type: item.room_type,
                    mapped_room_type: item.mapped_room_type,
                    is_approved: item.is_approved,
                    changed_mapped_room_type: item.changed_mapped_room_type,
                    approval_pending: item.approval_pending,
                    auto_approval: item.auto_approval,
                    approval_sent_by: item.approval_sent_by,
                  }))}
                  pageSizeOptions={[5, 10, 50, 100]}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  columns={cols}
                  editMode="row"
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  onProcessRowUpdateError={(error) => console.log(error)}
                  sx={{ borderCollapse: "collapse", border: "none" }}
                />
              )}
            </div>
          </div>
        </div>
      </Grow>

      <Dialog
        open={openForm}
        onClose={() => {
          setOpenForm(false)
          setMapAlertMessage("")
          setShowMapAdditionAlert(false);
          setNewRoomType('')
          setSelectedMappedRoomType('')
        }}
        aria-labelledby="add-room-type-mapping"
      >
        <DialogTitle id="add-room-type-mapping">
          <h5 className="bold">Add New Room Type Mapping</h5>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="mb-4">
              <label htmlFor="room-type">Room Type</label>
              <input
                type="text"
                id="room-type"
                value={newRoomType}
                onChange={(e) => setNewRoomType(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="mb-2">
              <FormControl fullWidth>
                <label htmlFor="suggested-room-type">Select Room Type Mapping</label>
                <Autocomplete
                  freeSolo
                  id="suggested-room-type"
                  options={roomTypeToMappedRoomType || []}
                  value={selectedMappedRoomType}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedMappedRoomType(newValue);
                    } else {
                      setSelectedMappedRoomType('')
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue) {
                      setSelectedMappedRoomType(newInputValue);
                    } else {
                      setSelectedMappedRoomType('')
                    }
                  }}
                  inputValue={selectedMappedRoomType}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      sx={{ padding: 0 }}
                      {...params}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
        {showMapAdditionAlert && <Alert severity="error">{mapAlertMessage}</Alert>}
        <DialogActions>
          <Button onClick={() => {
            setOpenForm(false)
            setMapAlertMessage("")
            setShowMapAdditionAlert(false);
            setNewRoomType('')
            setSelectedMappedRoomType('')
          }}>Cancel</Button>
          <Button onClick={handleAddMapping} autoFocus>
            Add Mapping
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAddNewModal}
        onClose={() => {
          setNewMappedRoomType("")
          setOpenAddNewModal(false)
          setShowNewRoomTypeAdditionAlert(false)
          setAlertMessage("")
        }}
        aria-labelledby="add-room-type-mapping"
      >
        <DialogTitle id="add-room-type-mapping">
          <h5 className="bold">Add New Room Type Mapping</h5>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <div>
            <div className="mb-4">
              <label htmlFor="room-type">Room Type</label>
              <input
                type="text"
                id="room-type"
                value={newMappedRoomType}
                onChange={(e) => setNewMappedRoomType(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </DialogContent>
        {showNewRoomTypeAdditionAlert && <Alert severity="error">{alertMessage}</Alert>}
        <DialogActions>
          <Button onClick={() => {
            setNewMappedRoomType("")
            setOpenAddNewModal(false)
            setShowNewRoomTypeAdditionAlert(false)
            setAlertMessage("")
          }}>Cancel</Button>
          <Button onClick={handleAddMappedRoomType} autoFocus>
            Add Mapping
          </Button>
        </DialogActions>

      </Dialog>
      <div className="modal-container">
        <RoomTypeApprovalModal open={popUpOpen} onClose={popUpClose} approvalData={approvalData} roomTypeToMappedRoomType={roomTypeToMappedRoomType} getRoomTypeMappings={getRoomTypeMappings} get_room_type_to_mapped_room_type={get_room_type_to_mapped_room_type} />
      </div>
    </>
  );
};

export default RoomTypeMappings;
