// import { useEffect } from "react"
// import UserAccess from "../../context/UserAccess";
// import useAuthStore from "../../context/useAuthStore";

// const PrivilegeUpdater = () => {
//   const user = useAuthStore((state) => state.user);
//   const fetchPrivileges = UserAccess((state) => state.fetchPrivileges);
  
//   useEffect(() => {
//     if (user?.id) {
//         console.log("FDFDDF")
//       fetchPrivileges(user.id);
//     }
//   }, [user]); 

//   return null;
// };

// export default PrivilegeUpdater;


import { useEffect } from "react";
import useUserStore from "../../context/UserContext"; 

const PrivilegeUpdater = () => {
  const user = useUserStore((state) => state.user);
  const fetchPrivileges = useUserStore((state) => state.fetchPrivileges);

  useEffect(() => {
    if (user?.email) {
      fetchPrivileges(user.id);
    }
  }, [user?.id, fetchPrivileges]); 
  return null;
};

export default PrivilegeUpdater;
