import React from "react"
import axios from "axios"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import { fetchToken } from "../../Auth"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    width: "100%",
  },
}))

const DialogHeader = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  padding: "16px 24px",
  borderBottom: "1px solid #e0e0e0",
  "& .MuiTypography-root": {
    color: "#FF8A00",
    fontWeight: 500,
  },
})

const CreateButton = styled(Button)({
  backgroundColor: "#FF8A00",
  color: "white",
  "&:hover": {
    backgroundColor: "#ff9d2f",
  },
})

const CancelButton = styled(Button)({
  color: "#666",
  borderColor: "#ddd",
})

export default function CreateDesignationDialog({ open, onClose, onSuccess }) {
  const navigate = useNavigate()
  const [designationName, setDesignationName] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [apiError, setApiError] = React.useState(null)

  const validateDesignationName = (name) => {
    // Allow only letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9\s]+$/
    return regex.test(name)
  }

  // Reset errors when input changes
  const handleInputChange = (e) => {
    setDesignationName(e.target.value)
    setError(null)
    setApiError(null)
  }

  const handleCreateDesignation = async () => {
    const trimmedName = designationName.trim()

    if (!trimmedName) {
      setError("Designation name is required")
      return
    }

    if (!validateDesignationName(trimmedName)) {
      setError("Designation name should not contain special characters")
      return
    }

    try {
      setIsLoading(true)
      setApiError(null)

      const payload = {
        designationName: trimmedName,
      }

      const response = await axios.post("/create_user_designation", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      })

      if (response.status === 201) {
        setDesignationName("")
        onSuccess?.()
        onClose()
      }
    } catch (error) {
      console.error("Error creating designation:", error)
      
      if (error.response?.status === 403) {
        setApiError("Access Denied: Insufficient Permissions")
      } else if (error.response?.status === 400) {
        setApiError(error.response.data.message || "Invalid input")
      } else if (error.response?.status === 401) {
        navigate("/login")
      } else {
        setApiError("An error occurred while creating the designation")
      }
    } finally {
      setIsLoading(false)
    }
  }

  // Reset form state when dialog closes
  const handleClose = () => {
    setDesignationName("")
    setError(null)
    setApiError(null)
    onClose()
  }

  return (
    <StyledDialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="sm" 
      fullWidth
      disableEscapeKeyDown={isLoading}
    >
      <DialogHeader>
        <Typography component="div" variant="h6">
          Create New User Designation
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: "#666" }} disabled={isLoading}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent sx={{ p: 3 }}>
        {apiError && (
          <Typography 
            color="error" 
            variant="body2" 
            sx={{
              mt:1, 
              mb: 2, 
              p: 1, 
              bgcolor: "#fff4f4", 
              borderRadius: 1 
            }}
          >
            {apiError}
          </Typography>
        )}
        <TextField
          fullWidth
          label="Designation Name"
          placeholder="Enter Designation Name"
          value={designationName}
          onChange={handleInputChange}
          error={!!error}
          helperText={error}
          sx={{ mt: 2 }}
          disabled={isLoading}
          autoFocus
          inputProps={{
            maxLength: 100,
          }}
        />
      </DialogContent>

      <DialogActions sx={{ p: 3, gap: 2, justifyContent: "center" }}>
        <CreateButton
          variant="contained"
          onClick={handleCreateDesignation}
          disableElevation
          disabled={isLoading || !designationName.trim()}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Create Designation"}
        </CreateButton>
        <CancelButton variant="outlined" onClick={handleClose} disabled={isLoading}>
          Cancel
        </CancelButton>
      </DialogActions>
    </StyledDialog>
  )
}