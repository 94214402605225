import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Tabs, Tab, Box, Typography, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TransferDetailsModal = ({ open, onClose, transferData }) => {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;

    if (isEmpty(transferData)) {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Transfer Details</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" color="textSecondary" align="center">
                        No transfer data available.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const formatSummary = (summary) => {
        const result = [];
        Object.entries(summary).forEach(([dbName, collections]) => {
            Object.entries(collections).forEach(([collectionName, stats]) => {
                result.push({
                    database: dbName,
                    collection: collectionName,
                    found: stats.documents_found || 0,
                    deleted: stats.documents_deleted || 0,
                    transferred: stats.documents_transferred || 0,
                    status: stats.status || 'completed'
                });
            });
        });
        return result;
    };

    const propertyData = transferData.property_transfer_summary && transferData.property_id
        ? formatSummary(transferData.property_transfer_summary[transferData.property_id] || {})
        : [];

    const completeData = transferData.complete_transfer_summary
        ? formatSummary(transferData.complete_transfer_summary)
        : [];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Transfer Details
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Transfer ID</Typography>
                            <Typography variant="body1">{transferData._id || 'N/A'}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Created By</Typography>
                            <Typography variant="body1">{transferData.created_by || 'Unknown'}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Date Range</Typography>
                            <Typography variant="body1">
                                {transferData.start_date || 'N/A'} to {transferData.end_date || 'N/A'}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Transfer Path</Typography>
                            <Typography variant="body1">
                                {transferData.transfer_from?.toUpperCase() || 'N/A'} → {transferData.transfer_to?.toUpperCase() || 'N/A'}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Created Date Time</Typography>
                            <Typography variant="body1">
                                {transferData.create_dtm?.toUpperCase() || 'N/A'}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">End Date Time</Typography>
                            <Typography variant="body1">
                                {transferData.end_dtm?.toUpperCase() || 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Tabs for Property & Complete Transfer Summary */}
                <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label="Property Transfer Summary" />
                    <Tab label="Complete Transfer Summary" />
                </Tabs>

                {/* Property Transfer Summary Table */}
                {tabValue === 0 && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="bold" >Database</TableCell>
                                    <TableCell className="bold" >Collection</TableCell>
                                    <TableCell className="bold" align="right">Documents Found</TableCell>
                                    <TableCell className="bold" align="right">Documents Deleted</TableCell>
                                    <TableCell className="bold" align="right">Documents Transferred</TableCell>
                                    <TableCell className="bold" >Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {propertyData.length > 0 ? (
                                    propertyData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.database}</TableCell>
                                            <TableCell>{row.collection}</TableCell>
                                            <TableCell align="right">{row.found}</TableCell>
                                            <TableCell align="right">{row.deleted}</TableCell>
                                            <TableCell align="right">{row.transferred}</TableCell>
                                            <TableCell>
                                                <span style={{
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    fontSize: '12px',
                                                    backgroundColor: row.status === 'completed' ? '#d4edda' : '#fff3cd',
                                                    color: row.status === 'completed' ? '#155724' : '#856404'
                                                }}>
                                                    {row.status}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">No data available</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {/* Complete Transfer Summary Table */}
                {tabValue === 1 && (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="bold">Database</TableCell>
                                    <TableCell className="bold">Collection</TableCell>
                                    <TableCell className="bold" align="right">Documents Found</TableCell>
                                    <TableCell className="bold" align="right">Documents Deleted</TableCell>
                                    <TableCell className="bold" align="right">Documents Transferred</TableCell>
                                    <TableCell className="bold">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {completeData.length > 0 ? (
                                    completeData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.database}</TableCell>
                                            <TableCell>{row.collection}</TableCell>
                                            <TableCell align="right">{row.found}</TableCell>
                                            <TableCell align="right">{row.deleted}</TableCell>
                                            <TableCell align="right">{row.transferred}</TableCell>
                                            <TableCell>
                                                <span style={{
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    fontSize: '12px',
                                                    backgroundColor: row.status === 'completed' ? '#d4edda' : '#fff3cd',
                                                    color: row.status === 'completed' ? '#155724' : '#856404'
                                                }}>
                                                    {row.status}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">No data available</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {/* Summary Section */}
                <Box mt={3} p={2} bgcolor="#f9fafb" borderRadius={2}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Transfer Summary
                    </Typography>
                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Total Documents Inserted</Typography>
                            <Typography variant="body1">{transferData.total_documents_inserted?.toLocaleString() || 0}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">Total Documents Deleted</Typography>
                            <Typography variant="body1">{transferData.total_documents_deleted?.toLocaleString() || 0}</Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            {/* <DialogActions>
                <Button variant="outlined" onClick={onClose}>Close</Button>
            </DialogActions> */}
        </Dialog>
    );
};

export default TransferDetailsModal;
