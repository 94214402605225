export function deslugify(slug) {
    return slug
        .split('-') // Split the string by hyphens
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with spaces
}

export function openUrlInIncognito(url) {
    if (url.startsWith("http://") || url.startsWith("https://")) {

    }
    else {
        url = `https://${url}`
    }
    window.open(url, '_blank', 'incognito=yes');
}


export function getRoomURL(ota, hotel_id, cin, additionalData, los = 1) {
    let numRooms = 1
    let numAdults = 2
    let numChildren = 0
    let url = null

    let dateNew = new Date(cin)

    dateNew.setDate(dateNew.getDate() + los)


    const yyyy = dateNew.getFullYear();
    const mm = String(dateNew.getMonth() + 1).padStart(2, '0');
    const dd = String(dateNew.getDate()).padStart(2, '0');
    const cout = `${yyyy}-${mm}-${dd}`;


    try {
        if (ota == "agoda") {
            if (additionalData?.[hotel_id]) {
                if (additionalData[hotel_id]?.[`${ota}_page_name`]) {
                    let pageName = additionalData[hotel_id][`${ota}_page_name`]
                    url = `agoda.com${pageName}?adults=${numAdults}&children=${numChildren}&rooms=${numRooms}&checkIn=${cin}&los=${los}`
                }
            }
        }
        else if (ota == "booking") {
            if (additionalData?.[hotel_id]) {
                if (additionalData[hotel_id]?.[`${ota}_page_name`] && additionalData[hotel_id]?.[`${ota}_id`]) {
                    let pageName = additionalData[hotel_id][`${ota}_page_name`]
                    let otaId = additionalData[hotel_id][`${ota}_id`]
                    url = `booking.com/hotel${pageName}.en-gb.html?all_sr_blocks=${otaId};checkin=${cin};checkout=${cout};group_adults=${numAdults};group_children=${numChildren};no_rooms=${numRooms};req_adults=${numAdults};req_children=${numChildren};`
                }
            }
        }
        else if (ota == "mmt") {
            if (additionalData?.[hotel_id]) {
                if (additionalData[hotel_id]?.[`${ota}_id`]) {
                    let ch_in = cin.replaceAll("-", "").substring(4) + cin.substring(0, 4)
                    let ch_out = cout.replaceAll("-", "").substring(4) + cout.substring(0, 4)
                    let otaId = additionalData[hotel_id][`${ota}_id`]
                    url = `makemytrip.com/hotels/hotel-details/?hotelId=${otaId}&_uCurrency=INR&checkin=${ch_in}&checkout=${ch_out}&city=abc&country=IN&rsc=${numRooms}e${numAdults}e${numChildren}e`
                }
            }
        }

        else if (ota == "emt") {
            if (additionalData?.[hotel_id]) {
                if (additionalData[hotel_id]?.[`${ota}_id`] && additionalData[hotel_id]?.[`${ota}_hotel_url`]) {
                    let ch_in = cin.split('-').reverse().join('/')
                    let ch_out = cout.split('-').reverse().join('/')
                    let otaId = additionalData[hotel_id][`${ota}_id`]
                    let hotelMainUrl = additionalData[hotel_id]?.[`${ota}_hotel_url`]
                    let hotelUrl = hotelMainUrl.split("?")[0]
                    // let hotelCity = additionalData[hotel_id]?.[`${ota}_city`]
                    // url = `${hotelUrl}?city=${hotelCity}&cin=${ch_in}&cOut=${ch_out}&Rooms=${numRooms}&pax=${numAdults}&emtid=${otaId.replaceAll("-", "")}&ecid=${otaId}`
                    url = `${hotelUrl}?city=xyz&cin=${ch_in}&cOut=${ch_out}&Rooms=${numRooms}&pax=${numAdults}&emtid=${otaId.replaceAll("-", "")}&ecid=${otaId}`
                }
            }
        }

        else if (ota == "ixigo") {
            if (additionalData?.[hotel_id]) {
                if (additionalData[hotel_id]?.[`${ota}_id`]) {
                    const ch_in = cin.split("-").reverse().join("");
                    const ch_out = cout.split("-").reverse().join("");
                    let otaId = additionalData[hotel_id][`${ota}_id`]

                    if (additionalData[hotel_id]?.[`${ota}_info_token`]) {
                        const infoToken = additionalData[hotel_id][`${ota}_info_token`]
                        url = `https://www.ixigo.com/hotels/${otaId}/details?checkinDate=${ch_in}&checkoutDate=${ch_out}&adultCount=${numAdults}&roomCount=${numRooms}&childCount=0&hotelInfoToken=${infoToken}`;

                    } else {
                        url = `https://www.ixigo.com/hotels/${otaId}/details?checkinDate=${ch_in}&checkoutDate=${ch_out}&adultCount=${numAdults}&roomCount=${numRooms}&childCount=0`;
                    }
                }
            }
        }

        else if (ota == "expedia") {
            if (additionalData?.[hotel_id]) {
                if (additionalData[hotel_id]?.[`${ota}_page_name`]) {
                    let pageName = additionalData[hotel_id][`${ota}_page_name`]
                    url = `https://www.expedia.com${pageName}?chkin=${cin}&chkout=${cout}`
                }
            }
        }


    } catch (error) {
        console.error("Error generating room url", error)
    }

    return url


}

export function formatDate(dateString) {
    try {
        let [datePart, hourPart] = dateString.split("|");
        if (typeof (hourPart) == "undefined") {
            [datePart, hourPart] = dateString.split(" ")
        }
        const [year, month, day] = datePart.split("-").map(Number);

        const date = new Date(year, month - 1, day, parseInt(hourPart), 0);

        const formattedDay = String(date.getDate()).padStart(2, "0");
        const formattedMonth = String(date.getMonth() + 1).padStart(2, "0");
        const formattedYear = date.getFullYear();
        // const formattedHour = String(date.getHours()).padStart(2, "0");
        // const formattedMinute = String(date.getMinutes()).padStart(2, "0");

        const [hour, min, sec] = hourPart.split(":")


        return `${formattedDay}-${formattedMonth}-${formattedYear} @ ${hour}:${min}`;
    } catch (error) {
        console.error("Error in formatDate", error)
    }
}

export function getCurrentDate() {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    return formattedDate
}