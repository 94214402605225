import { useEffect,useState } from 'react'
import { Routes, Route, useNavigate ,useLocation} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout';
import { AppProvider } from '../../context/AppContext';
import { isValidRoute } from '../../Auth';
import useUserStore from "../../context/UserContext";

import Dashboard from './Dashboard';
import Mappings from './Mappings';
import HotelMappingsDashboard from './HotelMappingsDashboard';
import DashboardSessionTracker from "../../components/common/SessionTracker"

const HotelMappings = ({ match }) => {
  const [dashboardId,setDashboardId] = useState("")
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };
  
    fetchId();
  }, []);
  
  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {
    checkRoute();
  }, [dashboardId]); 

  return (
    <AppProvider>
      {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}
      <BaseLayout>
        <Routes>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="mappings" element={<HotelMappingsDashboard />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default HotelMappings;