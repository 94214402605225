import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../assets/styles/modal.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Autocomplete, TextField } from '@mui/material';
import axios from "axios";
import { fetchToken } from "../../Auth";
import Alert from '@mui/material/Alert';
import ApprovalStore from "../../context/RoomTypeMealTypeApprovalContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
const dialogPaperStyle = {
    // height: "23.5rem",
    width: "50rem"
};

const MealPlanApprovalModal = ({ open, onClose, onApprove, approvalData, mappedMealPlans, getMealPlanMappings }) => {
    const {
        mealPlanApprovalCount,
        decrementMealPlanApprovalCount,
    } = ApprovalStore();

    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false); // New state for the confirmation dialog
    const [selectedMealPlan, setSelectedMealPlan] = useState('');
    const [approveDisable, setApproveDisable] = useState(false);
    const navigate = useNavigate();




    useEffect(() => {
        setSelectedMealPlan(approvalData.changed_meal_plan || '');
    }, [approvalData]);

    const closeModal = () => {
        setAlertMessage("");
        setShowAlert(false);
        setSelectedMealPlan("");
        onClose();
    };

    const handleApproveClick = () => {
        // Show confirmation dialog before approving
        setShowConfirmationDialog(true);
    };

    const handleCancelConfirmation = () => {
        // Hide the confirmation dialog
        setShowConfirmationDialog(false);
    };

    const confirmApproval = async () => {
        try {
            setApproveDisable(true);
            const data = {
                ...approvalData,
                changed_meal_plan: selectedMealPlan,
                functionality_id:37,
                required_privilege:3
            };
            if (!selectedMealPlan) {
                setAlertMessage("Select a mapping!");
                setShowAlert(true);
                return;
            }
            if (!mappedMealPlans.includes(selectedMealPlan)) {
                setAlertMessage("Select a valid mapping!");
                setShowAlert(true);
                return;
            }
            const response = await axios.post("/approve_meal_plan_mapping", data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            });
            if (response.status === 200) {
                closeModal();
                toast.success(response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.SUCCESS
                });
                decrementMealPlanApprovalCount();
                setAlertMessage("");
                setShowAlert(false);
                getMealPlanMappings();
            }
        } catch (err) {
            console.error("Error approving mapping", err);
            if (err.response && err.response.status === 500) {
                toast.error('Something went wrong!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                });
            }
            if (err.response && err.response.status === 400) {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    theme: "colored",
                    type: toast.TYPE.ERROR
                });
            }
            if (err.response && err.response.status === 401) {
                navigate('/login');
            }
        } finally {
            // Close the confirmation dialog
            setShowConfirmationDialog(false);
            setApproveDisable(false);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={closeModal}
                PaperProps={{
                    style: dialogPaperStyle
                }}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "0" }}>
                    <p style={{ margin: 0, fontWeight: "bold" }}>Approve Mapping</p>
                    <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: 0 }}>
                    <div className="modal-container">
                        <p className="grey-text">Existing Mapping</p>
                        <div className="input-container">
                            <div className="ind-inputs">
                                <label htmlFor="meal-plan">Meal Plan</label>
                                <input type="text" className='form-control' name='meal-plan' id='meal-plan' disabled value={approvalData.key} title={approvalData.key} />
                            </div>
                            <div className="ind-inputs">
                                <label htmlFor="mapped-meal-plan">Mapped Meal Plan</label>
                                <input type="text" className='form-control' name='mapped-meal-plan' id='mapped-meal-plan' disabled value={approvalData.meal_plan} title={approvalData.meal_plan} />
                            </div>

                        </div>
                        <p className="grey-text">Recommended Mapping</p>
                        <div className="input-container">
                            <div className="ind-inputs">
                                <label htmlFor="meal-plan-2">Meal Plan</label>
                                <input type="text" className='form-control' name='meal-plan-2' id='meal-plan-2' disabled value={approvalData.key} title={approvalData.key} />
                            </div>
                            <div className="ind-inputs">
                                <label htmlFor="suggested-meal-plan">Suggested Meal Plan Mapping<Tooltip title="Can edit the suggested mapping" placement="top"><InfoOutlinedIcon sx={{ height: "0.65em", cursor: 'pointer' }} /></Tooltip></label>
                                <Autocomplete
                                    freeSolo
                                    id="suggested-meal-plan"
                                    options={mappedMealPlans || []}
                                    value={selectedMealPlan}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setSelectedMealPlan(newValue);
                                        } else {
                                            setSelectedMealPlan('')
                                        }
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        if (newInputValue) {
                                            setSelectedMealPlan(newInputValue);
                                        } else {
                                            setSelectedMealPlan('')
                                        }
                                    }}
                                    inputValue={selectedMealPlan}
                                    sx={{ width: "15em", height: "1em" }}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ padding: 0 }}
                                            {...params}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {approvalData.approval_sent_by &&
                            <div className='mt-4'>
                                Sent By: {approvalData.approval_sent_by}
                            </div>
                        }
                        <div className='mt-2'>
                            {showAlert && <Alert severity="error">{alertMessage}</Alert>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: "center", alignItems: "center", marginBottom: "1em" }}>
                    <Button variant="outlined" onClick={handleApproveClick} color="primary">
                        Approve Mapping
                    </Button>
                    {/* <Button variant="outlined" onClick={closeModal} color="primary">
                        Cancel
                    </Button> */}
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog
                open={showConfirmationDialog}
                onClose={handleCancelConfirmation}
                PaperProps={{
                    sx: {
                        top: 0, // Align it closer to the top of the screen
                        marginTop: '2rem',
                        position: 'absolute',
                    }
                }}
            >
                <DialogTitle>Confirm Approval</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to approve this mapping?</p>
                </DialogContent>
                <DialogActions>
                    <Button disabled={approveDisable} onClick={confirmApproval} color="primary" variant="contained">
                        Save
                    </Button>
                    <Button onClick={handleCancelConfirmation} color="primary" variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MealPlanApprovalModal;
