import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import BaseLayout from './components/common/Layout/BaseLayout';
import { AppProvider } from './context/AppContext';

import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import SignUp from './pages/SignUp';
import Onboarding from './pages/Onboarding';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import HomeLayout from './pages/HomeLayout';
import Profile from './pages/Profile';
import Admin from './pages/Admin/Admin';
import Competition from './pages/Competition/Competition';
import Master from './pages/Master/Master';
import RSM from './pages/RSM/RSM';
import Tech from './pages/Tech/Tech';
import MarketIntel from './pages/MarketIntel/MarketIntel';
import HotelMappings from './pages/HotelMappings/HotelMappings';
import MappingTabs from './pages/HotelMappings/MappingTabs';
import Settings from './pages/Settings/Settings';
import UsageTracker from './pages/UsageTracker/UsageTracker';
import ClientManagement from './pages/Client/ClientManagement';
import HotelManagement from './pages/HotelManagement/HotelManagement';
import DataVoyage from "./pages/DataVoyage/DataVoyage";
import NotFound from './pages/NotFound';
import Temp from './pages/Temp';
import { SessionTrackerProvider } from "./context/SessionTrackerContext";


import { RequireToken, fetchToken, deleteToken } from "./Auth";

import axios from 'axios';
import PrivilegeUpdater from "./components/common/PrivilegeUpdater";

import useUserStore from "./context/UserContext";



import io from 'socket.io-client';

export const UserContext = createContext();


function App() {
    const [isStaff, setIsStaff] = useState("");
    const [socket, setSocket] = useState(null);

    const user = useUserStore((state) => state.user);
    return (
        <>
            <BrowserRouter>
                {user && <PrivilegeUpdater />}
                <Routes>
                    <Route exact path='/' element={<AppProvider><Login /></AppProvider>} />


                    {/* Auth routes */}
                    <Route exact path='/login' element={<AppProvider><Login /></AppProvider>} />
                    <Route exact path='/reset-password' element={<AppProvider><ResetPassword /></AppProvider>} />
                    <Route exact path='/temp' element={<Temp />} />
                    <Route exact path='/sign-up' element={<SignUp />} />
                    <Route exact path='/onboarding' element={<Onboarding />} />

                    {/* Profile routes */}
                    <Route exact path='/profile' element={<RequireToken><Profile /></RequireToken>} />

                    {/* Dashboard routes */}
                    <Route exact path='/home' element={<RequireToken><AppProvider><SessionTrackerProvider><HomeLayout /></SessionTrackerProvider></AppProvider></RequireToken>} />

                    {/* User Management routes */}
                    <Route path='/admin/*' element={<RequireToken><SessionTrackerProvider><Admin /></SessionTrackerProvider></RequireToken>} />

                    {/* Tech Dashboard routes */}
                    <Route path='/tech/*' element={<RequireToken><SessionTrackerProvider><Tech /></SessionTrackerProvider></RequireToken>} />

                    {/* Hotel Management routes */}
                    <Route path='/hotels/*' element={<RequireToken><SessionTrackerProvider><HotelManagement /></SessionTrackerProvider></RequireToken>} />

                    {/* Client Management routes */}
                    <Route path='/client/*' element={<RequireToken><SessionTrackerProvider><ClientManagement /></SessionTrackerProvider></RequireToken>} />

                    {/* Room Setup Management routes */}
                    <Route path='/rooms/*' element={<RequireToken><SessionTrackerProvider><RSM /></SessionTrackerProvider></RequireToken>} />

                    {/* Competitive Pricing routes */}
                    <Route path='/competitive-pricing/*' element={<RequireToken><SessionTrackerProvider><Competition /></SessionTrackerProvider></RequireToken>} />

                    {/* Master routes */}
                    <Route path='/master/' element={<RequireToken><AppProvider> <SessionTrackerProvider><BaseLayout><Master /></BaseLayout> </SessionTrackerProvider></AppProvider></RequireToken>} />

                    {/* Market Intel */}
                    <Route path='/market-intel/' element={<RequireToken><AppProvider><SessionTrackerProvider><BaseLayout><MarketIntel /></BaseLayout></SessionTrackerProvider></AppProvider></RequireToken>} />

                    {/* Hotel Mappings Dashboard routes */}
                    <Route path='/hotel-mappings/*' element={<RequireToken><SessionTrackerProvider><HotelMappings /></SessionTrackerProvider></RequireToken>} />
                    <Route path='/mappings' element={<RequireToken><SessionTrackerProvider><MappingTabs /></SessionTrackerProvider></RequireToken>} />

                    {/* Settings Dashboard routes */}
                    <Route path='/settings/*' element={<RequireToken><SessionTrackerProvider><Settings /></SessionTrackerProvider></RequireToken>} />

                    {/* Usage Tracker Dashboard */}
                    <Route path='/usage-tracker/*' element={<RequireToken><SessionTrackerProvider><UsageTracker /></SessionTrackerProvider></RequireToken>} />

                    {/* Data Transfer Dashboard */}
                    <Route path='/data-voyage' element={<RequireToken><AppProvider><SessionTrackerProvider><BaseLayout><DataVoyage /></BaseLayout></SessionTrackerProvider></AppProvider></RequireToken>} />


                    {/* 404 Error route */}
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
