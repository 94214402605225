import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Typography,
  IconButton,
  Switch,
  LinearProgress,
  Alert,
  Paper,
  Divider,
} from "@mui/material";
import axios from "axios";
import { fetchToken } from "../../Auth";
import { toast } from "react-toastify";
import Select from "react-select";
import { FeatureAccessTable } from "../../components/common/FeatureTable.js";
import { Chip } from "@mui/material";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : theme.palette.grey[300],
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const userTypeOptions = [
  { value: "internal", label: "Internal" },
  { value: "external", label: "External" },
];

export default function ManageUser({
  clients,
  roles,
  designationOptions,
  userRoleDetails,
}) {
  const [userType, setUserType] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [accessGroup, setAccessGroup] = useState({});
  const [designation, setDesignation] = useState("");
  const [designationId, setDesignationId] = useState("");

  const [selectedTenants, setSelectedTenants] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [internalUsersData, setInternalUsersData] = useState([]);
  const [externalClientName, setExternalClientName] = useState("");
  const [tenantPropertiesCache, setTenantPropertiesCache] = useState({});
  const [propertiesData, setPropertiesData] = useState([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [propertyRolesList, setPropertyRolesList] = useState([]);
  const [selectedPropertyForRole, setSelectedPropertyForRole] = useState(null);
  const [propertyBasedRole, setPropertyBasedRole] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [scope, setScope] = useState({});
  const [assignedDashboards, setAssignedDashboards] = useState([]);
  const [availableDashboards, setAvailableDashboards] = useState([]);
  const [featureAccess, setFeatureAccess] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userApiData, setUserApiData] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [features, setFeatures] = useState([]);
  const [defaultDashboards, setDefaultDashboards] = useState([]);
  const [defaultProperties, setDefaultProperties] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userApiData?.user_scope?.length > 0 && defaultProperties.length > 0) {
      const userProperties = userApiData.user_scope
        .map((scope) => {
          const matchedProperty = defaultProperties.find(
            (prop) => prop.property_id === scope.property_id
          );
          if (matchedProperty) {
            return {
              value: matchedProperty.property_id,
              label: matchedProperty.property_name,
              displayLabel: `${matchedProperty.property_name} - ${matchedProperty.city} - ${matchedProperty.tenant_name}`,
              tenant_id: matchedProperty.tenant_id,
              city: matchedProperty.city,
            };
          }
          return null;
        })
        .filter(Boolean);
      setSelectedProperties(userProperties);
    }
  }, [userApiData, defaultProperties]);

  // useEffect(() => {
  //   if (
  //     userApiData?.property_access &&
  //     userApiData.property_access.length > 0 &&
  //     propertiesData.length > 0 &&
  //     !initialPropertyRolesLoaded
  //   ) {
  //     const propertyRoles = userApiData.property_access
  //       .map((scope) => {
  //         const matchedRole = roles.find((role) => role.id === scope.role_id)
  //         const matchedProperty = propertiesData.find((prop) => prop.property_id === scope.property_id)

  //         if (matchedRole && matchedProperty) {
  //           const propertyRole = {
  //             propertyId: scope.property_id,
  //             propertyName: `${matchedProperty.property_name} - ${matchedProperty.city} - ${matchedProperty.tenant_name}`,
  //             roleId: scope.role_id,
  //             roleName: matchedRole.name,
  //           }

  //           return propertyRole
  //         }

  //         return null
  //       })
  //       .filter(Boolean)

  //     setPropertyRolesList(propertyRoles)
  //     setInitialPropertyRolesLoaded(true)
  //   }
  // }, [userApiData, propertiesData, roles, initialPropertyRolesLoaded])
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: "120px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "120px",
    }),
    control: (provided) => ({
      ...provided,
      "&:hover": {
        border: "1.25px solid #556",
      },
    }),
  };

  const propertySelectStyles = {
    ...customStyles,
  };

  const getPropertiesForTenants = async (tenantIds, userTenant = false) => {
    setIsLoadingProperties(true);
    try {
      const newPropertiesToFetch = [];
      const cachedProperties = [];

      for (const id of tenantIds) {
        if (tenantPropertiesCache[id]) {
          cachedProperties.push(...tenantPropertiesCache[id]);
        } else {
          newPropertiesToFetch.push(id);
        }
      }

      let allProperties = [...cachedProperties];

      if (newPropertiesToFetch.length > 0) {
        const promises = newPropertiesToFetch.map((id) =>
          axios.get(`/get_client_properties/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
            },
          })
        );

        const responses = await Promise.all(promises);
        const newProperties = responses.flatMap((response, index) => {
          if (response.status === 200 && response.data) {
            const tenantId = newPropertiesToFetch[index];
            const properties = Object.entries(response.data).flatMap(
              ([cityName, cityData]) =>
                cityData.properties.map((prop) => ({
                  property_id: prop.property_id,
                  property_name: prop.property_name,
                  city: cityName,
                  tenant_id: prop.tenant_id || tenantId,
                  tenant_name: prop.tenant_name,
                  is_active: prop.is_active,
                  room_types: prop.room_types || {},
                }))
            );

            setTenantPropertiesCache((prev) => ({
              ...prev,
              [tenantId]: properties,
            }));

            return properties;
          }
          return [];
        });

        allProperties = [...cachedProperties, ...newProperties];
      }
      if (userTenant) {
        setDefaultProperties(allProperties);
      }
      setPropertiesData(allProperties);

      // if (userApiData?.user_scope?.length > 0 && allProperties.length > 0) {
      //   const userProperties = userApiData.user_scope
      //     .map((scope) => {
      //       const matchedProperty = allProperties.find((prop) => prop.property_id === scope.property_id)
      //       if (matchedProperty) {
      //         return {
      //           value: matchedProperty.property_id,
      //           label: matchedProperty.property_name,
      //           displayLabel: `${matchedProperty.property_name} - ${matchedProperty.city} - ${matchedProperty.tenant_name}`,
      //           tenant_id: matchedProperty.tenant_id,
      //           city: matchedProperty.city,
      //         }
      //       }
      //       return null
      //     })
      //     .filter(Boolean)
      //   setSelectedProperties(userProperties)
      // }
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/login");
      }
      console.error("Error fetching properties:", err);
      toast.error("Failed to fetch properties!");
    } finally {
      setIsLoadingProperties(false);
    }
  };

  const getPropertyOptions = () => {
    return (propertiesData || []).map((prop) => ({
      value: prop.property_id,
      label: `${prop.property_name}`,
      displayLabel: `${prop.property_name} - ${prop.city} - ${prop.tenant_name}`,
      displayLabelForProperty: `${prop.property_name}`,
      tenant_id: prop.tenant_id,
      tenant_name: prop.tenant_name,
      city: prop.city,
    }));
  };

  const handleTenantsChange = (selectedOptions) => {
    setSelectedPropertyForRole(null);
    setPropertyBasedRole(null);
    const options = selectedOptions || [];

    if (userType === "external") {
      const selectedOption = Array.isArray(options) ? options[0] : options;
      const singleOption = selectedOption ? [selectedOption] : [];
      setSelectedTenants(singleOption);

      if (selectedOption) {
        setSelectedClient({
          value: selectedOption.value,
          label: selectedOption.label,
        });
        getPropertiesForTenants([selectedOption.value]);
      } else {
        setSelectedClient(null);
        setSelectedProperties([]);
        setPropertiesData([]);
      }
      return;
    }

    setSelectedTenants(options);
    setSelectedClients(
      options.map((opt) => ({
        value: opt.value,
      }))
    );

    if (!options.length) {
      setSelectedProperties([]);
      setPropertiesData([]);
      setSelectedClient(null);
      setSelectedClients([]);
      setPropertyRolesList([]);
      setScope({});
      return;
    }

    const tenantIds = options.map((opt) => opt.value);
    setSelectedProperties((prev) =>
      (prev || []).filter((prop) => tenantIds.includes(prop.tenant_id))
    );
    setPropertyRolesList((prevList) => {
      const validProperties = propertiesData.filter((prop) =>
        tenantIds.includes(prop.tenant_id)
      );
      const validPropertyIds = validProperties.map((prop) => prop.property_id);
      return prevList.filter((item) =>
        validPropertyIds.includes(item.propertyId)
      );
    });
    getPropertiesForTenants(tenantIds);
  };

  const handleUserTypeChange = async (event) => {
    const utype = event.target.value;
    setUserType(utype);
    setSelectedUser(null);
    setSearchClicked(false);
    setExternalClientName("");

    if (utype === "internal") {
      if (internalUsersData.length === 0) {
        try {
          const response = await axios.get(`/get_all_users/${utype}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
            },
          });

          const formattedUsers = response.data.map((userArray) => ({
            id: userArray[0],
            name: `${userArray[1]}`,
          }));

          setInternalUsersData(formattedUsers);
          setUsersList(formattedUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
          if (error.response?.status === 401) {
            navigate("/login");
          }
          setUsersList([]);
          setInternalUsersData([]);
        }
      } else {
        setUsersList(internalUsersData);
      }
    } else {
      setUsersList([]);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser({
      id: user.id,
      name: user.name,
      isActive: true,
    });
    if (!searchClicked) {
      setUserApiData(null);
      setAccessGroup({});
      setDesignation("");
      setDesignationId("");
      setSelectedTenants([]);
      setSelectedProperties([]);
      setFirstName("");
      setLastName("");
    }
  };

  const handleSave = async () => {
    if (!firstName || !lastName) {
      toast.error("First name and last name are required!");
      return;
    }
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    if (specialCharRegex.test(firstName) || specialCharRegex.test(lastName)) {
      toast.error(
        "First name and last name should not contain special characters!"
      );
      return;
    }

    if (!designationId && !userApiData?.user_designation_id) {
      toast.error("Please select a designation!");
      return;
    }

    if (!selectedRole && !userApiData?.user_role_id) {
      toast.error("Please select an access group!");
      return;
    }

    if (selectedTenants.length === 0) {
      toast.error("Please select at least one tenant!");
      return;
    }

    if (selectedProperties.length === 0) {
      toast.error("Please select at least one property!");
      return;
    }

    if (assignedDashboards.length == 0) {
      toast.error("Please assign at least one dashboard!");
      return;
    }
    setIsSaving(true);
    const formattedData = {
      // Basic user info with preselected values
      firstName: firstName || userApiData?.first_name,
      lastName: lastName || userApiData?.last_name,
      userRole: selectedRole
        ? Number(selectedRole.id)
        : Number(userApiData?.user_role_id),
      userDesignation: designationId || userApiData?.user_designation_id,
      is_active: selectedUser.isActive ? 1 : 0,
      // Selected clients with preselected values
      selectedClients:
        userType === "internal"
          ? selectedClients.length > 0
            ? selectedClients.map((client) => ({
                value: Number(client.value),
              }))
            : userApiData?.assigned_clients?.map((client) => ({
                value: Number(client.client_id),
              })) || []
          : selectedClient
          ? [{ value: Number(selectedClient.value) }]
          : userApiData?.assigned_clients?.map((client) => ({
              value: Number(client.client_id),
            })) || [],

      // Dashboard IDs with preselected values
      dashboards: assignedDashboards.map((d) => Number(d.value)),

      // Property access settings with preselected values
      property_access:
        propertyRolesList.map((item) => ({
          property_id: Number(item.propertyId),
          role_id: Number(item.roleId),
        })) || [],

      // Feature privileges with preselected values
      features:
        featureAccess.map(({ functionality_id, privilege }) => ({
          feature_id: Number(functionality_id),
          privilege: Number(privilege) || 5,
        })) || [],

      // User scope with preselected values
      scope:
        Object.keys(scope).length > 0
          ? Object.entries(scope).reduce((acc, [clientId, cities]) => {
              acc[clientId] = cities;
              return acc;
            }, {})
          : userApiData?.user_scope?.reduce((acc, item) => {
              if (!acc[item.client_id]) {
                acc[item.client_id] = {};
              }
              if (!acc[item.client_id][item.city]) {
                acc[item.client_id][item.city] = [];
              }
              acc[item.client_id][item.city].push(item.property_id);
              return acc;
            }, {}) || {},
    };
    try {
      await axios.put(`/edit_user/${selectedUser.id}`, formattedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      // console.log(
      //   "Create User Payload:",
      //   JSON.stringify(
      //     {
      //       ...formattedData,
      //     },
      //     null,
      //     2,
      //   ),
      // )
      toast.success("User updated successfully!");
      setTimeout(async () => {
        await handleSearchUser();
      }, 500);
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      console.error("Error updating user:", error);
      toast.error("Failed to update user!");
    } finally {
      setIsSaving(false);
    }
  };

  const isSelectionComplete = userType && selectedUser;

  const rolesOptions = roles.map((role) => ({
    value: role.id,
    label: role.name,
  }));
  function updatePrivileges(referenceArray, featureArray) {
    const featureMap = {};

    featureArray.forEach((item) => {
      featureMap[item.functionality_id] = {
        privilege: item.privilege,
        is_reset: item.reset,
      };
    });

    const updatedArray = referenceArray.map((reference) => {
      if (featureMap.hasOwnProperty(reference.functionality_id)) {
        const newPrivilege =
          featureMap[reference.functionality_id]["privilege"];
        const defaultReset = featureMap[reference.functionality_id]["is_reset"];
        const isModified = reference.privilege !== newPrivilege;

        return {
          ...reference,
          originalPrivilege: reference.privilege,
          privilege: newPrivilege,
          is_reset: isModified ? 1 : 0,
          defaultReset: defaultReset,
          defaultPrivilege: newPrivilege,
        };
      }

      return {
        ...reference,
        originalPrivilege: reference.privilege,
        is_reset: 0,
        defaultReset: 0,
        defaultPrivilege: reference.privilege,
      };
    });

    return updatedArray;
  }

  const handleSearchUser = async () => {
    if (!selectedUser?.id) return;
    // setInitialPropertyRolesLoaded(false)
    // // Also reset the propertyRolesList to clear previous user's data
    setPropertyRolesList([]);

    setIsSearchLoading(true);
    try {
      const response = await axios.get(
        `/fetch_user_details/${selectedUser.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      setUserApiData(response.data);
      setSelectedUser((prev) => ({
        ...prev,
        isActive: response.data.is_active === 1,
      }));
      setFeatures(response.data.features || []);
      setFirstName(response.data.first_name || "");
      setLastName(response.data.last_name || "");
      setSelectedRole(null);

      const userData = response.data;

      if (userData.user_role_id && userRoleDetails?.roles) {
        const userRole = userRoleDetails.roles.find(
          (role) => role.user_role_id === userData.user_role_id
        );
        if (userRole?.dashboards) {
          const dashboardOptions = userRole.dashboards.map((dashboard) => ({
            value: dashboard.dashboard_id,
            label: dashboard.dashboard_name,
          }));
          setAvailableDashboards(dashboardOptions);
          const preselectedDashboards =
            userData.dashboards
              ?.filter((dashboard) => dashboard.assigned)
              .map((dashboard) => ({
                value: dashboard.dashboard_id,
                label:
                  dashboardOptions.find(
                    (opt) => opt.value === dashboard.dashboard_id
                  )?.label || "",
              }))
              .filter((dashboard) => dashboard.label) || [];
          setAssignedDashboards(preselectedDashboards);
          setDefaultDashboards(preselectedDashboards);
        }
      }
      setAccessGroup({
        id: userData.user_role_id,
        name: userData.user_role_name,
      });
      setDesignation({
        id: userData.user_designation_id,
        name: userData.user_designation_name,
      });
      setDesignationId(userData.user_designation_id);

      const assignedClients = userData.assigned_clients.map((client) => ({
        value: client.client_id,
        label: client.client_name,
      }));
      setSelectedTenants(assignedClients);

      if (userData.user_role_id && userRoleDetails?.roles) {
        const userRole = userRoleDetails.roles.find(
          (role) => role.user_role_id === userData.user_role_id
        );
        const updatedFeatureArray = updatePrivileges(
          userRole.functionalities,
          userData.features
        );
        setFeatureAccess(updatedFeatureArray);
      }

      if (assignedClients.length > 0) {
        const tenantIds = assignedClients.map((client) => client.value);
        await getPropertiesForTenants(tenantIds, true);
      }

      setSearchClicked(true);
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      console.error("Error fetching user details:", error);
      toast.error("Failed to fetch user details!");
      setUserApiData(null);
    } finally {
      setIsSearchLoading(false);
    }
  };
  useEffect(() => {
    if (
      userApiData &&
      userApiData.user_scope &&
      userApiData.user_scope.length > 0
    ) {
      const newPropertyRolesList = userApiData.property_access
        .map((scope) => {
          const matchedProperty = defaultProperties.find(
            (prop) => prop.property_id === scope.property_id
          );
          const matchedRole = roles.find((role) => role.id === scope.role_id);
          // console.log(matchedRole)
          if (matchedProperty && matchedRole) {
            return {
              propertyId: scope.property_id,
              propertyName: `${matchedProperty.property_name} - ${matchedProperty.city} - ${matchedProperty.tenant_name}`,
              roleId: scope.role_id,
              roleName: matchedRole.name,
            };
          }
          return null;
        })
        .filter(Boolean);
      setPropertyRolesList(newPropertyRolesList);
    }
    // }, [userDetails, propertiesData])
  }, [defaultProperties, userApiData]);

  const handleAccessGroupChange = (option) => {
    if (option) {
      setSelectedRole(option);
      setAccessGroup(option);

      // Update available dashboards based on selected role
      if (userRoleDetails?.roles) {
        const userRole = userRoleDetails.roles.find(
          (role) => role.user_role_id === option.id
        );
        if (userRole?.dashboards) {
          const dashboardOptions = userRole.dashboards.map((dashboard) => ({
            value: dashboard.dashboard_id,
            label: dashboard.dashboard_name,
          }));
          setAvailableDashboards(dashboardOptions);

          const available = dashboardOptions.filter((dash) => {
            // Check if any object in defaultDashboards has a matching value
            return defaultDashboards.some(
              (defaultDash) => defaultDash.value === dash.value
            );
          });

          setAssignedDashboards(available);
          const updatedFeatures = updatePrivileges(
            userRole.functionalities,
            features
          );
          setFeatureAccess(updatedFeatures);
        }
      }
    }
  };

  const handleDesignationChange = (option) => {
    if (option) {
      setDesignationId(option.id);
      setDesignation(option);
      // Store the full designation object if needed for reference
      setUserApiData((prev) => ({
        ...prev,
        user_designation_name: option.name,
      }));
    }
  };

  const handlePropertiesChange = (opt) => {
    const selectedOpts = opt || [];
    setSelectedProperties(selectedOpts);
    if (
      selectedPropertyForRole &&
      !selectedOpts.some((prop) => prop.value === selectedPropertyForRole.value)
    ) {
      setSelectedPropertyForRole(null);
    }
    const selectedPropertyIds = selectedOpts.map((prop) => prop.value);
    setPropertyRolesList((prevList) =>
      prevList.filter((item) => selectedPropertyIds.includes(item.propertyId))
    );
    const newScope = {};
    selectedOpts.forEach((property) => {
      const tenantId = property.tenant_id;
      const city = property.city || "default";
      if (!newScope[tenantId]) {
        newScope[tenantId] = {};
      }
      if (!newScope[tenantId][city]) {
        newScope[tenantId][city] = [];
      }
      newScope[tenantId][city].push(property.value);
    });
    setScope(newScope);
  };
  const handleSelectedClient = async (selectedOption) => {
    setExternalClientName(selectedOption?.value || "");
    setSelectedUser(null);
    try {
      const response = await axios.get(
        `/fetch_external_users/${selectedOption?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      const formattedUsers = response.data.map((userArray) => ({
        id: userArray[0],
        name: userArray[1],
      }));
      setUsersList(formattedUsers);
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
      }
      console.error("Error fetching external users:", error);
      setUsersList([]);
      setUserApiData(null);
    }
  };

  const handleAddPropertyRole = () => {
    const selectedProperty = selectedPropertyForRole;
    const selectedRole = roles.find(
      (opt) => opt.id === propertyBasedRole.value
    );

    if (selectedProperty && selectedRole) {
      const isDuplicate = propertyRolesList.some(
        (item) => item.propertyId === selectedProperty.value
      );

      if (isDuplicate) {
        toast.error("This property already has a role assigned!");
        return;
      }

      setPropertyRolesList([
        ...propertyRolesList,
        {
          propertyId: selectedProperty.value,
          propertyName: selectedProperty.displayLabel,
          roleId: selectedRole.id,
          roleName: selectedRole.name,
        },
      ]);
      setSelectedPropertyForRole(null);
      setPropertyBasedRole(null);
    }
  };
  return (
    <Box sx={{ width: "100%", px: 2 }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 3 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            placeholder="Select User Type"
            styles={customStyles}
            value={userTypeOptions.find((opt) => opt.value === userType)}
            onChange={(option) =>
              handleUserTypeChange({ target: { value: option?.value || "" } })
            }
            options={userTypeOptions}
            className="w-100"
          />
        </FormControl>

        {userType === "external" && (
          <>
            <FormControl sx={{ minWidth: 200 }}>
              <Select
                placeholder="Select Client"
                styles={customStyles}
                value={clients?.find(
                  (client) => client.value === externalClientName
                )}
                onChange={handleSelectedClient}
                options={clients || []}
                className="w-100"
              />
            </FormControl>

            <FormControl sx={{ minWidth: 200 }}>
              <Select
                placeholder="Select User"
                styles={customStyles}
                value={usersList.find((u) => u.id === selectedUser?.id) || null}
                onChange={(option) => {
                  if (option) handleUserSelect(option);
                }}
                options={usersList}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id.toString()}
                isDisabled={userType === "external" && !externalClientName}
                className="w-100"
              />
            </FormControl>
          </>
        )}

        {userType !== "external" && (
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              placeholder="Select User"
              styles={customStyles}
              value={usersList.find((u) => u.id === selectedUser?.id) || null}
              onChange={(option) => {
                if (option) handleUserSelect(option);
              }}
              options={usersList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id.toString()}
              isDisabled={userType === "external" && !externalClientName}
              className="w-100"
            />
          </FormControl>
        )}

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Button
            variant="contained"
            onClick={handleSearchUser}
            disabled={isSearchLoading || !userType || !selectedUser}
            sx={{
              bgcolor: "black",
              "&:hover": {
                bgcolor: "#333",
              },
            }}
          >
            {isSearchLoading ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                Loading...
              </Box>
            ) : (
              "Search"
            )}
          </Button>

          {selectedUser && searchClicked && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mx: 1 }}>Inactive</Typography>
              <IconButton
                onClick={() =>
                  setSelectedUser({
                    ...selectedUser,
                    isActive: !selectedUser.isActive,
                  })
                }
              >
                <IOSSwitch checked={selectedUser.isActive} />
              </IconButton>
              <Typography>Active</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {isSelectionComplete && searchClicked && !isSearchLoading && (
        <Paper sx={{ mb: 3, p: 3, borderRadius: "12px" }}>
          <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
            <FormControl fullWidth>
              <label className="form-label d-flex align-items-center">
                First Name
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter First Name"
              />
            </FormControl>
            <FormControl fullWidth>
              <label className="form-label d-flex align-items-center">
                Last Name
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter Last Name"
              />
            </FormControl>
          </Box>
          <Box sx={{ mb: 3, display: "flex", gap: 2 }}>
            <FormControl fullWidth>
              <label className="form-label d-flex align-items-center">
                Access Group
                <span className="text-danger">*</span>
              </label>
              <Select
                placeholder="Access Group"
                styles={customStyles}
                value={accessGroup}
                onChange={handleAccessGroupChange}
                options={roles}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id.toString()}
                className="w-100"
              />
            </FormControl>

            <FormControl fullWidth>
              <label className="form-label d-flex align-items-center">
                Designation
                <span className="text-danger">*</span>
              </label>
              <Select
                placeholder="Designation"
                styles={customStyles}
                value={designation}
                onChange={handleDesignationChange}
                options={designationOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id.toString()}
                className="w-100"
              />
            </FormControl>
          </Box>
        </Paper>
      )}

      {isSelectionComplete && searchClicked && !isSearchLoading && (
        <Paper sx={{ mb: 3, p: 3, borderRadius: "12px" }}>
          <div className="w-100 row mb-2">
            <div className="col-10">
              <h6 className="bold text-orange">
                <i className="fa-solid fa-building mx-2 grey"></i> Tenant &
                Property
              </h6>
            </div>
          </div>
          <div className="row">
            {!userType ? (
              <div className="p-2">
                <Alert severity="info">
                  Select a user type in the section above to assign tenants and
                  properties
                </Alert>
              </div>
            ) : (
              <>
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label d-flex align-items-center">
                        {userType === "external"
                          ? "Assign Tenant"
                          : "Assign Tenants"}
                        <span className="text-danger">*</span>
                        <Tooltip
                          title={`Select the tenant organization${
                            userType === "internal" ? "s" : ""
                          } this user belongs to`}
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <Select
                        placeholder="Select Tenants"
                        styles={customStyles}
                        isMulti={userType === "internal"}
                        value={selectedTenants}
                        onChange={handleTenantsChange}
                        className="w-100"
                        options={clients}
                        isDisabled={userType === "external"}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label className="form-label d-flex align-items-center">
                        Assign Property
                        <span className="text-danger">*</span>
                        <Tooltip
                          title="Select the properties this user will have access to"
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <Select
                        placeholder="Select Properties"
                        styles={propertySelectStyles}
                        isMulti
                        value={selectedProperties || []}
                        onChange={handlePropertiesChange}
                        className="w-100"
                        options={getPropertyOptions()}
                        isLoading={isLoadingProperties}
                        isDisabled={
                          !selectedTenants?.length || isLoadingProperties
                        }
                        formatOptionLabel={({ displayLabel }) =>
                          displayLabel || ""
                        }
                        noOptionsMessage={() =>
                          !selectedTenants?.length
                            ? "Please select a tenant first"
                            : "No properties available"
                        }
                      />
                    </div>
                  </div>
                </div>
                <Divider
                  sx={{
                    my: 1 /* Increased vertical margin */,
                    borderColor: "rgba(0,0,0,0.15)",
                    borderBottomWidth: 1 /* Increased thickness */,
                    width: "100%",
                    opacity: 0.8,
                  }}
                />
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label d-flex align-items-center">
                        Property Name
                        <Tooltip title="Select a property" placement="right">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <Select
                        placeholder="Select Property"
                        styles={propertySelectStyles}
                        value={selectedPropertyForRole || null}
                        onChange={(opt) =>
                          setSelectedPropertyForRole(opt ? opt : "")
                        }
                        className="w-100"
                        options={getPropertyOptions().filter((option) =>
                          selectedProperties.some(
                            (selectedProp) =>
                              selectedProp.value === option.value
                          )
                        )}
                        isDisabled={!selectedTenants?.length}
                        formatOptionLabel={({ displayLabelForProperty }) =>
                          displayLabelForProperty || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label d-flex align-items-center">
                        Property Based Role
                        <Tooltip
                          title="Select the role for this property"
                          placement="right"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <Select
                        placeholder="Select Role"
                        styles={customStyles}
                        value={propertyBasedRole}
                        onChange={(opt) => setPropertyBasedRole(opt)}
                        className="w-100"
                        options={rolesOptions}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <Button
                      variant="contained"
                      disabled={!selectedPropertyForRole || !propertyBasedRole}
                      onClick={handleAddPropertyRole}
                      sx={{
                        backgroundColor:
                          !selectedPropertyForRole || !propertyBasedRole
                            ? "#E0E0E0"
                            : "#FF8A00",
                        color:
                          !selectedPropertyForRole || !propertyBasedRole
                            ? "#9E9E9E"
                            : "white",
                        "&:hover": {
                          backgroundColor:
                            !selectedPropertyForRole || !propertyBasedRole
                              ? "#E0E0E0"
                              : "#ff9d2f",
                        },
                        transition: "all 0.3s ease",
                        fontWeight: "500",
                        textTransform: "none",
                        boxShadow:
                          !selectedPropertyForRole || !propertyBasedRole
                            ? "none"
                            : "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      Add Property Role
                    </Button>
                  </div>
                </div>
                {propertyRolesList.length > 0 && (
                  <Paper variant="outlined" sx={{ p: 3 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Assigned Properties and Roles
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}
                    >
                      {propertyRolesList.map((item, index) => (
                        <Chip
                          key={index}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography component="span">
                                {item.propertyName}
                              </Typography>
                              <Typography component="span" sx={{ mx: 0.5 }}>
                                -
                              </Typography>
                              <Typography
                                component="span"
                                color="text.secondary"
                                sx={{ fontWeight: "bold" }}
                              >
                                {item.roleName}
                              </Typography>
                            </Box>
                          }
                          onDelete={() => {
                            setPropertyRolesList(
                              propertyRolesList.filter((_, i) => i !== index)
                            );
                          }}
                          sx={{
                            padding: "4px",
                            "& .MuiChip-label": {
                              display: "flex",
                              alignItems: "center",
                              px: 1,
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Paper>
                )}
              </>
            )}
          </div>
        </Paper>
      )}

      {selectedUser && searchClicked && !isSearchLoading && (
        <Paper sx={{ mb: 3, p: 3, borderRadius: "12px" }}>
          <div className="w-100 row mb-2">
            <div className="col-10">
              <h6 className="bold text-orange">
                <i className="fa-solid fa-chart-line mx-2 grey"></i> Assigned
                Dashboards
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label className="form-label d-flex align-items-center">
                  Select Dashboards
                  <span className="text-danger">*</span>
                  <Tooltip
                    title="Select the dashboards this user will have access to"
                    placement="right"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <Select
                  placeholder="Select Dashboards"
                  styles={customStyles}
                  isMulti
                  value={assignedDashboards}
                  onChange={(selected) => setAssignedDashboards(selected || [])}
                  options={availableDashboards}
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </Paper>
      )}

      {selectedUser && searchClicked && !isSearchLoading && (
        <Paper sx={{ mb: 3, p: 3, borderRadius: "12px" }}>
          <div className="w-100 row mb-2">
            <div className="col-10">
              <h6 className="bold text-orange d-flex align-items-center">
                <i className="fa-solid fa-shield-halved mx-2 grey"></i>{" "}
                Functionality Access Levels
                <Tooltip
                  title="Set specific access permissions for each functionality. Select the appropriate access level using the radio buttons."
                  placement="right"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FeatureAccessTable
                features={featureAccess}
                setFeatureAccess={setFeatureAccess}
                mode="edit"
              />
            </div>
          </div>
        </Paper>
      )}

      {isSelectionComplete && searchClicked && !isSearchLoading && (
        <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={isSaving}
            sx={{
              minWidth: 150,
              bgcolor: "black",
              "&:hover": {
                bgcolor: "#333",
              },
            }}
          >
            {isSaving ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                Saving...
              </Box>
            ) : (
              "Save Changes"
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
}
