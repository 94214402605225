import { useEffect,useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import BaseLayout from "../../components/common/Layout/BaseLayout";
import { AppProvider } from "../../context/AppContext";
import isAuthenticated, { getCookie } from "../../services/auth.js";
import { isValidRoute } from "../../Auth";

import Dashboard from "./Dashboard";
import DashboardSessionTracker from "../../components/common/SessionTracker";
import useUserStore from "../../context/UserContext";

const UsageTracker = ({ userCount, connectedUsers }) => {
  // const dashboardId = useUserStore((state) => state.dashboardId);
  const [dashboardId,setDashboardId] = useState("")
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const location = useLocation(); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };
  
    fetchId();
  }, []);
  
  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {
    checkRoute();
  }, [dashboardId]); 

  return (
    <AppProvider>
      {/* Only render DashboardSessionTracker when dashboardId is available */}
      {dashboardId && <DashboardSessionTracker dashboardId={dashboardId} />}

      <BaseLayout>
        <Routes>
          <Route path="dashboard" element={<Dashboard connectedUsers={connectedUsers} userCount={userCount} />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  );
};

export default UsageTracker;
