import { useEffect,useState} from 'react'
import { BrowserRouter, Routes, Route , useNavigate,useLocation} from 'react-router-dom';
import BaseLayout from '../../components/common/Layout/BaseLayout'
import isAuthenticated, {getCookie} from '../../services/auth.js'
import { isValidRoute } from '../../Auth';
import {AppProvider} from '../../context/AppContext';

import CompetitivePricing from './CompetitivePricing';

import '../../assets/styles/admin.css';
import '../../assets/styles/master.css';
import '../../assets/styles/base.css';
import DashboardSessionTracker from "../../components/common/SessionTracker"
import useUserStore from '../../context/UserContext';

const Master = ({ match }) => {
  const [dashboardId,setDashboardId] = useState("")
  const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);

  const location = useLocation(); 
  const navigate = useNavigate();
  useEffect(() => {
    const fetchId = async () => {
      if (!dashboardId) {
        let id = await fetchDashboardId(location.pathname);
        setDashboardId(id);
      }
    };
  
    fetchId();
  }, []);
  
  const checkRoute = async () => {
    if (dashboardId) {
      const res = await isValidRoute(dashboardId);
      if (!res["valid"]) {
        navigate(res["redirect"]);
      }
    }
  };
  useEffect(() => {
    checkRoute();
  }, [dashboardId]); 

  return(
    <AppProvider>
                <DashboardSessionTracker dashboardId={2}> </DashboardSessionTracker>
      <BaseLayout>
        <Routes>
            <Route path="dashboard" element={<CompetitivePricing />} />
        </Routes>
      </BaseLayout>
    </AppProvider>
  )
};

export default Master;