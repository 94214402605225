import { Box, Typography, Button, Paper, TextField, IconButton, TableContainer, LinearProgress } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { Add as AddIcon, Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import { useEffect, useState, useCallback } from "react"
import AssignedUsersDesignationDialog from "./AssignedUsersDesignationDialog"
import { DataGrid } from "@mui/x-data-grid"
import CreateDesignationDialog from "./CreateDesignationDialog"
import EditDesignationDialog from "./EditDesignationDialog"
import { Switch } from "@mui/material"
import axios from "axios"
import { fetchToken } from "../../Auth"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: "20px",
  boxShadow: "none",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  overflow: "hidden",
}))

const SearchField = styled(TextField)({
  width: "250px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
  },
})

const CreateButton = styled(Button)({
  backgroundColor: "#1a1a1a",
  color: "white",
  borderRadius: "8px",
  textTransform: "none",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "#333",
  },
})

const TotalRolesBox = styled(Paper)({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  marginBottom: "20px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
})

const TotalRolesNumber = styled(Typography)({
  fontSize: "32px",
  color: "#FF8A00",
  lineHeight: 1,
})

const TotalRolesText = styled(Typography)({
  fontSize: "18px",
  fontWeight: 600,
  color: "#666",
  marginBottom: "4px",
})

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "20px",
})

const RightSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  alignItems: "flex-end",
})

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }),
)

const dataGridStyles = {
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "black",
    color: "white",
    fontSize: 12,
    fontWeight: 300,
  },
  "& .MuiDataGrid-columnHeader": {
    whiteSpace: "normal",
    lineHeight: "normal",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "20px",
  },
  "& .MuiDataGrid-sortIcon": {
    color: "white",
  },
  "& .MuiDataGrid-menuIcon": {
    display: "none",
  },
 
}

export default function ManageDesignation() {
  const navigate = useNavigate()
  const [designationDetails, setDesignationDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [selectedRoleForEdit, setSelectedRoleForEdit] = useState(null)
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [filteredRows, setFilteredRows] = useState([])

  // Fetch designation details
  const getDesignationDetails = useCallback(async () => {
    setLoading(true)
    try {
      const token = fetchToken("zetta_access_token")
      if (!token) {
        navigate("/login")
        return
      }

      const response = await axios.get("/get_user_designation_detail", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 200 && response.data !== undefined) {
        setDesignationDetails(response.data)
      }
    } catch (err) {
      console.error("Error fetching designation details:", err)
      if (err.response?.status === 401) {
        navigate("/login")
      } else {
        toast.error("Failed to load designation details")
      }
    } finally {
      setLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    getDesignationDetails()
  }, [])

  // Handle status toggle
  const handleStatusToggle = useCallback(
    async (roleId, newStatus) => {
      if (!newStatus) {
        // Find the designation in the data
        const designation = designationDetails?.designations?.find((item) => item.user_designation_id === roleId)

        // If users are assigned, show toast and prevent status change
        if (designation && Number.parseInt(designation.user_count) > 0) {
          toast.warning("Cannot disable the designation if users are assigned to it")
          return
        }
      }

      try {
        const token = fetchToken("zetta_access_token")
        if (!token) {
          navigate("/login")
          return
        }

        const response = await axios.put(
          `/update_user_designation_status/${roleId}`,
          {
            is_active: newStatus ? 1 : 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (response.data.success) {
          // Update only the specific row in the local state instead of refetching
          setDesignationDetails((prevState) => {
            const updatedDesignations = prevState.designations.map((item) => {
              if (item.user_designation_id === roleId) {
                return {
                  ...item,
                  is_active: newStatus ? 1 : 0,
                  updated_at: response.data.updated_at.updated_at,
                  last_updated_by_name: response.data.updated_at.updated_by,
                }
              }
              return item
            })

            return {
              ...prevState,
              designations: updatedDesignations,
            }
          })

          toast.success(newStatus ? "Designation activated" : "Designation deactivated")
        } else {
          toast.warning(response.data.message)
        }
      } catch (error) {
        console.error("Error updating role status:", error)
        if (error.response?.status === 403) {
          toast.warning("Access Denied: Insufficient Permissions")
        } else if (error.response?.status === 404) {
          toast.warning("User role not found")
        } else {
          toast.warning(error.response?.data?.message || "Failed to update role status")
        }
      }
    },
    [designationDetails, navigate],
  )

  // Format date for display
  const formatDate = useCallback((dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
  }, [])

  // Dialog handlers
  const handleOpenDialog = useCallback(
    (role) => {
      const selectedDesignation = designationDetails?.designations?.find(
        (designation) => designation.user_designation_id === role.id,
      )

      setSelectedRole({
        ...role,
        users: selectedDesignation?.users || [],
      })
      setIsDialogOpen(true)
    },
    [designationDetails],
  )

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false)
    setSelectedRole(null)
  }, [])

  const handleOpenEditDialog = useCallback(
    (role) => {
      const selectedDesignation = designationDetails?.designations?.find(
        (designation) => designation.user_designation_id === role.id,
      )

      setSelectedRoleForEdit({
        id: selectedDesignation?.user_designation_id,
        role: selectedDesignation?.user_designation_name,
      })
      setIsEditDialogOpen(true)
    },
    [designationDetails],
  )

  const handleCloseEditDialog = useCallback(() => {
    setIsEditDialogOpen(false)
    setSelectedRoleForEdit(null)
  }, [])

  const handleOpenCreateDialog = useCallback(() => {
    setIsCreateDialogOpen(true)
  }, [])

  const handleCloseCreateDialog = useCallback(() => {
    setIsCreateDialogOpen(false)
  }, [])

  useEffect(() => {
    setColumns([
      {
        field: "role",
        headerName: "User Designation",
        flex: 1,
        sortable: true,
      },
      {
        field: "assignedUsers",
        headerName: "Assigned Users",
        flex: 0.5,
        sortable: true,
        renderCell: (params) => (
          <Button
            onClick={() => handleOpenDialog(params.row)}
            sx={{
              color: "#1a1a1a",
              textDecoration: "underline",
              minWidth: "auto",
              padding: "0",
              transition: "all 0.2s ease",
              "&:hover": {
                background: "none",
                textDecoration: "underline",
                color: "#FF8A00",
                transform: "translateY(-1px)",
              },
            }}
            disabled={params.value === "0"}
          >
            {params.value}
          </Button>
        ),
      },
      {
        field: "edit",
        headerName: "Edit",
        flex: 0.5,
        sortable: false,
        renderCell: (params) => (
          <IconButton
            onClick={() => handleOpenEditDialog(params.row)}
            sx={{
              backgroundColor: "black",
              padding: "6px",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            <EditIcon sx={{ color: "white", fontSize: "18px" }} />
          </IconButton>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IOSSwitch
              checked={params.row.isActive === 1}
              onChange={(e) => handleStatusToggle(params.row.id, e.target.checked)}
            />
          </Box>
        ),
      },
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 1,
        sortable: true,
      },
      {
        field: "createdBy",
        headerName: "Created By",
        flex: 1,
        sortable: true,
      },
      {
        field: "lastEditedAt",
        headerName: "Last Edited at",
        flex: 1,
        sortable: true,
      },
      {
        field: "lastEditedBy",
        headerName: "Last Edited by",
        flex: 1,
        sortable: true,
      },
    ])
  }, [handleOpenDialog, handleOpenEditDialog, handleStatusToggle])

  useEffect(() => {
    if (designationDetails?.designations) {
      const newRows = designationDetails.designations.map((item) => ({
        id: item.user_designation_id,
        role: item.user_designation_name,
        isActive: item.is_active,
        assignedUsers: item.user_count.toString(),
        createdAt: formatDate(item.created_at),
        createdBy: item.created_by,
        lastEditedAt: formatDate(item.updated_at),
        lastEditedBy: item.created_by,
      }))
      setRows(newRows)
    } else {
      setRows([])
    }
  }, [designationDetails, formatDate])

  useEffect(() => {
    if (!searchText) {
      setFilteredRows(rows)
    } else {
      const searchLower = searchText.toLowerCase()
      const filtered = rows.filter((row) =>
        Object.values(row).some((value) => value && value.toString().toLowerCase().includes(searchLower)),
      )
      setFilteredRows(filtered)
    }
  }, [rows, searchText])

 
  return (
    <Box sx={{ padding: "16px" }}>
      <HeaderContainer>
        <TotalRolesBox>
          <TotalRolesText>Total Designation</TotalRolesText>
          <TotalRolesNumber>{designationDetails?.designations?.length || 0}</TotalRolesNumber>
        </TotalRolesBox>

        <RightSection>
          <CreateButton variant="contained" startIcon={<AddIcon />} onClick={handleOpenCreateDialog}>
            Create New User Designation
          </CreateButton>
          <SearchField
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  {searchText && (
                    <IconButton size="small" onClick={() => setSearchText("")} sx={{ padding: "2px" }}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                  <SearchIcon color="action" />
                </>
              ),
            }}
          />
        </RightSection>
      </HeaderContainer>

      <StyledTableContainer component={Paper}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          sx={dataGridStyles}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5 ,10,20 ]}
          disableRowSelectionOnClick
          sortingMode="client"
          loading={loading}
          
        />
      </StyledTableContainer>
      {loading &&  <LinearProgress
          sx={{
            backgroundColor: "#FFF0E0", // Lighter background color
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FF8A00", // The actual moving bar color
            },
          }}
        />}

      {selectedRole && (
        <AssignedUsersDesignationDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          roleTitle={selectedRole?.role}
          users={selectedRole?.users || []}
        />
      )}

      {selectedRoleForEdit && (
        <EditDesignationDialog
          open={isEditDialogOpen}
          onClose={handleCloseEditDialog}
          role={selectedRoleForEdit}
          setDesignationDetails={setDesignationDetails}
        />
      )}

      <CreateDesignationDialog
        open={isCreateDialogOpen}
        onClose={handleCloseCreateDialog}
        onSuccess={getDesignationDetails}
      />
    </Box>
  )
}