import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { fetchToken } from "../../Auth";
import { toast } from "react-toastify";

const ClientDashboards = () => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [assignedDashboards, setAssignedDashboards] = useState([]);

  const navigate = useNavigate();

  const getAllClients = async () => {
    try {
      const response = await axios.get(`/fetch_all_clients`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        let temp = [];
        for (let item of response.data) {
          temp.push({ label: item[1], value: item[0] });
        }
        setClients(temp);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getAllDashboards = async () => {
    try {
      const response = await axios.get("/get_active_dashboards", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        //   let temp = response.data.map(item => ({'id': item[0], 'name': item[1], 'url': item[2], 'createdBy': item[4], 'createdAt': item[5], 'updatedAt': item[6], 'status': item[3]}))
        let temp = response.data.map((item) => ({
          id: item.dashboard_id,
          name: item.dashboard_name,
        }));
        setDashboards(temp);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const getDashboards = async () => {
    try {
      const response = await axios.get(`/get_client_dashboards/${client}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        setAssignedDashboards(response.data);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const saveDashboards = async () => {
    if (!assignedDashboards.length) {
      toast.error("Please assign atleast one dashboard!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR,
      });
      return;
    }
    try {
      const response = await axios.post(
        "/update_client_dashboards",
        { client_id: parseInt(client), dashboards: assignedDashboards },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
          },
        }
      );
      if (response.status == 200) {
        toast.success("Dashboards assigned successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      console.error("Error", error);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
        type: toast.TYPE.ERROR,
      });
    }
  };

  const handleChange = (id) => {
    if (assignedDashboards.includes(parseInt(id))) {
      setAssignedDashboards(
        assignedDashboards.filter((item) => parseInt(item) !== parseInt(id))
      );
    } else {
      setAssignedDashboards([...assignedDashboards, parseInt(id)]);
    }
  };

  useEffect(() => {
    if (client) {
      getDashboards();
    }
  }, [client]);

  useEffect(() => {
    getAllClients();
    getAllDashboards();
  }, []);

  return (
    <Grow in={true} {...(true ? { timeout: 700 } : {})}>
      <div className="p-4 white-card">
        <div className="mt-3">
          <div className="bg-grey p-1 py-3 corner w-100 row">
            <div className="col-5">
              <h3 class="dashboard-card-title">Client Dashboards</h3>
            </div>
            <div className="col-7 d-flex justify-content-end align-items-center">
              <i className="grey-2 fa-solid fa-hotel mx-3"></i>
              <select
                onChange={(evt) => setClient(evt.target.value)}
                className="w-50 form-select"
                aria-label="client-select"
              >
                <option selected hidden>
                  Select a Client
                </option>
                {clients.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mt-3">
          {!client ? (
            <Alert severity="info">Select a client to assign dashboards.</Alert>
          ) : (
            <div>
              <h6>Assign dashboards</h6>
              <p className="grey-2 mt-1 small">
                Dashboards not listed below might be inactive. Please make them
                active from the <a href="/tech/home">Tech dashboard</a>.
              </p>

              <div className="mt-4 w-100 row">
                {dashboards.map((item, idx) => (
                  <div key={idx} className="col-3 my-2">
                    <div class="form-check">
                      <input
                        onChange={(evt) => handleChange(evt.target.value)}
                        checked={assignedDashboards.includes(parseInt(item.id))}
                        class="form-check-input"
                        type="checkbox"
                        value={item.id}
                      />
                      <label class="form-check-label">{item.name}</label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-3 w-100 d-flex justify-content-center align-items-center">
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => saveDashboards()}
                >
                  Assign Dashboards
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Grow>
  );
};

export default ClientDashboards;
