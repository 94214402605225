import React from "react";
import { useState, useEffect } from "react";
import DashboardSessionTracker from "../../components/common/SessionTracker";
import Grow from "@mui/material/Grow";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Select from "react-select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { fetchToken,isValidRoute } from "../../Auth";
import "../../assets/styles/base.css";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';
import SyncIcon from '@mui/icons-material/Sync';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TransferDetailsModal from './TransferDetailsModal';
import Skeleton from '@mui/material/Skeleton';
import useUserStore from "../../context/UserContext";



const DataVoyage = () => {
    const navigate = useNavigate();
    const [dashboardId,setDashboardId] = useState("")
    const fetchDashboardId = useUserStore((state) => state.fetchDashboardId);
  
    const location = useLocation(); 
  
    useEffect(() => {
        const fetchId = async () => {
          if (!dashboardId) {
            let id = await fetchDashboardId(location.pathname);
            setDashboardId(id);
          }
        };
      
        fetchId();
      }, []);
      
      const checkRoute = async () => {
        if (dashboardId) {
          const res = await isValidRoute(dashboardId);
          if (!res["valid"]) {
            navigate(res["redirect"]);
          }
        }
      };
    useEffect(() => {
      checkRoute();
    }, [dashboardId]); 


    const [tenants, setTenants] = useState([]);
    const [availableProperties, setAvailableProperties] = useState([]);

    const [selectedTenant, setSelectedTenant] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState("");
    const [transferLimits, setTransferLimits] = useState({})
    const [dayLimit, setDayLimit] = useState(0);

    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedTransfer, setSelectedTransfer] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [transferFrom, setTransferFrom] = useState({
        label: "Production",
        value: "prod",
    });
    const [transferTo, setTransferTo] = useState(null);

    const fromOptions = [
        { label: "Production", value: "prod" },
        { label: "Staging", value: "stage" },
    ];
    const toOptions = [
        { label: "Staging", value: "stage" },
        { label: "Development", value: "dev" },
        // { label: "Production", value: "prod" },
    ];

    const showToast = (message, type = "error") => {
        toast(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE[type.toUpperCase()] || toast.TYPE.ERROR,
        });
    };

    const columns = [
        {
            field: 'create_dtm',
            headerName: 'Created',
            flex: 0.8,
            valueGetter: (params) => dayjs(params.row.create_dtm).format('YYYY-MM-DD HH:mm'),
            headerClassName: 'font-semibold'
        },
        {
            field: 'end_dtm',
            headerName: 'Ended',
            flex: 0.8,
            valueGetter: (params) => params.row.end_dtm ? dayjs(params.row.end_dtm).format('YYYY-MM-DD HH:mm') : "-",
            headerClassName: 'font-semibold'
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            flex: 1,
            headerClassName: 'font-semibold'
        },
        {
            field: 'transfer_details',
            headerName: 'Transfer Details',
            flex: 1.5,
            headerClassName: 'font-semibold',
            minHeight: 100, // Add this to ensure minimum height
            renderCell: (params) => (
                <div className="d-flex flex-column gap-1 py-2">
                    <div className="bold text-muted">
                        {`${params.row.database?.toUpperCase()}`}
                    </div>
                    <div className="">
                        {`${params.row.transfer_from?.toUpperCase()} → ${params.row.transfer_to?.toUpperCase()}`}
                    </div>
                    <div className="">
                        {`${params.row.start_date} to ${params.row.end_date}`}
                    </div>
                </div>
            ),
            sortable: false,
        },
        {
            field: 'tenant_property',
            headerName: 'Tenant/Property',
            flex: 1,
            headerClassName: 'font-semibold',
            renderCell: (params) => (
                <div className="flex flex-col">
                    <div className="text-sm">Tenant: {params.row.tenant_id}</div>
                    <div className="text-xs text-gray-500">Property: {params.row.property_id}</div>
                </div>
            ),
            sortable: false,
        },
        {
            field: 'documents',
            headerName: 'Documents',
            flex: 1,
            headerClassName: 'font-semibold',
            renderCell: (params) => (
                <div className="flex flex-col">
                    <div className="text-sm">Inserted: {params.row.total_documents_inserted}</div>
                    <div className="text-xs text-gray-500">Deleted: {params.row.total_documents_deleted}</div>
                </div>
            ),
            sortable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerClassName: 'font-semibold',
            renderCell: (params) => {
                const statusConfig = {
                    completed: { color: 'success', label: 'COMPLETED' },
                    failed: { color: 'error', label: 'FAILED' },
                    partially_failed: { color: 'warning', label: 'PARTIALLY FAILED' },
                    in_progress: { color: 'info', label: 'IN PROGRESS' }
                };

                const config = statusConfig[params.value] || { color: 'default', label: params.value?.toUpperCase() };

                return (
                    <Chip
                        label={config.label}
                        color={config.color}
                        variant="filled"
                        size="small"
                        className="min-w-24 text-center"
                    />
                );
            },
            sortable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.8,
            headerClassName: 'font-semibold',
            renderCell: (params) => {
                const isRetryEnabled = params.row.status === 'failed' || params.row.status === 'partially_failed';

                return (
                    <div className="d-flex align-items-center gap-2">
                        <IconButton
                            onClick={() => handleRetry(params.row)}
                            disabled={!isRetryEnabled}
                            size="small"
                            sx={{
                                color: isRetryEnabled ? '#ff8b00' : '#ccc',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 139, 0, 0.04)'
                                }
                            }}
                        >
                            <SyncIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => handleRefresh(params.row)}
                            size="small"
                            sx={{
                                color: '#ff8b00',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 139, 0, 0.04)'
                                }
                            }}
                        >
                            <UpdateIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => handleOpenTransferDetails(params.row)}
                            sx={{
                                color: '#ff8b00',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 139, 0, 0.04)'
                                }
                            }}
                        >
                            <OpenInNewIcon />
                        </IconButton>
                    </div>
                );
            },
            sortable: false,
        }
    ];

    const getAllClients = async () => {
        try {
            const response = await axios.get("/get_tenant_property_mapping", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${fetchToken("zetta_access_token")}`,
                },
            });
            if (response.data) {
                const transformedTenants = Object.entries(response.data).map(
                    ([id, data]) => ({
                        value: parseInt(id),
                        label: data.tenant_name.trim(),
                        properties: data.properties || [],
                    })
                );

                // Sort tenants alphabetically by name
                transformedTenants.sort((a, b) => a.label.localeCompare(b.label));

                setTenants(transformedTenants);
            }
        } catch (error) {
            console.error("Error in fetching clients");
            if (error.response && error.response.status === 401) {
                navigate("/login");
            }
        }
    };

    const handleTenantChange = (selectedOption) => {
        setSelectedTenant(selectedOption);
        setSelectedProperty(null); // Reset property selection
        setStartDate(null);
        setEndDate(null);

        if (selectedOption) {
            // Transform properties for the selected tenant
            const properties = selectedOption.properties.map((prop) => ({
                value: prop.property_id,
                label: prop.property_name,
            }));

            // Sort properties alphabetically
            properties.sort((a, b) => a.label.localeCompare(b.label));

            setAvailableProperties([{ label: "All", value: "all" }, ...properties]);

        } else {
            setAvailableProperties([]);
        }
    };

    const handleStartDateChange = (newValue) => {
        if (!newValue) {
            setStartDate(null);
            return;
        }
        setStartDate(dayjs(newValue));
        setEndDate(null);
    };

    const handleEndDateChange = (newValue) => {
        if (!newValue) {
            setEndDate(null);
            return;
        }
        setEndDate(dayjs(newValue));
    };

    // Function to determine if a date should be disabled for start date picker
    const shouldDisableStartDate = (date) => {
        if (!endDate) return false;
        const diffDays = dayjs(endDate).diff(date, "day");
        return diffDays > dayLimit;
    };

    // Function to determine if a date should be disabled for end date picker
    const shouldDisableEndDate = (date) => {
        if (!startDate) return false;
        const diffDays = dayjs(date).diff(startDate, "day");
        // Disable if date is before start date or more than 10 days after start date
        return diffDays < 0 || diffDays > (dayLimit - 1);
    };

    const handleOpenTransferDetails = (row) => {
        setSelectedTransfer(row);
        setIsModalOpen(true);
    };

    const transferMongoData = async () => {
        try {
            if (!selectedTenant) {
                showToast("Please select a tenant!");
                return;
            }

            if (!selectedProperty) {
                showToast("Please select a property!");
                return;
            }

            if (!startDate) {
                showToast("Please enter a start date!");
                return;
            }

            if (!endDate) {
                showToast("Please enter a end date!");
                return;
            }

            if (!transferFrom) {
                showToast("Please select a source for data transfer!");
                return;
            }

            if (!transferTo) {
                showToast("Please select a destination for data transfer!");
                return;
            }

            if (transferFrom.value === transferTo.value) {
                showToast("Source and destination of  transfer cannot be same!");
                return;
            }

            const diffDays = endDate.diff(startDate, 'day');
            if (diffDays < 0) {
                showToast("End date cannot be before start date!");
                return;
            }
            if (diffDays > dayLimit) {
                showToast(`Date range cannot exceed ${dayLimit} days!`);
                return;
            }

            const formattedStartDate = startDate.format('YYYY-MM-DD');
            const formattedEndDate = endDate.format('YYYY-MM-DD');

            const data = {
                "tenant_id": selectedTenant.value,
                "property_id": selectedProperty.value,
                "start_date": formattedStartDate,
                "end_date": formattedEndDate,
                "transfer_from": transferFrom.value,
                "transfer_to": transferTo.value,
            }

            const response = await axios.post("/transfer_data_mongo", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });

            if (response.data) {
                showToast(response.data.msg, "success")
                getTransferLogs();
            }

        } catch (error) {
            console.error("Error in transferring data", error);
            if (error.response.status === 401) {
                navigate("/login");
            }
            showToast(
                error?.response?.data?.msg
                    ? error?.response?.data?.msg
                    : "Error in transferring data",
                "error"
            );
        }
    };

    const transferRDSData = async () => {
        try {
            const data = {}
            const response = await axios.post("/transfer_data_rds", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });

            if (response.data) {
                showToast(response.data.msg, "success")
                getTransferLogs();
            }
        } catch (error) {
            console.error("Error in transferring data", error);
            if (error.response.status === 401) {
                navigate("/login");
            }
            showToast(
                error?.response?.data?.msg
                    ? error?.response?.data?.msg
                    : "Error in transferring data",
                "error"
            );
        }
    }

    const getTransferLogs = async () => {
        // setIsLoading(true);
        try {
            const response = await axios.get("/get_data_transfers", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });

            if (response.data) {
                setLogs(response.data);
            }

        } catch (error) {
            console.error("Error in fetching transfer logs", error)
            if (error.response.status === 401) {
                navigate("/login");
            }

        } finally {
            // setIsLoading(false);
        }

    }

    const handleRetry = async (row) => {
        try {
            const data = {
                "_id": row._id,
                "database": row.database,
            }
            const response = await axios.post("/retry_transfer", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });
            if (response.data) {
                showToast(response.data.msg, "success")
                getTransferLogs();
            }
        } catch (error) {
            console.error("Error while retrying", error)
            if (error.response.status === 401) {
                navigate("/login");
            }
            showToast(
                error?.response?.data?.msg
                    ? error?.response?.data?.msg
                    : "Error in retrying transfer",
                "error"
            );
        }
    }

    const handleRefresh = async (row) => {
        try {
            const response = await axios.get(`/get_transfer_status/${row._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });
            if (response.data) {
                setLogs(prevLogs => prevLogs.map(log =>
                    log._id === row._id ? response.data : log
                ));
                showToast("Refresh Complete!", "success");
            }
        } catch (error) {
            console.error("Error while refreshing", error);
            if (error.response?.status === 401) {
                navigate("/login");
            }
            showToast(
                error?.response?.data?.msg || "Error in fetching transfer status",
                "error"
            );
        }
    };

    const getDayLimit = async () => {
        try {
            const response = await axios.get("/get_transfer_day_interval", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });
            if (response.data) {
                setTransferLimits(response.data);
                setDayLimit(response.data.days_interval_all_props);
            }
        } catch (error) {
            console.error("Error getting day limits", error)
            if (error.response?.status === 401) {
                navigate("/login");
            }
        }
    }

    useEffect(() => {
        if (selectedProperty) {
            if (selectedProperty.value === "all") {
                setDayLimit(transferLimits.days_interval_all_props)
            } else {
                setDayLimit(transferLimits.days_interval_one_props)
            }
        }
        setStartDate(null);
        setEndDate(null);
    }, [selectedProperty]);

    useEffect(() => {
        getDayLimit()
        getAllClients();
        getTransferLogs();
    }, []);



    return (
        <>
            <DashboardSessionTracker dashboardId={38}></DashboardSessionTracker>
            <section className="py-2">
                <Grow in={true} {...(true ? { timeout: 500 } : {})}>
                    <div className="admin-card bg-white p-4">
                        <div className="row d-flex justify-content-between align-items-center">
                            <h3 className="bold col-6">
                                <i class="fa-solid fa-arrow-right-arrow-left mr-2"></i>Data
                                Transfer Dashboard
                            </h3>
                            <div className="col-6 d-flex justify-content-end">
                                <Button
                                    className="btn-orange-non-radius w-30 mr-2"
                                    startIcon={<PersonIcon />}
                                    onClick={transferRDSData}
                                >
                                    Transfer RDS Data
                                </Button>
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className="row d-flex flex-column mt-4">
                            {/* <div className='row'>
                                <p className='bold text-orange medium-2'>Client Settings</p>
                            </div> */}
                            <div className="row w-100 d-flex justify-content-between align-items-center gap-1">
                                <div className="d-flex flex-column w-40">
                                    <p className="bold text-orange medium-1 m-0">Select Tenant</p>

                                    <Select
                                        className="w-100 mt-1 p-0"
                                        placeholder="Select Tenant"
                                        options={tenants}
                                        value={selectedTenant}
                                        onChange={handleTenantChange}
                                        isClearable
                                    />
                                </div>
                                <div className="d-flex flex-column w-40">
                                    <p className="bold text-orange medium-1 m-0">
                                        Select Property
                                    </p>
                                    <Select
                                        className="w-100 mt-1 p-0"
                                        placeholder="Select Property"
                                        options={availableProperties}
                                        value={selectedProperty}
                                        onChange={setSelectedProperty}
                                        // isDisabled={!selectedTenant}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex flex-column mt-4">
                            {/* <div className='row'>
                                <p className='bold text-orange medium-2'>Date Settings</p>
                            </div> */}
                            <div className="row w-100 d-flex justify-content-between align-items-center gap-1">
                                <div className="d-flex flex-column w-40">
                                    <p className="bold text-orange medium-1 m-0">Start Date</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            slotProps={{
                                                // field: { clearable: true },
                                                textField: {
                                                    size: "small",
                                                    margin: "dense",
                                                    // helperText: endDate ? "Select a date within 10 days of end date" : ""
                                                },
                                            }}
                                            className="w-100"
                                            format="YYYY-MM-DD"
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            shouldDisableDate={shouldDisableStartDate}
                                            disabled={!selectedTenant || !selectedProperty}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="d-flex flex-column w-40">
                                    <p className="bold text-orange medium-1 m-0">End Date</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            slotProps={{
                                                // field: { clearable: true },
                                                textField: {
                                                    size: "small",
                                                    margin: "dense",
                                                    // helperText: startDate ? "Select a date within 10 days of start date" : ""
                                                },
                                            }}
                                            className="w-100"
                                            format="YYYY-MM-DD"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            shouldDisableDate={shouldDisableEndDate}
                                            disabled={!selectedTenant || !selectedProperty}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex flex-column mt-4">
                            {/* <div className='row'>
                                <p className='bold text-orange medium-2'>Transfer Settings</p>
                            </div> */}
                            <div className="row w-100 d-flex justify-content-between align-items-center gap-1">
                                <div className="d-flex flex-column w-40">
                                    <p className="bold text-orange medium-1 m-0">Transfer From</p>
                                    <Select
                                        className="w-100 mt-1 p-0"
                                        placeholder="Transfer From"
                                        options={fromOptions}
                                        value={transferFrom}
                                        onChange={setTransferFrom}
                                        isDisabled={true}
                                        isClearable
                                    />
                                </div>
                                <div className="d-flex flex-column w-40">
                                    <p className="bold text-orange medium-1 m-0">Transfer To</p>
                                    <Select
                                        className="w-100 mt-1 p-0"
                                        placeholder="Transfer To"
                                        options={toOptions}
                                        value={transferTo}
                                        onChange={setTransferTo}
                                        // isDisabled={!selectedTenant}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center mt-4 p-2">
                            <Button
                                className="btn-orange-non-radius w-25"
                                onClick={transferMongoData}
                            >
                                Transfer Mongo Data
                            </Button>
                        </div>

                        <div className="row d-flex flex-column mt-4">
                            <div className="row">
                                <p className="bold text-orange medium-2">Transfer Status</p>
                            </div>

                            {isLoading ? (
                                <div className="row p-4">
                                    {/* Animate loading rows */}
                                    {[...Array(5)].map((_, index) => (
                                        <div key={index} className="mb-3">
                                            <Skeleton
                                                variant="rectangular"
                                                height={50}
                                                animation="wave"
                                                sx={{
                                                    backgroundColor: 'rgba(255, 139, 0, 0.1)',
                                                    borderRadius: '4px'
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : logs && logs.length > 0 ? (
                                <div className="row">
                                    <DataGrid
                                        rows={logs}
                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        getRowId={(row) => row._id}
                                        getRowHeight={() => 'auto'}
                                        getEstimatedRowHeight={() => 100}
                                        initialState={{
                                            sorting: {
                                                sortModel: [{ field: 'create_dtm', sort: 'desc' }],
                                            },
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="row text-center p-4">
                                    <p>No transfer logs available</p>
                                </div>
                            )}
                        </div>
                    </div>
                </Grow>
            </section>
            <TransferDetailsModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                transferData={selectedTransfer}
            />
            <ToastContainer />
        </>
    );
};

export default DataVoyage;
