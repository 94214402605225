import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PriceHistory from "./PriceHistory";
import BookingHistory from "./BookingHistory";

const MasterSecondaryDialogue = ({
    open,
    setOpen,
    tenantId,
    propertyId,
    roomType,
    mealPlan,
    ota,
    selfInvSource,
    invIntegrated,
    selfTariffSource,
    tariffIntegrated,
    date,
    masterData,
    currencySymbol,
    type,
    title,
    setSecDialogType,
    setSecDialogTitle,
}) => {
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSecDialogType("tariffHistory");
        setSecDialogTitle("Tariff History");
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => {
                    handleClose();
                }}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    {title}

                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            handleClose();
                        }}
                        aria-label="close"
                        style={{
                            position: "absolute",
                            right: "1rem",
                            top: "1rem",
                            cursor: "pointer",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {date && type === "tariffHistory" && (
                        <PriceHistory
                            tenantId={tenantId}
                            propertyId={propertyId}
                            roomType={roomType}
                            mealPlan={mealPlan}
                            ota={ota}
                            selfTariffSource={selfTariffSource}
                            date={date}
                            masterData={masterData}
                            currencySymbol={currencySymbol}
                            open={open}
                        />
                    )}

                    {date && type === "bookingHistory" && (
                        <BookingHistory
                            tenantId={tenantId}
                            propertyId={propertyId}
                            date={date}
                            open={open}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MasterSecondaryDialogue;
