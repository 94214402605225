import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const privilegeMap = {
  1: "Reader",
  2: "Maker",
  3: "Checker",
  4: "Full Access",
  5: "No Access",
};

export default function FunctionalityDetailsDialog({
  open,
  onClose,
  functionalities,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          color: "#fcac51",
        }}
      >
        Functionality Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(9, 9, 9, 0.1)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: 0, display: "flex", justifyContent: "center" }}
      >
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 440,
            mt: 1,
            mb: 1,
            width: "80%",
            "& .MuiTable-root": {
              margin: "0 auto",
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    width: "50%",
                    padding: "12px 16px", // Add more compact padding
                  }}
                >
                  Functionality Name
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    fontWeight: "bold",
                    width: "50%",
                    padding: "12px 16px", // Add more compact padding
                  }}
                >
                  Privilege
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {functionalities?.map((functionality) => (
                <TableRow
                  key={functionality.functionality_id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}
                >
                  <TableCell sx={{ padding: "12px 16px" }}>
                    {functionality.functionality_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      padding: "12px 16px",
                    }}
                  >
                    {privilegeMap[functionality.privilege]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
