import { React, useEffect, useState } from 'react'
import DashboardSessionTracker from "../../components/common/SessionTracker"
import Grow from '@mui/material/Grow';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { fetchToken } from '../../Auth';
import axios from 'axios';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from "@mui/icons-material/Delete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import { Country, State, City } from "country-state-city";
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import { toast, ToastContainer } from "react-toastify";
import { getRoomURL, openUrlInIncognito, getCurrentDate } from "../../services/helper"
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ConfirmationDialog from "../../components/common/ConfirmationDialog"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../../assets/styles/mappings.css"


const HotelMappingsDashboard = () => {
    const navigate = useNavigate();

    const [existingMappings, setExistingMappings] = useState([]);
    const [filteredMappings, setFilteredMappings] = useState([]);
    const [clientDataSources, setClientDataSources] = useState({});
    const [otas, setOtas] = useState([]);
    const [showExistingMappings, setShowExistingMappings] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cityHotels, setCityHotels] = useState([]);
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [newHotelOpen, setNewHotelOpen] = useState(false);
    const [modalOta, setModalOta] = useState('');
    const [newHotelName, setNewHotelName] = useState(null);
    const [newHotelId, setNewHotelId] = useState(null);
    const [newHotelUrl, setNewHotelUrl] = useState(null);
    const [newHotelInfoToken, setNewHotelInfoToken] = useState(null);
    const [newHotelRoomUrl, setNewHotelRoomUrl] = useState(null);
    const [newHotelPageName, setNewHotelPageName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const [suggestedMappings, setSuggestedMappings] = useState(null);


    const [selectedCountry, setSelectedCountry] = useState({
        "label": "India",
        "value": "India",
        "country_code": "IN"
    });

    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        title: '',
        message: '',
        onConfirm: null
    });

    const handleConfirmation = (title, message) => {
        return new Promise((resolve) => {
            setConfirmDialog({
                open: true,
                title,
                message,
                onConfirm: () => {
                    setConfirmDialog(prev => ({ ...prev, open: false }));
                    resolve(true);
                }
            });
        });
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialog(prev => ({ ...prev, open: false }));
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "#fff",
            borderColor: "#9e9e9e",
            minHeight: "38px",
            height: "auto",
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            maxHeight: "30px", // Set the maximum height for the value container
            overflowY: "auto", // Enable vertical scrolling
            padding: "0 6px",
        }),
        input: (provided, state) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "30px",
        }),
    };
    const [selectedCity, setSelectedCity] = useState(null);


    const getExistingHotelMappings = async () => {
        try {
            const response = await axios.get("/get_hotel_mappings", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });

            if (response.status === 200) {
                setExistingMappings(response.data);
            }

        } catch (error) {
            console.error("Error in fetching existing mappings", error)
            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }
    const getClientDataSources = async () => {
        try {
            const response = await axios.get("/get_client_data_sources", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });
            if (response.status === 200) {
                setClientDataSources(response.data);
            }
        } catch (error) {
            console.error("Error in fetching client data sources", error)
            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getHospitalityCities = async () => {
        try {
            const response = await axios.get("/get_hospitality_cities", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });
            if (response.data) {
                setCities(response.data)
            }
        } catch (error) {
            console.error("Error fetching cities", error)
            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        getClientDataSources();
        getHospitalityCities();
    }, []);

    useEffect(() => {
        if (Object.keys(clientDataSources).length) {
            const dataSourcesArray = Object.values(clientDataSources)
            setOtas(dataSourcesArray.filter(source => source.is_pms === false))
        }
        // client data sources is a dict but otas is a list
    }, [clientDataSources]);

    useEffect(() => {
        if (otas.length) {
            getExistingHotelMappings()
        }
    }, [otas])

    const generateColumns = () => {
        const baseColumns = [
            {
                field: 'hotel_id',
                headerName: 'Sciative ID',
                flex: 1,
                // minWidth: 130
                renderCell: (params) => (
                    <div
                        title={params.row.hotel_id}
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%"
                        }}
                        className="bold"
                    >
                        {params.row.hotel_id}
                    </div>
                ),
            },
            {
                field: 'city',
                headerName: 'City',
                flex: 0.75,
                // minWidth: 130
                renderCell: (params) => (
                    <div
                        title={params.row.city}
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%"
                        }}
                        className=""
                    >
                        {params.row.city}
                    </div>
                ),
                sortComparator: (v1, v2) => {
                    // Handle cases where either value is null/undefined
                    const val1 = v1 || "-";
                    const val2 = v2 || "-";

                    // If both are "-", treat them as equal
                    if (val1 === "-" && val2 === "-") return 0;
                    // If first is "-", it should come first in ascending order
                    if (val1 === "-") return 1;
                    // If second is "-", it should come first in ascending order
                    if (val2 === "-") return -1;
                    // Normal string comparison for other cases
                    return val1.localeCompare(val2);
                },
            }
        ];

        // Add columns for each OTA
        const otaColumns = otas.flatMap(ota => [
            // {
            //     field: `${ota.id.toLowerCase()}_id`,
            //     headerName: `${ota.name} ID`,
            //     flex: 1,
            //     minWidth: 130
            // },
            {
                field: `${ota.id.toLowerCase()}_name`,
                headerName: `${ota.name}`,
                flex: 1.75,
                renderCell: (params) => {
                    if (params.row?.[`${ota.id}_name`]) {
                        return (
                            <div>
                                <p className='mb-0' title={params.row[`${ota.id}_name`] || "-"}>
                                    {params.row[`${ota.id}_name`] || "-"}
                                </p>
                                <p className='small text-muted mb-0'>
                                    {params.row[`${ota.id}_id`] || "-"}
                                </p>
                            </div>
                        );
                    }
                    return <div>-</div>;
                },
                sortComparator: (v1, v2) => {
                    // Handle cases where either value is null/undefined
                    const val1 = v1 || "-";
                    const val2 = v2 || "-";

                    // If both are "-", treat them as equal
                    if (val1 === "-" && val2 === "-") return 0;
                    // If first is "-", it should come first in ascending order
                    if (val1 === "-") return 1;
                    // If second is "-", it should come first in ascending order
                    if (val2 === "-") return -1;
                    // Normal string comparison for other cases
                    return val1.localeCompare(val2);
                },
            }
        ]);

        return [...baseColumns, ...otaColumns];
    };

    useEffect(() => {
        if (!searchTerm.trim()) {
            setFilteredMappings(existingMappings);
            return;
        }

        const searchTermLower = searchTerm.toLowerCase();
        const filtered = existingMappings.filter(mapping => {
            // Search in Sciative ID - convert to string first
            if (mapping?.['hotel_id']?.toString().toLowerCase().includes(searchTermLower)) {
                return true;
            }

            // Search in OTA names and IDs
            for (const ota of otas) {
                const otaNameKey = `${ota.id}_name`;
                const otaIdKey = `${ota.id}_id`;

                if (mapping[otaNameKey]?.toString().toLowerCase().includes(searchTermLower) ||
                    mapping[otaIdKey]?.toString().toLowerCase().includes(searchTermLower)) {
                    return true;
                }
            }
            return false;
        });

        setFilteredMappings(filtered);
    }, [searchTerm, existingMappings, otas]);


    const handleModalClose = () => {
        setShowExistingMappings(false);
        setSearchTerm('');
    }

    const showToast = (message, type = "error") => {
        toast(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: "colored",
            type: toast.TYPE[type.toUpperCase()] || toast.TYPE.ERROR,
        });
    };


    const getCityHotels = async () => {
        try {
            if (!selectedCity) {
                showToast("Please select a city!")
                return
            }
            const response = await axios.get(`/get_city_level_hotels/${selectedCity.label}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });
            setCityHotels(response.data);
        } catch (error) {
            console.error('Error in fetching hotels for city', error)
            if (error.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getMappings = async () => {
        // Validation checks
        if (!selectedCity) {
            showToast("Please select a city!");
            return;
        }
        if (!selectedHotels.length) {
            showToast("Please select at least 1 hotel!");
            return;
        }

        try {
            // Check if we need confirmation for overriding existing mappings
            if (suggestedMappings && Object.keys(suggestedMappings).length) {
                const shouldOverride = await handleConfirmation(
                    "Get New Mappings",
                    "You will lose your existing mappings. Do you wish to proceed?"
                );
                if (!shouldOverride) return;
            }
            setIsLoading(true);

            // API request configuration
            const requestData = {
                hotels: selectedHotels,
                city: selectedCity.label
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`
                }
            };

            // Make API request
            const response = await axios.post("/get_suggested_mappings", requestData, config);
            if (response.data) {
                setSuggestedMappings(response.data);
            }

        } catch (error) {
            console.error("Error in getting mappings:", error);
            if (error.response?.status === 401) {
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewHotelOpen = (ota) => {
        setModalOta(ota);
        setNewHotelOpen(true);
    }

    const addCustomMapping = async () => {
        // Input validation
        if (!newHotelName) {
            setErrorMessage("Please enter a hotel name!");
            return;
        }
        if (!newHotelId) {
            setErrorMessage("Please enter a hotel ID!");
            return;
        }
        if (!newHotelPageName && (modalOta === "agoda" || modalOta === "booking")) {
            setErrorMessage("Page name is required for Agoda & Booking !");
            return;
        }
        if (!newHotelUrl && modalOta === "emt") {
            setErrorMessage("Hotel URL is required for EaseMyTrip!");
            return;
        }
        if (!newHotelInfoToken && modalOta === "ixigo") {
            setErrorMessage("Hotel Info Token is required for Ixigo!");
            return;
        }

        // Create the new hotel object
        const newHotelData = {
            ota_id: newHotelId,
            ota_name: newHotelName,
            match_score: 100,
            selected: true
        };

        // Add additional fields based on OTA type
        if (modalOta === "agoda") {
            newHotelData.agoda_page_name = newHotelPageName;
        }
        if (modalOta === "booking") {
            newHotelData.booking_page_name = newHotelPageName;
        }
        if (modalOta === "emt") {
            newHotelData.emt_hotel_url = newHotelUrl;
        }
        if (modalOta === "ixigo") {
            newHotelData.emt_hotel_url = newHotelInfoToken;
        }

        setSuggestedMappings(prevMappings => {
            const updatedMappings = { ...prevMappings };

            // Get the current hotel name from the accordion
            const currentHotelName = Object.keys(updatedMappings)[0];

            // Update the OTA suggestions for the current hotel
            if (updatedMappings[currentHotelName]) {
                // Deselect all existing suggestions for this OTA
                if (Array.isArray(updatedMappings[currentHotelName][modalOta])) {
                    updatedMappings[currentHotelName][modalOta] = updatedMappings[currentHotelName][modalOta].map(
                        suggestion => ({ ...suggestion, selected: false })
                    );
                    // Add the new hotel to the suggestions
                    updatedMappings[currentHotelName][modalOta].push(newHotelData);
                } else {
                    // If it's not an array (empty or null), initialize it
                    updatedMappings[currentHotelName][modalOta] = [newHotelData];
                }
            }
            return updatedMappings;
        });



        // Close the modal
        handleNewHotelClose();

        // Show success message
        showToast("Custom mapping added successfully!", "success");
    };

    const handleNewHotelClose = (ota) => {
        setNewHotelOpen(false);
        setModalOta("");
        // Clear the form
        setNewHotelName("");
        setNewHotelId("");
        setNewHotelPageName("");
        setNewHotelUrl("");
        setNewHotelInfoToken("");
        setNewHotelRoomUrl("");

        setErrorMessage("");
    }


    // useEffect(() => {
    //     setCountries(Country.getAllCountries())
    // }, [])

    const updateOtaHotelSelection = (hotelName, otaName, selectedOtaId) => {
        setSuggestedMappings(prevMappings => {
            // Create a deep copy of the previous mappings to avoid direct mutation
            const updatedMappings = { ...prevMappings };

            // Get the current OTA suggestions for the specific hotel
            const otaSuggestions = updatedMappings[hotelName][otaName];

            // Handle both array and object cases
            if (Array.isArray(otaSuggestions)) {
                // For array-type suggestions (like Agoda)
                updatedMappings[hotelName][otaName] = otaSuggestions.map(suggestion => ({
                    ...suggestion,
                    selected: suggestion.ota_id === selectedOtaId
                }));
            } else {
                // For object-type suggestions (like Booking)
                updatedMappings[hotelName][otaName] = {
                    ...otaSuggestions,
                    selected: otaSuggestions.ota_id === selectedOtaId
                };
            }

            return updatedMappings;
        });
    };

    const deleteHotelMapping = async (hotelName) => {
        try {
            // Show confirmation dialog before deleting
            const shouldDelete = await handleConfirmation(
                "Delete Hotel Mapping",
                `Are you sure you want to delete the mapping for ${hotelName.split("$")[0]}?`
            );

            if (!shouldDelete) return;

            const hotelId = hotelName.split("$")[1];

            setSelectedHotels(prevSelected =>
                prevSelected.filter(hotel => parseInt(hotel.value) !== parseInt(hotelId))
            );

            setSuggestedMappings(prevMappings => {
                const updatedMappings = { ...prevMappings };
                delete updatedMappings[hotelName];
                return updatedMappings;
            });

            // showToast("Hotel mapping deleted successfully", "success");

        } catch (error) {
            console.error("Error deleting hotel mapping:", error);
            showToast("Failed to delete hotel mapping", "error");
        }
    };


    const getRelevantHotelFields = () => {
        try {
            // First check if the URL is valid
            new URL(newHotelRoomUrl);

            if (modalOta === "emt") {
                try {
                    const baseUrl = newHotelRoomUrl.split('?')[0];
                    if (baseUrl) {
                        setNewHotelUrl(baseUrl);
                    } else {
                        setNewHotelUrl('');
                    }


                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelUrl("");
                }
                try {
                    // Extract ecid
                    const ecid = new URLSearchParams(newHotelRoomUrl.split('?')[1]).get('ecid');
                    if (ecid) {
                        setNewHotelId(ecid.toUpperCase());
                    } else {
                        setNewHotelId("");
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelId("");

                }
            } else if (modalOta === "agoda") {
                try {
                    const pathname = new URL(newHotelRoomUrl).pathname;
                    if (pathname) {
                        setNewHotelPageName(pathname)
                    } else {
                        setNewHotelPageName("")
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelPageName("");
                }
            } else if (modalOta === "booking") {
                try {
                    const pathname = new URL(newHotelRoomUrl).pathname;
                    if (pathname) {
                        setNewHotelPageName(pathname.replace("/hotel/in/", "").replace(".html", ""))
                    } else {
                        setNewHotelPageName("")
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelPageName("");
                }
                try {
                    const b_id = new URLSearchParams(newHotelRoomUrl.split('?')[1]).get('dest_id');
                    if (b_id) {
                        setNewHotelId(b_id.toUpperCase());
                    } else {
                        setNewHotelId("");
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelId("");
                }
            } else if (modalOta === "ixigo") {
                try {
                    const pathname = new URL(newHotelRoomUrl).pathname;
                    if (pathname) {
                        setNewHotelId(pathname.split("/")[2])
                    } else {
                        setNewHotelId("")
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelId("");
                }
                try {
                    const infoToken = new URLSearchParams(newHotelRoomUrl.split('?')[1]).get('hotelInfoToken')
                    if (infoToken) {
                        setNewHotelInfoToken(infoToken)
                    } else {
                        setNewHotelInfoToken("")
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelInfoToken("");
                }
            } else if (modalOta === "mmt") {
                try {
                    const mmt_id = new URLSearchParams(newHotelRoomUrl.split('?')[1]).get('hotelId');
                    if (mmt_id) {
                        setNewHotelId(mmt_id.toUpperCase());
                    } else {
                        setNewHotelId("");
                    }
                } catch (error) {
                    console.error("Invalid URL or error in processing:", error);
                    setNewHotelId("");

                }
            }

        }
        catch (error) {
            console.error("Invalid URL or error in processing:", error);
            setNewHotelUrl("");
            setNewHotelId("");
            setNewHotelPageName("");
            setNewHotelInfoToken("");
        }
    }



    const saveMappings = async () => {
        try {
            // Transform the suggested mappings into the required format
            const mappingsToSave = Object.entries(suggestedMappings).reduce((acc, [hotelName, otaMappings]) => {
                // Get the hotel ID from the hotel name (it's stored as "hotelName$hotelId")
                const hotelId = hotelName.split('$')[1];

                // Initialize the mapping object for this hotel
                const hotelMapping = {
                    property_id: hotelId,
                    mappings: {}
                };

                // For each OTA, find the selected mapping
                Object.entries(otaMappings).forEach(([ota, suggestions]) => {
                    // Handle both array and single object cases
                    const selectedMapping = Array.isArray(suggestions)
                        ? suggestions.find(s => s.selected)
                        : suggestions.selected ? suggestions : null;

                    if (selectedMapping) {
                        // Add the mapping data
                        hotelMapping.mappings[ota] = {
                            ota_id: selectedMapping.ota_id,
                            ota_name: selectedMapping.ota_name,
                            rating: selectedMapping.rating,
                            image_url: selectedMapping.image_url,
                            // Include additional fields based on OTA
                            ...(selectedMapping.agoda_page_name && { agoda_page_name: selectedMapping.agoda_page_name }),
                            ...(selectedMapping.booking_page_name && { booking_page_name: selectedMapping.booking_page_name }),
                            ...(selectedMapping.emt_hotel_url && { emt_hotel_url: selectedMapping.emt_hotel_url })
                        };
                    }
                });

                // Only include hotels that have at least one mapping
                if (Object.keys(hotelMapping.mappings).length > 0) {
                    acc.push(hotelMapping);
                }

                return acc;
            }, []);

            const data = { "mappings_to_save": mappingsToSave }

            const response = await axios.post("/save_hotel_mappings", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('zetta_access_token')}`,
                }
            });

            if (response.status === 200) {
                showToast(response?.data?.msg ? response.data.msg : "Mappings saved successfully!", "success");
                // Refresh the existing mappings
                getExistingHotelMappings();
                // Clear the suggested mappings
                setSuggestedMappings(null);
            }
        } catch (error) {
            console.error("Error saving mappings:", error);
            showToast(error?.response?.data?.msg ? error?.response?.data?.msg : "Error is saving mappings", "error");
            if (error.response?.status === 401) {
                navigate('/login');
            }
        }
    };

    const removeOtaMapping = (hotelName, ota) => {
        setSuggestedMappings(prevMappings => {
            const updatedMappings = { ...prevMappings };

            const otaSuggestions = updatedMappings[hotelName][ota];

            if (Array.isArray(otaSuggestions)) {
                updatedMappings[hotelName][ota] = otaSuggestions.map(suggestion => ({
                    ...suggestion,
                    selected: false
                }));
            } else if (otaSuggestions && typeof otaSuggestions === 'object') {
                updatedMappings[hotelName][ota] = {
                    ...otaSuggestions,
                    selected: false
                };
            }

            return updatedMappings;
        });

        // showToast(`Mapping removed for ${otas.find(item => item.id === ota)?.name || ota.toUpperCase()}`, "success");
    };


    // useEffect(() => {
    //     if (selectedCountry && typeof selectedCountry.value !== 'undefined' && selectedCountry.value) {
    //         const cities = City.getCitiesOfCountry(selectedCountry.country_code);
    //         setCities(cities)
    //     }
    // }, [selectedCountry])

    useEffect(() => {
        let timeoutId;
        if (errorMessage) {
            timeoutId = setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [errorMessage]);

    useEffect(() => {
        if (selectedCity && Object.keys(selectedCity).length) {
            setSelectedHotels([]);
            getCityHotels();
        }
    }, [selectedCity]);


    // useEffect(() => {
    //     getRelevantHotelFields()
    // }, [newHotelRoomUrl]);

    return (
        <>
            {/* <DashboardSessionTracker dashboardId={33}> </DashboardSessionTracker> */}

            <Grow
                in={true}
                {...(true ? { timeout: 500 } : {})}
            >
                <div className='px-4 py-2 mt-3 white-card'>
                    <div className="mt-3">
                        <div className='bg-grey p-1 py-2 corner w-100 row'>
                            <div className='col-9'>
                                <h3 class='dashboard-card-title'>Map New Hotel</h3>
                                <p className='grey-2 small mt-1'>Map a particular hotel across all OTAs</p>
                            </div>
                            <div className='col-3 d-flex justify-content-end align-items-center'>
                                <Button variant='contained' className="btn-orange-non-radius w-50" onClick={() => { setShowExistingMappings(true) }} disabled={!existingMappings.length}>View Mappings</Button>
                            </div>
                        </div>
                        <div className='mt-3 mb-3 w-75'>
                            {/* <h6 className='mt-2 bold'>Map a new hotel</h6> */}
                            <h6 className='text-muted small'>Search for a hotel name to view results across all OTAs</h6>
                            {/* do not remove!!!! */}
                            {/* <Select
                                value={selectedCountry}
                                onChange={(opt) => setSelectedCountry({ 'label': opt.label, 'value': opt.value, "country_code": opt.country_code })}
                                className="w-100 mt-1"
                                options={countries.map(item => ({ 'label': item.name, 'value': item.name, 'country_code': item.isoCode }))}
                                placeholder='Select Country'
                            /> */}
                            {/* <div className="w-100 mb-2 d-flex justify-content-between align-items-center">
                                <Select
                                    value={selectedCity}
                                    onChange={(opt) => setSelectedCity({ 'label': opt.label, 'value': opt.value })}
                                    className="w-70 mt-1"
                                    options={cities.map(item => ({ 'label': item.name, 'value': item.name }))}
                                    placeholder='Select City'
                                />
                                <Button className="w-25 btn-orange-non-radius" variant="contained" onClick={getCityHotels}>
                                    Get Hotels
                                </Button>
                            </div> */}


                            <div className="w-100 mb-2 d-flex justify-content-between align-items-center">
                                <Select
                                    value={selectedCity}
                                    onChange={(opt) => setSelectedCity({ 'label': opt.label, 'value': opt.value })}
                                    className="w-25"
                                    // options={cities.map(item => ({ 'label': item.name, 'value': item.name }))}
                                    options={cities.map(item => ({ 'label': item, 'value': item }))}
                                    placeholder='Select City'
                                />
                                <MultiSelect
                                    labelledBy="Select your preferred hotels"
                                    placeholder="Select hotels"
                                    value={selectedHotels}
                                    onChange={(opt) => {
                                        if (opt.length <= 10) {
                                            setSelectedHotels(opt);
                                        } else {
                                            showToast(`You can only select up to ${10} hotels.`);
                                        }
                                    }}
                                    hasSelectAll={false}
                                    isMulti
                                    styles={customStyles}
                                    closeMenuOnSelect={false}
                                    options={cityHotels}
                                    className="multi-select w-50"
                                    overrideStrings={{
                                        selectSomeItems: "Select Hotel's",
                                    }}
                                // disabled={!cityHotels.length}
                                />
                                <Button className="w-20 btn-orange-non-radius" variant="contained" onClick={getMappings} disabled={isLoading}>
                                    {isLoading ? 'Loading...' : 'Get Mappings'}
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </Grow>
            <div className="px-4 py-2 mt-3">
                {/* Replace the existing mapping section with this */}
                {suggestedMappings && Object.entries(suggestedMappings).map(([hotelName, suggestions]) => (
                    <Accordion
                        key={hotelName}
                        style={{
                            borderRadius: "10px",
                            '&:before': {
                                display: 'none'
                            },
                            background: "black",
                        }}
                        className="mb-3 p-0"
                        sx={{
                            '&:before': {
                                display: 'none'
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="text-white" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='flex align-items-center justify-content-center'
                        >
                            <div className="col-10 d-flex align-items-center">
                                <h6 className="mb-0 text-white">{hotelName.split("$")[1]}. {hotelName.split("$")[0]}</h6>
                            </div>
                            <div className='col-2 d-flex justify-content-end'>
                                <DeleteIcon
                                    className='text-white'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteHotelMapping(hotelName);
                                    }}

                                />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails sx={{ borderRadius: "10px" }} className="bg-grey">
                            <div className="row d-flex justify-content-center">
                                {Object.entries(suggestions).map(([ota, sugList]) => (
                                    <div key={ota} className={`m-3 white-card w-45 p-3`}>
                                        <p className="m-0 bold">
                                            {otas.find(item => item.id === ota)?.name ? otas.find(item => item.id === ota)?.name : ota.toUpperCase()}
                                        </p>
                                        <div>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                className="flex flex-col gap-2 mt-3"
                                            >
                                                {sugList ? (
                                                    // Handle array of suggestions (like in agoda)
                                                    sugList.map((suggestion) => {
                                                        let ota_id = suggestion.ota_id
                                                        let additionalData = {
                                                            [ota_id]: {
                                                                "agoda_page_name": suggestion.agoda_page_name,
                                                                "emt_hotel_url": suggestion.emt_hotel_url,
                                                                "booking_page_name": suggestion.booking_page_name,
                                                                [ota + "_id"]: ota_id
                                                            }
                                                        }
                                                        const roomUrl = getRoomURL(ota, suggestion.ota_id, getCurrentDate(), additionalData);
                                                        const matchScore = suggestion.match_score ?? Infinity;
                                                        const isInfinityScore = matchScore === Infinity;
                                                        return (
                                                            <div
                                                                key={suggestion.ota_id}
                                                                className={`relative d-flex align-items-center border rounded-lg p-1 hover:border-primary transition-colors ${isInfinityScore ? 'animate-pulse' : ''
                                                                    }`}
                                                                style={{
                                                                    border: '1px solid #e0e0e0',
                                                                    borderRadius: '8px',
                                                                    marginBottom: '8px',
                                                                    backgroundColor: isInfinityScore ? '#f0f7ff' : 'white', // Light blue background
                                                                    borderLeft: isInfinityScore ? '4px solid #2196f3' : '1px solid #e0e0e0' // Blue left border
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value={suggestion.ota_id}
                                                                    control={<Radio
                                                                        checked={suggestion.selected}
                                                                        onChange={() => updateOtaHotelSelection(hotelName, ota, suggestion.ota_id)}
                                                                    />}
                                                                    label={
                                                                        <div>
                                                                            <div>{suggestion.ota_name}</div>
                                                                            <small className="text-muted">Match Score: {(suggestion.match_score ?? Infinity).toLocaleString("en-IN")}%</small>
                                                                        </div>
                                                                    }
                                                                    style={{ flexGrow: 1 }}
                                                                />
                                                                {
                                                                    roomUrl ?
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => openUrlInIncognito(roomUrl)}
                                                                            style={{
                                                                                padding: '8px',
                                                                                marginLeft: '8px'
                                                                            }}
                                                                            sx={{ color: "#0d6efd" }}
                                                                        >
                                                                            <OpenInNewIcon />
                                                                        </IconButton>
                                                                        :
                                                                        <IconButton
                                                                            size="small"
                                                                            // onClick={() => openUrlInIncognito()}
                                                                            style={{
                                                                                padding: '8px',
                                                                                marginLeft: '8px'
                                                                            }}
                                                                            sx={{ cursor: "none" }}
                                                                            disabled={true}
                                                                        >
                                                                            <OpenInNewIcon />
                                                                        </IconButton>
                                                                }

                                                            </div>
                                                        )
                                                    })
                                                ) : null}
                                            </RadioGroup>
                                            {!sugList.length &&
                                                <div>
                                                    <Alert severity="warning">No Hotels matched on {otas.find(item => item.id === ota)?.name ? otas.find(item => item.id === ota)?.name : ota.toUpperCase()}</Alert>
                                                </div>
                                            }
                                            {/* <div className="mt-4">
                                                <Button className="text-orange border-orange w-100" onClick={() => { handleNewHotelOpen(ota) }}>
                                                    Add Custom
                                                </Button>
                                            </div> */}
                                            <div className="mt-4">
                                                <Button
                                                    className="text-orange border-orange w-100"
                                                    onClick={() => removeOtaMapping(hotelName, ota)}
                                                >
                                                    Remove Mapping
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="row d-flex justify-content-center m-3">
                                <div className="white-card row w-95 p-3 d-flex flex-column">
                                    <p className="m-0 bold">Hotel ID Mapping</p>
                                    <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                                        {otas.map((ota, index) => (
                                            <>
                                                <div className="border rounded" style={{ width: '150px', height: '112px' }}>
                                                    <div className="d-flex flex-column h-100">
                                                        {/* <div className="d-flex justify-content-center align-items-center flex-grow-1" style={{ height: '72px' }}>
                                                            {suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.image_url ? (
                                                                <img
                                                                    src={suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.image_url}
                                                                    alt="Hotel"
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                />
                                                            ) : (
                                                                <p className="m-0">
                                                                    {suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.ota_name || '-'}
                                                                </p>
                                                            )}
                                                        </div> */}
                                                        <div className="d-flex justify-content-center align-items-center flex-grow-1" style={{ height: '72px' }}>
                                                            {suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.image_url ? (
                                                                <img
                                                                    src={suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.image_url}
                                                                    alt="Hotel"
                                                                    onLoad={(e) => {
                                                                        e.target.style.display = 'block'; // Ensure image is visible
                                                                        e.target.nextElementSibling.style.display = 'none'; // Hide fallback text
                                                                    }}
                                                                    onError={(e) => {
                                                                        e.target.style.display = 'none'; // Hide the image
                                                                        e.target.nextElementSibling.style.display = 'block'; // Show fallback text
                                                                    }}
                                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                />
                                                            ) : null}
                                                            <p
                                                                className="m-0"
                                                                style={{
                                                                    display: suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.image_url ? 'none' : 'block'
                                                                }}
                                                            >
                                                                {suggestions[ota.id.toLowerCase()]?.find(item => item.selected)?.ota_name || '-'}
                                                            </p>
                                                        </div>
                                                        <hr className="my-0" />
                                                        <div className="d-flex justify-content-center align-items-center p-2" style={{ height: '40px' }}>
                                                            {ota.main_logo ? (
                                                                <img
                                                                    src={ota.main_logo}
                                                                    alt={ota.name}
                                                                    style={{ width: '50px', height: '25px', objectFit: 'contain' }}
                                                                />
                                                            ) : (
                                                                <p className="m-0 small">{ota.name}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                {index !== otas.length - 1 && (
                                                    <Box className="d-flex align-items-center mx-2">
                                                        <ArrowBackIcon />
                                                        <ArrowForwardIcon />
                                                    </Box>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}


                {suggestedMappings &&
                    <div className='w-100 d-flex justify-content-center'>
                        <Button className="btn-orange-non-radius" onClick={saveMappings}>Save Mappings</Button>
                    </div>
                }
            </div>


            <Dialog
                open={showExistingMappings}
                onClose={() => handleModalClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="text-orange">
                            Hotel Mappings
                        </h4>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => {
                                handleModalClose();
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="w-100 d-flex justify-content-end mb-3 mt-2">
                        <TextField
                            label="Search Hotels"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchTerm && (
                                            <IconButton
                                                aria-label="clear search"
                                                onClick={() => setSearchTerm("")}
                                                edge="end"
                                                size="small"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                                // startAdornment: (
                                //     <InputAdornment position="start">
                                //         <SearchIcon />
                                //     </InputAdornment>
                                // ),
                                style: {
                                    borderRadius: '20px',
                                    backgroundColor: '#f5f5f5',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />

                    </div>
                    <div>
                        <DataGrid
                            rows={filteredMappings}
                            columns={generateColumns()}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10 }
                                }
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                            getRowId={(row) => row.hotel_id}
                            disableSelectionOnClick
                        />
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={newHotelOpen}
                onClose={handleNewHotelClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div className='d-flex flex-column'>
                            <Typography variant="h6">Add Custom Mapping for {modalOta && otas.find(item => item.id === modalOta)?.name ? otas.find(item => item.id === modalOta)?.name : modalOta.toUpperCase()}</Typography>
                            {/* <Typography variant="h6">{modalOta}</Typography> */}
                        </div>
                        <IconButton
                            onClick={handleNewHotelClose}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div className="row mt-3">
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Hotel Name</label>
                            <input
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel Name"
                                aria-label="hotel-name"
                                value={newHotelName}
                                onChange={(evt) => { setNewHotelName(evt.target.value) }}
                            />
                        </div>
                        <div className="d-flex flex-column col-6">
                            <label htmlFor="">Hotel ID</label>
                            <input
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel ID"
                                aria-label="hotel-id"
                                value={newHotelId}
                                onChange={(evt) => { setNewHotelId(evt.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="d-flex flex-column col-12">
                            <label htmlFor="">Hotel Room Url</label>
                            <input
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel Room Url"
                                aria-label="hotel-name"
                                value={newHotelRoomUrl}
                                onChange={(evt) => { setNewHotelRoomUrl(evt.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="d-flex flex-column col-4">
                            <label htmlFor="">Page Name <Tooltip placement="top" title="Applicable for Agoda & Booking"><InfoIcon size="small" /></Tooltip></label>
                            <input
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Page Name"
                                aria-label="page-name"
                                value={newHotelPageName}
                                onChange={(evt) => { setNewHotelPageName(evt.target.value) }}
                            />
                        </div>
                        <div className="d-flex flex-column col-4">
                            <label htmlFor="">Hotel URL <Tooltip placement="top" title="Applicable for EaseMyTrip"><InfoIcon size="small" /></Tooltip></label>
                            <input
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel URL"
                                aria-label="hotel-url"
                                value={newHotelUrl}
                                onChange={(evt) => { setNewHotelUrl(evt.target.value) }}
                            />
                        </div>
                        <div className="d-flex flex-column col-4">
                            <label htmlFor="">Hotel Info Token <Tooltip placement="top" title="Applicable for Ixigo"><InfoIcon size="small" /></Tooltip></label>
                            <input
                                type="text"
                                className="mt-1 form-control"
                                placeholder="Hotel Info Token"
                                aria-label="hotel-info-token"
                                value={newHotelInfoToken}
                                onChange={(evt) => { setNewHotelInfoToken(evt.target.value) }}
                            />
                        </div>
                    </div>
                    {
                        errorMessage &&
                        <div className="mt-3">
                            <Alert severity="error">{errorMessage}</Alert>
                        </div>
                    }
                    <div className='row mt-3 w-100'>
                        <div className='col-12 d-flex justify-content-center'>
                            <Button variant="outlined" className='text-orange border-orange' onClick={addCustomMapping}>Save</Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>


            <ConfirmationDialog
                open={confirmDialog.open}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onConfirm={confirmDialog.onConfirm}
                onCancel={handleConfirmDialogClose}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
                <Typography variant="h6" sx={{ ml: 2 }}>
                    Finding hotel mappings...
                </Typography>
            </Backdrop>

            <ToastContainer />
        </>
    )
}

export default HotelMappingsDashboard;